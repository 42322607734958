var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit } from "@angular/core";
import { HieroBDD } from "../../services/hierobdd.service";
import * as i18nIsoLanguages from "@cospired/i18n-iso-languages";
import * as jsPDF from "jspdf";
import { LocalisationService } from "../../../../common/src/modules/localisation/localisation.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AppModalConfirmComponent } from "../../../../common/src/utility/modal/confirm-modal.component";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import algoliasearch from "algoliasearch";
import { PrestationType } from "../../../../common/src/bdd/interfaces/types";
import { EnumDevisState } from "../../../../common/src/bdd/interfaces/IDevis";
var doc = new jsPDF();
var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
let img = new Image();
img.src = "../../../assets/img/logo/fact-blue.png";
let tamponRefused = new Image();
tamponRefused.src = "../../../assets/img/tampon/image_2022-05-03_12-49-42.png";
let tamponAccepted = new Image();
tamponAccepted.src = "../../../assets/img/tampon/MicrosoftTeams-image.png";
export class DevisComponent {
    constructor(hiero, localisation, modalService, router, _location) {
        this.hiero = hiero;
        this.localisation = localisation;
        this.modalService = modalService;
        this.router = router;
        this._location = _location;
        this.p = 1;
        this.busy = true;
    }
    ngOnInit() {
        this.filter = "Filtrer";
        this.searching = false;
        this.allDevis().then((value) => (this.devis = value));
        this.allDevis().then((value) => (this.nbMaxResultSearch = value.length));
    }
    allDevis() {
        return this.hiero.DB.collection("devis")
            .orderBy("date", "desc")
            .get()
            .then((snap) => {
            return snap.docs;
        });
    }
    deleteDevis(id) {
        return __awaiter(this, void 0, void 0, function* () {
            const modalRef = this.modalService.open(AppModalConfirmComponent, {
                centered: true,
            });
            modalRef.componentInstance.header = "Confirmer la suppression";
            modalRef.componentInstance.message =
                "Etes vous vraiment sur de vouloir supprimer ce devis ?";
            modalRef.componentInstance.ok = "Supprimer";
            modalRef.componentInstance.cancel = "Annuler";
            let result = false;
            try {
                result = yield modalRef.result;
            }
            catch (_a) { }
            if (result) {
                this.busy = true;
                try {
                    this.hiero.DB.collection("devis")
                        .doc(id)
                        .delete()
                        .then(() => {
                        this.router
                            .navigateByUrl("/refresh", { skipLocationChange: true })
                            .then(() => {
                            this.router.navigate([decodeURI(this._location.path())]);
                        });
                    });
                }
                catch (err) {
                    console.error(err.message);
                }
                finally {
                    this.busy = false;
                }
            }
        });
    }
    getClientData(id) {
        return this.hiero.DB.collection("professionnels")
            .doc(id)
            .get()
            .then((snap) => {
            return snap.data();
        });
    }
    getFullNameClient(uid) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.hiero.DB.collection("users")
                .doc(uid)
                .get()
                .then((snap) => {
                return snap.data();
            });
        });
    }
    getSingleDevis(id) {
        return this.hiero.DB.collection("devis")
            .doc(id)
            .get()
            .then((snap) => {
            return snap.data();
        });
    }
    download(id) {
        return __awaiter(this, void 0, void 0, function* () {
            let currentLang = this.localisation.CurrentLanguageISO639;
            i18nIsoLanguages.registerLocale(require("@cospired/i18n-iso-languages/langs/" + currentLang + ".json"));
            this.singleDevis = yield this.getSingleDevis(id);
            this.professionnelData = yield this.getClientData(this.singleDevis.professionnelId);
            this.client = yield this.getFullNameClient(this.singleDevis.professionnelUid);
            var doc = new jsPDF();
            // ####### Adresse de Hiero
            doc.setFontSize(12);
            doc.setFontType("bold");
            doc.text(this.localisation.localise("support_company"), 10, 10);
            doc.setFontSize(10);
            doc.setFontType("normal");
            doc.text(this.localisation.localise("support_addr1"), 10, 15);
            doc.text(this.localisation.localise("support_addr2"), 10, 20);
            doc.text(this.localisation.localise("support_tel"), 10, 25);
            doc.text(this.localisation.localise("support_email"), 10, 30);
            //##### Logo
            doc.addImage(img, "png", 150, 10, 50, 40);
            // ##### Titre facture
            doc.setTextColor("#702963");
            doc.setFontSize(20);
            doc.setFontType("bold");
            doc.text(this.localisation.localise("devis").toUpperCase(), 10, 70);
            doc.setFontType("normal");
            doc.setTextColor(255, 0, 0);
            doc.setFontSize(30);
            // Infos sous le titre de la facture:
            doc.setTextColor("black");
            doc.setFontSize(10);
            doc.setFontType("bold");
            doc.text(this.localisation.localise("devis_numero").toUpperCase(), 20, 80);
            doc.text(this.localisation.localise("devis_expiration").toUpperCase(), 20, 85);
            this.singleDevis.purchaseOrder
                ? doc.text(this.localisation.localise("bon_commande"), 20, 100)
                : null;
            doc.setFontType("normal");
            doc.text(`${this.singleDevis.numero}`, 60, 80);
            doc.text(new Date(this.singleDevis.date.seconds * 1000).toLocaleDateString("fr-FR"), 60, 85);
            this.singleDevis.purchaseOrder
                ? doc.text(this.singleDevis.purchaseOrder, 60, 100)
                : null;
            // ADRESSE DE FACTURATION
            doc.setFontType("bold");
            doc.text(this.localisation.localise("account_adresse").toUpperCase(), 130, 80);
            doc.setFontType("normal");
            doc.text(this.professionnelData.businessName, 130, 87);
            doc.text(this.client.profile.givenName +
                " " +
                this.client.profile.familyName.toUpperCase(), 130, 92);
            doc.text(this.professionnelData.address.number +
                " " +
                this.professionnelData.address.street, 130, 97);
            doc.text(this.professionnelData.address.code +
                " " +
                this.professionnelData.address.city, 130, 102);
            doc.text(this.professionnelData.address.country, 130, 107);
            // Ligne horizontal
            doc.setDrawColor("#702963");
            doc.line(pageWidth - 20, 120, 20, 120);
            // doc.line(190, 151, 30, 151);
            // ####### Le tableau
            // # En tête
            doc.setFillColor(112, 41, 99);
            doc.rect(20, 125, pageWidth - 40, 10, "F");
            doc.setTextColor("#FFFFFF");
            doc.text(this.localisation.localise("facture_qte").toUpperCase(), 21, 131);
            doc.text(this.localisation.localise("commande_produit").toUpperCase(), 33, 131);
            doc.text(this.localisation.localise("commande_desc").toUpperCase(), 73, 131);
            doc.text(this.localisation.localise("tasks_price_tva").toUpperCase(), 127, 131);
            doc.text(this.localisation.localise("facture_price").toUpperCase(), 147, 129);
            doc.text(this.localisation.localise("facture_unitaire").toUpperCase(), 147, 133);
            doc.text(this.localisation.localise("facture_montant").toUpperCase(), 171, 131);
            // # La data
            doc.setTextColor("black");
            doc.text(`${this.singleDevis.pagesNumber ? this.singleDevis.pagesNumber : 1}`, 21, 143); //  QTE
            if (this.singleDevis.typePrestation === PrestationType.INTERPRETATION)
                var strArr = doc.splitTextToSize(this.singleDevis.event, 35);
            else
                var strArr = doc.splitTextToSize(this.singleDevis.documentType, 35);
            doc.text(strArr, 33, 143); //  Product
            let type = "";
            if (this.singleDevis.typePrestation === PrestationType.INTERPRETATION)
                type = this.localisation.localise("interpretation");
            if (this.singleDevis.typePrestation === PrestationType.TRADUCTION_ASSERMENTEE)
                type = this.localisation.localise("traduction_assermentee");
            if (this.singleDevis.typePrestation === PrestationType.TRADUCTION_CLASSIQUE)
                type = this.localisation.localise("traduction_classic");
            var strDes = doc.splitTextToSize(type +
                " " +
                this.localisation.localise("prestation_order_from").toLowerCase() +
                " " +
                i18nIsoLanguages
                    .getName(this.singleDevis.srcLang, currentLang)
                    .toLowerCase() +
                " " +
                this.localisation.localise("order_language_to").toLowerCase() +
                " " +
                i18nIsoLanguages
                    .getName(this.singleDevis.destLang, currentLang)
                    .toLowerCase(), 40);
            doc.text(strDes, 73, 143); // Description (faire la gestion de l'espace direcetement avec substr() et \n)
            doc.text("20 %", 127, 143); // TVA
            if (this.singleDevis.typePrestation === PrestationType.INTERPRETATION) {
                doc.text(this.singleDevis.price.priceHT.toFixed(2), 152, 143);
                doc.text(this.singleDevis.price.priceHT.toFixed(2), pageWidth - 30, 143); // Montant total
                // Question par rapport au Prix Unitaire
            }
            else {
                doc.text((this.singleDevis.priceHT.toFixed(2) /
                    (this.singleDevis.pagesNumber ? this.singleDevis.pagesNumber : 1)).toFixed(2), 152, 143); // Question par rapport au Prix Unitaire
                doc.text(this.singleDevis.priceHT.toFixed(2), pageWidth - 30, 143); // Montant total
            }
            doc.setDrawColor("#702963");
            // doc.line(190, 185, 30, 185);
            doc.line(pageWidth - 20, 155, 20, 155);
            // ##### Table des totaux
            //# Col 1
            doc.text(this.localisation.localise("total_ht").toUpperCase(), 127, 165);
            doc.text(this.localisation.localise("total_tva").toUpperCase(), 127, 175);
            doc.text(this.localisation.localise("total_ttc").toUpperCase(), 127, 185);
            doc.text(this.localisation.localise("facute_solde").toUpperCase(), 127, 195);
            // # Col 2
            if (this.singleDevis.typePrestation === PrestationType.INTERPRETATION) {
                doc.text(this.singleDevis.price.priceHT.toFixed(2), pageWidth - 30, 165);
                doc.text(this.singleDevis.price.priceTVAVal.toFixed(2), pageWidth - 30, 175);
                doc.text(this.singleDevis.price.priceTTC.toFixed(2), pageWidth - 30, 185);
            }
            else {
                doc.text(this.singleDevis.priceHT.toFixed(2), pageWidth - 30, 165);
                doc.text(this.singleDevis.priceTVAVal.toFixed(2), pageWidth - 30, 175);
                doc.text(this.singleDevis.priceTTC.toFixed(2), pageWidth - 30, 185);
            }
            if (this.singleDevis.state === EnumDevisState.RefusedByClient) {
                doc.addImage(tamponRefused, "png", 30, 158, 45, 40);
            }
            else if (this.singleDevis.state === EnumDevisState.AcceptedByClient) {
                doc.addImage(tamponAccepted, "png", 30, 158, 45, 40);
            }
            doc.setTextColor("#702963");
            doc.setFontSize(15);
            doc.setFontType("bold");
            if (this.singleDevis.typePrestation === PrestationType.INTERPRETATION)
                doc.text(this.singleDevis.price.priceTTC.toFixed(2) + " " + "EUR", 165, 195);
            else
                doc.text(this.singleDevis.priceTTC.toFixed(2) + " " + "EUR", 165, 195);
            doc.setFontType("normal");
            // Recap TVA
            doc.setFontSize(12);
            doc.setTextColor("black");
            doc.text(this.localisation.localise("recap_tva").toUpperCase(), 20, 208);
            doc.setFontSize(10);
            // doc.text('MONTANT DE TVA', )
            // doc.text('BASE HT', )
            // doc.text()
            doc.setTextColor("black");
            // Lignes inférieures
            // doc.setDrawColor('#702963')
            // doc.line(30, 230, 190, 230)
            // doc.line(20, 201, pageWidth-20, 201)
            doc.setFillColor(112, 41, 99);
            doc.rect(20, 211, pageWidth - 40, 10, "F");
            doc.setTextColor("#FFFFFF");
            doc.text(this.localisation.localise("recap_taux").toUpperCase(), 30, 217);
            doc.text(this.localisation.localise("recap_mt_tva").toUpperCase(), 90, 217);
            doc.text(this.localisation.localise("recap_base_ht").toUpperCase(), 170, 217);
            doc.setTextColor("black");
            doc.text(this.localisation.localise("facture_tva_fr").toUpperCase(), 25, 227);
            if (this.singleDevis.typePrestation === PrestationType.INTERPRETATION) {
                doc.text(this.singleDevis.price.priceTVAVal.toFixed(2), 100, 227);
                doc.text(this.singleDevis.price.priceHT.toFixed(2), 179, 227);
            }
            else {
                doc.text(this.singleDevis.priceTVAVal.toFixed(2), 100, 227);
                doc.text(this.singleDevis.priceHT.toFixed(2), 179, 227);
            }
            // Pénalité et etc...
            doc.setFontType("normal");
            doc.setFontSize(10);
            doc.setTextColor("black");
            doc.text(this.localisation.localise("condition_paragraphe_1"), pageWidth / 2, 245, {
                align: "center",
            });
            doc.text(this.localisation.localise("condition_paragraphe_2"), pageWidth / 2, 250, {
                align: "center",
            });
            doc.text(this.localisation.localise("condition_paragraphe_3"), pageWidth / 2, 255, { align: "center" });
            // Footer
            doc.setDrawColor("#702963");
            doc.line(0, pageHeight - 16, pageWidth, pageHeight - 16);
            doc.setFontSize(8);
            doc.text(this.localisation.localise("capital_sasu") +
                " - " +
                this.localisation.localise("agency_address_number") +
                " " +
                this.localisation.localise("rcs_infos") +
                " - " +
                this.localisation.localise("agency_address_number") +
                " " +
                this.localisation.localise("siret") +
                " - " +
                this.localisation.localise("agency_address_number") +
                " " +
                this.localisation.localise("tva_fr"), pageWidth / 2, pageHeight - 10, { align: "center" });
            doc.save(this.localisation.localise("devis") +
                "_" +
                this.singleDevis.numero +
                ".pdf");
        });
    }
    searchDevis(query) {
        return __awaiter(this, void 0, void 0, function* () {
            this.searching = true;
            const client = algoliasearch("BXE9JAXJO1", "0e831572d669fa8aef91cf6d9a2b7d6a");
            const index = client.initIndex("devis");
            index
                .search(query, { hitsPerPage: this.nbMaxResultSearch })
                .then((data) => {
                this.result = data.hits;
                this.result.sort((a, b) => b.date - a.date);
            });
            this.p = 1;
            this.filter = query !== "" ? query : "Filtrer";
        });
    }
    DevisCommandClassic() {
        this.searching = false;
        this.hiero.DB.collection("devis")
            .where("presta.srcCountryCode", "==", "")
            .orderBy("date", "desc")
            .get()
            .then((snap) => {
            this.devis = snap.docs.map((devi) => {
                return devi;
            });
        });
        this.p = 1;
        this.filter = "Devis commandes classiques";
    }
    DevisCommandAsser() {
        this.searching = false;
        this.hiero.DB.collection("devis")
            .orderBy("date", "desc")
            .get()
            .then((snap) => {
            this.devis = snap.docs
                .map((devi) => {
                return devi;
            })
                .filter((asser) => asser.data().presta.srcCountryCode !== "");
        });
        this.p = 1;
        this.filter = "Commandes assermentées";
    }
    allDevisFilter() {
        this.searching = false;
        this.hiero.DB.collection("devis")
            .orderBy("date", "desc")
            .get()
            .then((snapshot) => {
            this.devis = snapshot.docs.map((devi) => {
                return devi;
            });
        });
        this.p = 1;
        this.filter = "Toutes les commandes";
    }
}
