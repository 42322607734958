<div class="container-fluid">
  <div class="d-flex justify-content-center">
    <div class="lead">
      <i class="fa fa-folder-open text-info"></i> Gestion de toutes les
      commandes d'interpretations
    </div>
  </div>

  <div class="card mt-4">
    <div class="d-flex justify-content-between">
      <div class="h5 mt-3 font-weight-bold text-info ml-4">
        <button class="btn btn-outline-dark ml-2" disabled>
          Filtrer par partenaires <i class="fa fa-filter text-dark"></i>
        </button>
      </div>
      <!--      <div class="mt-3 mr-4">-->
      <!--        <button class="btn btn-outline-primary">-->
      <!--          <i class="fa fa-repeat"></i> Voir commandes professionnels-->
      <!--        </button>-->
      <!--      </div>-->
    </div>

    <div>
      <table class="table table-striped mt-4">
        <thead>
          <tr>
            <th scope="col" class="align-middle">Envoyé le</th>
            <th scope="col" class="align-middle">Date d'intervention</th>
            <th scope="col" class="align-middle">Intervention</th>
            <th scope="col" class="align-middle">Objet</th>
            <th scope="col" class="align-middle">De</th>
            <th scope="col" class="align-middle">Vers</th>
            <th scope="col" class="align-middle">Prix (TTC)</th>
            <th scope="col" class="align-middle">Traducteur</th>
            <th scope="col" class="align-middle">Statut</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let item of allCommands
                | paginate : { itemsPerPage: 8, currentPage: p }
            "
            class="clickable"
            [routerLink]="[item.id]"
          >
            <td class="align-middle">
              <button class="btn btn-secondary" disabled>
                {{ item.data().sendTime.toDate() | date : "dd/MM/yyyy" }}
              </button>
            </td>
            <td class="align-middle">
              <button class="btn btn-livraison" disabled>
                {{
                  item.data().missions.date.toDate() | date : "dd/MM/yyyy HH:mm"
                }}
              </button>
            </td>
            <td>{{ item.data().missions.intervention.name }}</td>
            <td>
              {{
                item.data().event.length > 17
                  ? item.data().event.substr(0, 17) + "..."
                  : item.data().event
              }}
            </td>
            <td class="align-middle">
              {{ item.data().missions.srcLang | iso639ToNative }}
            </td>
            <td class="align-middle">
              {{ item.data().missions.destLang | iso639ToNative }}
            </td>
            <td class="align-middle">
              {{ item.data().price.priceTTC }} &euro;
            </td>
            <td class="align-middle">{{ item.data().translatorName }}</td>
            <td class="align-middle font-weight-bold h7">
              <span
                style="color: #935e10"
                *ngIf="item.data().state == 'waiting-for-translator'"
                >Nouveau</span
              >
              <span
                style="color: #257f25"
                *ngIf="item.data().state == 'translating'"
                >En cours de traduction</span
              >
              <span
                style="color: #257f25"
                *ngIf="item.data().state == 'validated-pro'"
                >{{ item.data().prestationInfo.state }}</span
              >
              <span
                style="color: #935e10"
                *ngIf="item.data().state == 'waiting-for-client-to-validate'"
                >En attente de validation par le client</span
              >
              <span
                style="color: #935e10"
                *ngIf="item.data().state == 'refused-by-translator'"
                >Refusé par le traducteur</span
              >
              <span
                style="color: #935e10"
                *ngIf="item.data().state == 'cancelled-by-client'"
                >{{ item.data().prestationInfo.state }}</span
              >
              <span
                style="color: #935e10"
                *ngIf="item.data().state == 'validated'"
                >Validé</span
              >
              <span
                style="color: #935e10"
                *ngIf="item.data().state == 'waiting-for-payment'"
                >En attente de paiement</span
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="d-flex justify-content-center mt-4">
      <pagination-controls
        (pageChange)="p = $event"
        class="my-pagination"
      ></pagination-controls>
    </div>
  </div>
</div>
