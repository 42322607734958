var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { HieroBDD } from "../../services/hierobdd.service";
export class SingleProfilComponent {
    constructor(route, hiero) {
        this.route = route;
        this.hiero = hiero;
        this.id = this.route.snapshot.paramMap.get("id");
    }
    ngOnInit() {
        this.singleUser().then((value) => (this.verify = value.filter((user) => user.uid === this.id)[0].emailVerified));
        this.singleUser().then((value) => (this.registrationTime = value.filter((user) => user.uid === this.id)[0].metadata.creationTime));
        this.singleUser().then((value) => (this.email = value.filter((user) => user.uid === this.id)[0].email));
        this.userInformations().then((value) => (this.user = value));
        this.typeDetails().then((value) => (this.userAdress = value));
        this.singleUser().then((value) => (this.photo = value.filter((user) => user.uid === this.id)[0].photoURL));
    }
    singleUser() {
        return __awaiter(this, void 0, void 0, function* () {
            const fun = fetch("https://us-central1-hiero-prod.cloudfunctions.net/allUsers");
            const data = yield fun;
            const result = yield data.json();
            return result;
        });
    }
    userInformations() {
        return this.hiero.DB.collection("users")
            .doc(this.id)
            .get()
            .then((snap) => {
            return snap.data();
        });
    }
    typeDetails() {
        return __awaiter(this, void 0, void 0, function* () {
            const req = this.hiero.DB.collection("traducteurs").where("uid", "==", this.id);
            const req2 = this.hiero.DB.collection("professionnels").where("uid", "==", this.id);
            const req3 = this.hiero.DB.collection("particuliers").where("uid", "==", this.id);
            return req.get().then((snap) => {
                if (snap.docs[0] !== undefined) {
                    return snap.docs[0].data();
                }
                else {
                    return req2.get().then((snap2) => {
                        if (snap2.docs[0] !== undefined) {
                            return snap2.docs[0].data();
                        }
                        else {
                            return req3.get().then((snap3) => {
                                return snap3.docs[0].data();
                            });
                        }
                    });
                }
            });
        });
    }
}
