var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { EmptyProfessionnelProfile } from '../interfaces/IProfessionnel';
import { ProfessionnelServiceList } from './ProfessionnelService';
const FIREBASE_COLLECTION_PROFESSIONNEL = 'professionnels';
export class Professionnel {
    constructor(user, docId) {
        this._user = user;
        this._docId = docId;
        this._docRef = user.DB.collection(FIREBASE_COLLECTION_PROFESSIONNEL).doc(docId);
        this._profileSubject = new BehaviorSubject(null);
        this._docRef.onSnapshot({
            next: (snapshort) => {
                this._profileSubject.next(snapshort.data());
            },
            error: (err) => { }
        });
    }
    get User() {
        return this._user;
    }
    get Id() {
        return this._docId;
    }
    sync() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this._docRef.get();
        });
    }
    // public async getProfessionnelService(): Promise<professionnelService> {}
    /** Watch for changes to the professionnal profile. */
    WatchProfile(observer) {
        return this._profileSubject.subscribe(observer);
    }
    /** Update the professionnal profile */
    UpdateProfile(profile) {
        return __awaiter(this, void 0, void 0, function* () {
            // let validatedProfile: ITraducteur;
            try {
                /* validatedProfile = await TraducteurProfileSchema.validate(profile,
                  {
                    strict: false,
                    abortEarly: false,
                    stripUnknown: true
                  }
                );
                */
                yield this._docRef.update(profile);
            }
            catch (err) {
                return Promise.reject(err);
            }
        });
    }
    hasServicesOrders() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield ProfessionnelServiceList.HasService(this);
            return true;
        });
    }
    /** Instantiate a new Translator class */
    static Init(user) {
        return __awaiter(this, void 0, void 0, function* () {
            const query = user.DB.collection(FIREBASE_COLLECTION_PROFESSIONNEL).where('uid', '==', user.Id);
            try {
                const snapshot = yield query.get();
                let docId = null;
                if (snapshot.empty) {
                    // No translator of this type, create one
                    const professionnel = EmptyProfessionnelProfile;
                    professionnel.uid = user.Id;
                    const docRef = yield user.DB.collection(FIREBASE_COLLECTION_PROFESSIONNEL).add(professionnel);
                    yield user.GetIDToken();
                    docId = docRef.id;
                }
                else {
                    docId = snapshot.docs[0].id;
                }
                const pro = new Professionnel(user, docId);
                yield pro.sync();
                return pro;
            }
            catch (err) {
                console.error(err);
                throw err;
            }
        });
    }
}
