<div class="card mt-4">
  <div class="d-flex justify-content-around">
    <div class="h5 mt-3 lead ml-4">
      <i class="fa fa-folder-open text-danger mr-1"></i> Historique des devis
    </div>
    <div class="dropdown mt-3">
      <button
        class="btn btn-outline-secondary dropdown-toggle"
        type="button"
        data-toggle="dropdown"
      >
        {{ filter }}
      </button>
      <ul class="dropdown-menu">
        <input
          class="form-control"
          id="myInput"
          type="text"
          placeholder="Chercher..."
          #inpt
          (keyup)="searchDevis(inpt.value)"
        />

        <li (click)="DevisCommandAsser()">
          <a href="javascript:void(0);">Devis commandes assermenté</a>
        </li>
        <li (click)="DevisCommandClassic()">
          <a href="javascript:void(0);">Devis commandes classique</a>
        </li>
        <li (click)="allDevisFilter()">
          <a href="javascript:void(0);">Tout les devis</a>
        </li>
      </ul>
    </div>
  </div>

  <div>
    <table class="table table-striped mt-4">
      <thead>
        <tr>
          <th scope="col">Date</th>
          <th scope="col">Numéro de devis</th>
          <th scope="col">Type de prestation</th>
          <th scope="col">Devis</th>
          <th scope="col">Statut</th>
          <th scope="col">Suppression</th>
        </tr>
      </thead>

      <tbody *ngIf="searching === false">
        <tr
          *ngFor="
            let devi of devis | paginate : { itemsPerPage: 8, currentPage: p }
          "
        >
          <th scope="row">
            <button class="btn btn-seine-maritime-date" disabled>
              {{ devi.data().date.toDate() | date : "dd/MM/yyyy" }}
            </button>
          </th>
          <td>
            <span>{{ devi.data().numero }}</span>
          </td>
          <td>{{ devi.data().typePrestation }}</td>
          <td>
            <button class="btn btn-danger" (click)="download(devi.id)">
              <i class="fa fa-download mr-1"></i> Générer
            </button>
          </td>
          <td
            class="text-warning font-weight-bold font-italic"
            *ngIf="devi.data().state === 'waiting-for-validation'"
          >
            En attente de validation
          </td>
          <td
            class="text-success font-weight-bold font-italic"
            *ngIf="devi.data().state === 'accepted-by-client'"
          >
            <span class="lead">
              <i class="fa fa-check-circle mr-1"></i>
              Devis accepté
            </span>
          </td>

          <td
            class="text-danger font-weight-bold font-italic"
            *ngIf="devi.data().state === 'refused-by-client'"
          >
            <span class="lead">
              <i class="fa fa-remove mr-1"></i>
              Devis refusé</span
            >
          </td>
          <td>
            <button class="btn btn-danger" (click)="deleteDevis(devi.id)">
              <i class="fa fa-trash"></i>
            </button>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="searching === true">
        <tr
          *ngFor="
            let devi of result | paginate : { itemsPerPage: 8, currentPage: p }
          "
        >
          <th scope="row">
            <button class="btn btn-seine-maritime-date" disabled>
              {{ devi.date | date : "dd/MM/yyyy" }}
            </button>
          </th>
          <td>
            <span>{{ devi.numero }}</span>
          </td>
          <td>{{ devi.typePrestation }}</td>
          <td>
            <button class="btn btn-danger" (click)="download(devi.objectID)">
              <i class="fa fa-download mr-1"></i> Générer
            </button>
          </td>
          <td
            class="text-warning font-weight-bold font-italic"
            *ngIf="devi.state === 'waiting-for-validation'"
          >
            En attente de validation
          </td>
          <td
            class="text-success font-weight-bold font-italic"
            *ngIf="devi.state === 'accepted-by-client'"
          >
            <span class="lead">
              <i class="fa fa-check-circle mr-1"></i>
              Devis accepté
            </span>
          </td>

          <td
            class="text-danger font-weight-bold font-italic"
            *ngIf="devi.state === 'refused-by-client'"
          >
            <span class="lead">
              <i class="fa fa-remove mr-1"></i>
              Devis refusé</span
            >
          </td>
          <td>
            <button class="btn btn-danger" (click)="deleteDevis(devi.objectID)">
              <i class="fa fa-trash"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="d-flex justify-content-center mt-4">
    <pagination-controls
      (pageChange)="p = $event"
      class="my-pagination"
    ></pagination-controls>
  </div>
</div>
