export enum EnumFileTypes {
  PDF,
  Image
}

export interface IFile {
  // File type
  type?: EnumFileTypes;
  // Extension
  ext?: string;
  // File name
  name?: string;
  // The unique folder id for storage on the device
  deviceStorageId: string;
  // Download URL
  downloadURL?: string | null;

  // TODO:
  // localPath
  // remotePath
  // isPresent?
}
