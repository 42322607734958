var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export class File {
    constructor(storage, userId, prestation, subFolder, file) {
        this._storage = storage;
        this._userId = userId;
        this._prestation = prestation;
        this._subFolder = subFolder;
        this._file = file;
    }
    get UserId() {
        return this._userId;
    }
    get PrestationId() {
        return this._prestation.deviceStorageId;
    }
    get SubFolder() {
        return this._subFolder;
    }
    get File() {
        return this._file;
    }
    GetDownloadLink() {
        return __awaiter(this, void 0, void 0, function* () {
            const id = this._userId + '/' + this._prestation.deviceStorageId + '/' + this._subFolder + '/' + this._file.deviceStorageId;
            const docRef = this._storage.ref(id);
            const url = yield docRef.getDownloadURL();
            return url;
        });
    }
    GetDownloadLinkExtra() {
        return __awaiter(this, void 0, void 0, function* () {
            const id = this._userId + '/' + this._prestation.deviceStorageId + '/' + this._subFolder + '/' + 'extra_' + this._file.deviceStorageId;
            const docRef = this._storage.ref(id);
            const url = yield docRef.getDownloadURL();
            return url;
        });
    }
}
