var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit } from "@angular/core";
import { HieroBDD } from "../../services/hierobdd.service";
import { Router } from "@angular/router";
export class AllUsersComponent {
    constructor(hiero, router) {
        this.hiero = hiero;
        this.router = router;
        this.p = 1;
    }
    ngOnInit() {
        this.filter = "Filtrer";
        this.numberOfUsers().then((value) => {
            this.newRegistration = value.sort(function (a, b) {
                return (new Date(b.metadata.creationTime).getTime() -
                    new Date(a.metadata.creationTime).getTime());
            });
        });
        this.allUsersReq().then((value) => (this.data = value));
    }
    numberOfUsers() {
        return __awaiter(this, void 0, void 0, function* () {
            const fun = fetch("https://us-central1-hiero-prod.cloudfunctions.net/allUsers");
            const data = yield fun;
            const result = yield data.json();
            return result;
        });
    }
    allUsersReq() {
        const req = this.hiero.DB.collection("users");
        return req.get().then((snapshot) => {
            return snapshot.docs;
        });
    }
    profile(id) {
        this.router.navigate(["app/users", id]);
    }
    allTrad() {
        const traducteurs = this.data.filter(trad => {
            if (trad.data().roles[0].type === 'traducteur') {
                return trad.id;
            }
            else {
                return false;
            }
        });
        this.newRegistration = this.newRegistration.filter(user => traducteurs.map(trad => trad.id).includes(user.uid));
        this.filter = "Tout les traduteurs";
    }
    allUsersFilter() {
        this.numberOfUsers().then((value) => {
            this.newRegistration = value.sort(function (a, b) {
                return (new Date(b.metadata.creationTime).getTime() -
                    new Date(a.metadata.creationTime).getTime());
            });
        });
        this.filter = 'Tout les utilisateurs';
    }
}
