import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { HieroBDD } from "../services/hierobdd.service";

@Injectable()
export class SeineMaritimeGuard implements CanActivate {
  constructor(private hiero: HieroBDD, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    if (this.hiero.Auth.User.Profile.isSeineMaritime) {
      this.router.navigate(["/app/seine-maritime/dashboard"]);
      return Promise.resolve(false);
    } else {
      return Promise.resolve(true);
    }
  }
}
