import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HieroBDD } from "../../services/hierobdd.service";

@Component({
  selector: "single-profile",
  templateUrl: "./singleProfil.component.html",
  styleUrls: ["./singleProfil.component.scss"],
})
export class SingleProfilComponent implements OnInit {
  id = this.route.snapshot.paramMap.get("id");
  verify: boolean;
  registrationTime;
  email;
  user;
  userAdress;
  photo;

  constructor(private route: ActivatedRoute, private hiero: HieroBDD) {}

  ngOnInit(): void {
    this.singleUser().then(
      (value) =>
        (this.verify = value.filter(
          (user) => user.uid === this.id
        )[0].emailVerified)
    );

    this.singleUser().then(
      (value) =>
        (this.registrationTime = value.filter(
          (user) => user.uid === this.id
        )[0].metadata.creationTime)
    );
    this.singleUser().then(
      (value) =>
        (this.email = value.filter((user) => user.uid === this.id)[0].email)
    );

    this.userInformations().then((value) => (this.user = value));

    this.typeDetails().then((value) => (this.userAdress = value));

    this.singleUser().then(
      (value) =>
        (this.photo = value.filter((user) => user.uid === this.id)[0].photoURL)
    );
  }

  async singleUser() {
    const fun = fetch(
      "https://us-central1-hiero-prod.cloudfunctions.net/allUsers"
    );

    const data = await fun;

    const result = await data.json();

    return result;
  }

  userInformations() {
    return this.hiero.DB.collection("users")
      .doc(this.id)
      .get()
      .then((snap) => {
        return snap.data();
      });
  }

  async typeDetails() {
    const req = this.hiero.DB.collection("traducteurs").where(
      "uid",
      "==",
      this.id
    );

    const req2 = this.hiero.DB.collection("professionnels").where(
      "uid",
      "==",
      this.id
    );

    const req3 = this.hiero.DB.collection("particuliers").where(
      "uid",
      "==",
      this.id
    );

    return req.get().then((snap) => {
      if (snap.docs[0] !== undefined) {
        return snap.docs[0].data();
      } else {
        return req2.get().then((snap2) => {
          if (snap2.docs[0] !== undefined) {
            return snap2.docs[0].data();
          } else {
            return req3.get().then((snap3) => {
              return snap3.docs[0].data();
            });
          }
        });
      }
    });
  }
}
