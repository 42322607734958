import { Component, OnInit } from "@angular/core";
import { HieroBDD } from "../../../services/hierobdd.service";
import * as jspdf from "jspdf";
import { IPrestation } from "../../../../../common/src/bdd/interfaces/IPrestation";
import { Professionnel } from "../../../../../common/src/bdd/professionnel/Professionnel";
import { ProfessionnelServiceList } from "../../../../../common/src/bdd/professionnel/ProfessionnelService";
import { Prestation } from "../../../../../common/src/bdd/prestation/Prestation";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { LocalisationService } from "../../../../../common/src/modules/localisation/localisation.service";
import * as i18nIsoLanguages from "@cospired/i18n-iso-languages";
import * as jsPDF from "jspdf";
import { ProDevisService } from "../../../services/pro/pro-devis.service";
import { EnumDevisState } from "../../../../../common/src/bdd/interfaces/IDevis";

var doc = new jsPDF();
var pageHeight =
  doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

let img = new Image();
img.src = "../../../../assets/img/logo/fact-fidinam.png";

let tamponRefused = new Image();
tamponRefused.src =
  "../../../../assets/img/tampon/image_2022-05-03_12-49-42.png";

let tamponAccepted = new Image();

tamponAccepted.src = "../../../../assets/img/tampon/MicrosoftTeams-image.png";

export const ROOM_LINK =
  "https://video-app-6852-1089-dev.twil.io?passcode=87123868521089";

export enum EnumPrestationState {
  Defining = "defining",
  WaitingForTranslator = "waiting-for-translator",
  CancelledByClient = "cancelled-by-client",
  WaitingForPayment = "waiting-for-payment",
  RefusedByTranslator = "refused-by-translator",
  Translating = "translating",
  WaitingForValidationFromClient = "waiting-for-client-to-validate",
  Validated = "validated",
}

@Component({
  selector: "app-dashboard-fidinam",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class FidinamComponent implements OnInit {
  date: Date = new Date();
  deviOrCommand: boolean;
  devis;
  p: number = 1;
  command;
  commandThisMonth;
  private singleDevis;
  private adminId: string;
  private adminFidinam;
  public client;
  usersFidinam;
  commandsNbr;
  sales;
  salesMonth;
  public proInfo;

  EmptyPrestation: IPrestation = {
    uid: "",
    state: EnumPrestationState.WaitingForTranslator,
    srcLanguageIso639: "",
    destLanguageIso639: "",
    srcCountryCode: "",
    documents: [],
    traducteurId: null,
    traducteur: null,
    price: {
      traducteurHT: 0,
      hieroMarginPercent: 0,
      hieroMarginValue: 0,
      subtotalHT: 0,
      tva: 0,
      tvaValue: 0,
      ttc: 0,
    },

    deviceStorageId: "",

    sentToTranslatorAt: 0,
    cancelledByClientAt: 0,
    acceptedByTranslatorAt: 0,
    refusedByTranslatorAt: 0,
    dueAt: 0,
    paidAt: 0,
    completedAt: 0,
    validatedByClientAt: 0,
    lastModifiedAt: 0,
    createdAt: 0,
    isProfessionalPrestation: false,
  };

  constructor(
    private hiero: HieroBDD,
    private router: Router,
    public _location: Location,
    private localisation: LocalisationService,
    private devisService: ProDevisService
  ) {}

  async ngOnInit(): Promise<void> {
    this.allDevis().then((value) => (this.devis = value));
    this.allCommands().then(
      (value) => (this.command = value.map((command) => command.data()))
    );
    this.allUsers().then((value) => (this.usersFidinam = value));

    this.allCommands().then((value) => (this.commandsNbr = value.length));

    this.salesRevenues().then((value) => (this.sales = value));

    this.salesRevenuesThisMonth().then((value) => (this.salesMonth = value));

    this.allCommandsThisMonth().then(
      (value) => (this.commandThisMonth = value)
    );

    this.adminFidinam = await this.adminInfo();

    this.deviOrCommand = JSON.parse(localStorage.getItem("isDevi"));

    if (this.deviOrCommand === null) {
      this.deviOrCommand = true;
    }
  }

  switchBetweenDeviCommands() {
    localStorage.setItem("isDevi", JSON.stringify(!this.deviOrCommand));
    this.deviOrCommand = JSON.parse(localStorage.getItem("isDevi"));
  }

  async getClientData(uid: string) {
    const user = this.hiero.DB.collection("users").doc(uid);
    const snap = await user.get();
    return snap.data();
  }

  allDevis() {
    return this.hiero.DB.collection("devis")
      .where("isDevisFidinam", "==", true)
      .orderBy("date", "desc")
      .get()
      .then((snap) => {
        return snap.docs;
      });
  }

  allCommands() {
    return this.hiero.DB.collection("professionnel_commandes")
      .where("isCommandFidinam", "==", true)
      .orderBy("createdAt", "desc")
      .get()
      .then((snap) => {
        console.log(snap.docs);

        return snap.docs;
      });
  }

  allCommandsThisMonth() {
    const date = new Date();
    const start = new Date(date.getFullYear(), date.getMonth(), 1);

    return this.hiero.DB.collection("professionnel_commandes")
      .where("isCommandFidinam", "==", true)
      .where("createdAt", ">=", start)
      .get()
      .then((snap) => {
        return snap.docs.length;
      });
  }

  salesRevenues() {
    let prices = [];
    let sum = 0;

    return this.hiero.DB.collection("professionnel_commandes")
      .where("isCommandFidinam", "==", true)
      .where("prestationInfo.state", "==", "validated")
      .get()
      .then((snapshot) => {
        snapshot.docs.map(
          (price) =>
            (prices = [price.data().prestationInfo.price.ttc, ...prices])
        );
      })
      .then(() => {
        const initialValue = 0;
        sum = prices.reduce(
          (previousValue, currentValue) => previousValue + currentValue,
          initialValue
        );
      })
      .then(() => {
        return sum.toFixed(2);
      });
  }

  salesRevenuesThisMonth() {
    let prices = [];
    let sum = 0;
    const date = new Date();
    const start = new Date(date.getFullYear(), date.getMonth(), 1);

    return this.hiero.DB.collection("professionnel_commandes")
      .where("isCommandFidinam", "==", true)
      .where("prestationInfo.state", "==", "validated")
      .where("createdAt", ">=", start)
      .get()
      .then((snapshot) => {
        snapshot.docs.map(
          (price) =>
            (prices = [price.data().prestationInfo.price.ttc, ...prices])
        );
      })
      .then(() => {
        const initialValue = 0;
        sum = prices.reduce(
          (previousValue, currentValue) => previousValue + currentValue,
          initialValue
        );
      })
      .then(() => {
        return sum.toFixed(2);
      });
  }

  getSingleDevis(id) {
    return this.hiero.DB.collection("devis")
      .doc(id)
      .get()
      .then((snap) => {
        return snap.data();
      });
  }

  async adminInfo() {
    const currentUser = this.hiero.Auth.User;
    const docRef = await this.hiero.DB.collection("professionnels")
      .where("uid", "==", currentUser.Id)
      .get();

    let pro = docRef.docs;
    this.adminId = pro[0].id;

    return pro[0].data();
  }

  updateBudget(price: number) {
    if (this.adminFidinam.budget > 0) {
      this.adminFidinam.budget = this.adminFidinam.budget - price;

      this.hiero.DB.collection("professionnels").doc(this.adminId).update({
        budget: this.adminFidinam.budget,
      });
    }
  }

  public async sendCommand(id) {
    this.singleDevis = await this.getSingleDevis(id);

    this.client = await this.getClientData(
      this.singleDevis.professionnelData.uid
    );

    /********************************
     * Faut récupérer l'objet User du pro qui a passé la commande pour le passer dans cette méthode
     */
    const pro: Professionnel = new Professionnel(
      this.hiero.Auth.User,
      this.adminId
    );

    //let pro = this.singleDevis.professionnelData as Professionnel;

    if (this.singleDevis.typePrestation === "Interprétation") {
      this.hiero.DB.collection("interpretations_commandes").add({
        missions: this.singleDevis.missions,
        state: EnumPrestationState.WaitingForTranslator,
        traducteur: this.singleDevis.traducteurName,
        professionnel: this.singleDevis.professionnelData,
        professionnelId: this.singleDevis.professionnelId,
        room: this.singleDevis.room,
        roomLink: ROOM_LINK,
        TVA: this.singleDevis.priceTVAVal,
        price: this.singleDevis.priceHT,
        priceTTC: this.singleDevis.priceTTC,
        isInterpretationFidinam: true,
      });
      await this.hiero.DB.collection("facture").add({
        numero: Math.floor(Math.random() * 1000000 + 1),
        room: this.singleDevis.room,
        date: new Date(),
        srcLang: this.singleDevis.srcLang,
        destLang: this.singleDevis.destLang,
        priceTTC: this.singleDevis.priceTTC,
        priceHT: this.singleDevis.priceHT,
        priceTVA: this.singleDevis.priceTVA,
        priceTVAVal: this.singleDevis.priceTVAVal,
        traducteur: this.singleDevis.traducteur,
        traducteurName: this.singleDevis.traducteurName,
        pagesNumber: 1,
        prestation: Math.floor(Math.random() * 1000000 + 1),
        typePrestation: "Interprétation",
        professionnelId: this.singleDevis.professionnelId,
        isFactureFidinam: true,
      });
    } else {
      const commands = new ProfessionnelServiceList(pro);

      this.EmptyPrestation.srcLanguageIso639 = this.singleDevis.srcLang;
      this.EmptyPrestation.price.ttc = this.singleDevis.priceTTC;
      this.EmptyPrestation.price.traducteurHT = this.singleDevis.priceHT;
      this.EmptyPrestation.price.tva = this.singleDevis.priceTVA;
      this.EmptyPrestation.price.tvaValue = this.singleDevis.priceTVAVal;
      if (this.singleDevis.originLangISO369 !== "classic")
        this.EmptyPrestation.srcCountryCode = this.singleDevis.originLangISO369;
      else this.EmptyPrestation.srcCountryCode = "";
      this.EmptyPrestation.destLanguageIso639 = this.singleDevis.destLang;
      this.EmptyPrestation.traducteurId = this.singleDevis.tradId;
      this.EmptyPrestation.documents = this.singleDevis.presta.documents;
      this.EmptyPrestation.uid = this.singleDevis.professionnelUid;
      this.EmptyPrestation.deviceStorageId = this.singleDevis.professionnelUid; // id prestation device
      this.EmptyPrestation.traducteur = this.singleDevis.presta.traducteur;
      this.EmptyPrestation.isProfessionalPrestation = true;

      /********************************
       * Faut récupérer l'objet User du pro qui a passé la commande pour le passer dans cette méthode
       * car sinon la prestation est créée avec l'uid de l'admin
       */
      const newPrestation = await Prestation.Create(
        this.hiero.Auth.User,
        this.EmptyPrestation
      );

      this.EmptyPrestation.uid = this.singleDevis.professionnelUid;

      let command = {
        srcLanguageIso639: this.singleDevis.srcLang,
        originLanguage: this.singleDevis.originLangISO369,
        destLanguageIso639: this.singleDevis.destLang,
        documentType: this.singleDevis.documentType,
        docFile: this.singleDevis.docFile,
        traducteurName: this.singleDevis.traducteurName,
        traducteurId: this.singleDevis.tradId,
        budget: this.singleDevis.budget,
        createdAt: new Date(),
        prestationId: newPrestation.Id,
        prestationInfo: this.EmptyPrestation,
        pagesNumber: this.singleDevis.pagesNumber,
        urgency: this.singleDevis.urgency,
        description: this.singleDevis.description,
        professionnelId: this.singleDevis.professionnelId,
        devisId: id,
        isCommandFidinam: true,
      };

      let facture = {
        numero: Math.floor(Math.random() * 1000000 + 1),
        date: new Date(),
        devisId: id,
        srcLang: this.singleDevis.srcLang,
        destLang: this.singleDevis.destLang,
        priceTTC: this.singleDevis.priceTTC,
        priceHT: this.singleDevis.priceHT,
        priceTVA: this.singleDevis.priceTVA,
        priceTVAVal: this.singleDevis.priceTVAVal,
        traducteur: this.singleDevis.traducteur,
        traducteurName: this.singleDevis.traducteurName,
        pagesNumber: this.singleDevis.pagesNumber,
        prestation: newPrestation.Id,
        typePrestation: this.singleDevis.typePrestation,
        professionnelId: this.singleDevis.professionnelId,
        purchaseOrder: this.singleDevis.purchaseOrder,
        isFactureFidinam: true,
        product: command,
        client: this.client,
        adresseClient: this.singleDevis.professionnelData,
      };

      await commands.addService(command);

      // await  this.updateBudget();

      await this.hiero.DB.collection("facture").add(facture);
    }

    await this.devisService.acceptProDevis(id);

    await this.router
      .navigateByUrl("/refresh", { skipLocationChange: true })
      .then(() => {
        this.router.navigate([decodeURI(this._location.path())]);
      });

    this.updateBudget(this.singleDevis.priceTTC);
  }

  public async refusedCommand(id) {
    await this.devisService.refuseProDevis(id);

    await this.router
      .navigateByUrl("/refresh", { skipLocationChange: true })
      .then(() => {
        this.router.navigate([decodeURI(this._location.path())]);
      });
  }

  allUsers() {
    return this.hiero.DB.collection("users")
      .where("profile.isFidinam", "==", true)
      .get()
      .then((snap) => {
        return snap.docs.length;
      });
  }

  public async download(id) {
    let currentLang = this.localisation.CurrentLanguageISO639;

    i18nIsoLanguages.registerLocale(
      require("@cospired/i18n-iso-languages/langs/" + currentLang + ".json")
    );

    this.singleDevis = await this.getSingleDevis(id);

    this.client = await this.getClientData(
      this.singleDevis.professionnelData.uid
    );

    var doc = new jsPDF();

    // ####### Adresse de Hiero
    doc.setFontSize(12);
    doc.setFontType("bold");
    doc.text(this.localisation.localise("support_company"), 10, 10);

    doc.setFontSize(10);
    doc.setFontType("normal");
    doc.text(this.localisation.localise("support_addr1"), 10, 15);
    doc.text(this.localisation.localise("support_addr2"), 10, 20);
    doc.text(this.localisation.localise("support_tel"), 10, 25);
    doc.text(this.localisation.localise("support_email"), 10, 30);

    //##### Logo

    doc.addImage(img, "png", 150, 10, 50, 40);

    // ##### Titre facture
    doc.setTextColor("#d2163e");
    doc.setFontSize(20);
    doc.setFontType("bold");
    doc.text(this.localisation.localise("devis").toUpperCase(), 10, 70);
    doc.setFontType("normal");
    doc.setTextColor(255, 0, 0);
    doc.setFontSize(30);

    // Infos sous le titre de la facture:
    doc.setTextColor("black");
    doc.setFontSize(10);
    doc.setFontType("bold");
    doc.text(this.localisation.localise("devis_numero").toUpperCase(), 20, 80);
    doc.text(
      this.localisation.localise("devis_expiration").toUpperCase(),
      20,
      85
    );

    this.singleDevis.purchaseOrder
      ? doc.text(this.localisation.localise("bon_commande"), 20, 100)
      : null;

    doc.setFontType("normal");

    doc.text(`${this.singleDevis.numero}`, 60, 80);
    doc.text(
      new Date(this.singleDevis.date.seconds * 1000).toLocaleDateString(
        "fr-FR"
      ),
      60,
      85
    );

    this.singleDevis.purchaseOrder
      ? doc.text(this.singleDevis.purchaseOrder, 60, 100)
      : null;

    // ADRESSE DE FACTURATION

    doc.setFontType("bold");
    doc.text(
      this.localisation.localise("account_adresse").toUpperCase(),
      130,
      80
    );
    doc.setFontType("normal");
    doc.text(this.singleDevis.professionnelData.businessName, 130, 87);
    doc.text(
      this.client.profile.givenName +
        " " +
        this.client.profile.familyName.toUpperCase(),
      130,
      92
    );
    doc.text(
      this.singleDevis.professionnelData.address.number +
        " " +
        this.singleDevis.professionnelData.address.street,
      130,
      97
    );
    doc.text(
      this.singleDevis.professionnelData.address.code +
        " " +
        this.singleDevis.professionnelData.address.city,
      130,
      102
    );
    doc.text(this.singleDevis.professionnelData.address.country, 130, 107);

    // Ligne horizontal
    doc.setDrawColor("#d2163e");
    doc.line(pageWidth - 20, 120, 20, 120);
    // doc.line(190, 151, 30, 151);

    // ####### Le tableau

    // # En tête
    doc.setFillColor(210, 22, 62);

    doc.rect(20, 125, pageWidth - 40, 10, "F");

    doc.setTextColor("#FFFFFF");

    doc.text(this.localisation.localise("facture_qte").toUpperCase(), 21, 131);

    doc.text(
      this.localisation.localise("commande_produit").toUpperCase(),
      33,
      131
    );

    doc.text(
      this.localisation.localise("commande_desc").toUpperCase(),
      73,
      131
    );

    doc.text(
      this.localisation.localise("tasks_price_tva").toUpperCase(),
      127,
      131
    );

    doc.text(
      this.localisation.localise("facture_price").toUpperCase(),
      147,
      129
    );
    doc.text(
      this.localisation.localise("facture_unitaire").toUpperCase(),
      147,
      133
    );

    doc.text(
      this.localisation.localise("facture_montant").toUpperCase(),
      171,
      131
    );

    // # La data

    doc.setTextColor("black");

    doc.text(
      `${this.singleDevis.pagesNumber ? this.singleDevis.pagesNumber : 1}`,
      21,
      143
    ); //  QTE

    if (this.singleDevis.typePrestation === "Interprétation")
      var strArr = doc.splitTextToSize(this.singleDevis.room, 35);
    else var strArr = doc.splitTextToSize(this.singleDevis.documentType, 35);
    doc.text(strArr, 33, 143); //  Product
    let type: string = "";
    if (this.singleDevis.typePrestation === "Interprétation")
      type = this.localisation.localise("interpretation");
    if (this.singleDevis.typePrestation === "Traduction assermentée")
      type = this.localisation.localise("traduction_assermentee");
    if (this.singleDevis.typePrestation === "Traduction classique")
      type = this.localisation.localise("traduction_classic");

    var strDes = doc.splitTextToSize(
      type +
        " " +
        this.localisation.localise("prestation_order_from").toLowerCase() +
        " " +
        i18nIsoLanguages
          .getName(this.singleDevis.srcLang, currentLang)
          .toLowerCase() +
        " " +
        this.localisation.localise("order_language_to").toLowerCase() +
        " " +
        i18nIsoLanguages
          .getName(this.singleDevis.destLang, currentLang)
          .toLowerCase(),
      40
    );
    doc.text(strDes, 73, 143); // Description (faire la gestion de l'espace direcetement avec substr() et \n)

    doc.text("20 %", 127, 143); // TVA

    doc.text(
      (
        this.singleDevis.priceHT.toFixed(2) /
        (this.singleDevis.pagesNumber ? this.singleDevis.pagesNumber : 1)
      ).toFixed(2),
      152,
      143
    ); // Question par rapport au Prix Unitaire

    doc.text(this.singleDevis.priceHT.toFixed(2), pageWidth - 30, 143); // Montant total

    doc.setDrawColor("#d2163e");
    // doc.line(190, 185, 30, 185);
    doc.line(pageWidth - 20, 155, 20, 155);

    // ##### Table des totaux
    //# Col 1

    doc.text(this.localisation.localise("total_ht").toUpperCase(), 127, 165);
    doc.text(this.localisation.localise("total_tva").toUpperCase(), 127, 175);
    doc.text(this.localisation.localise("total_ttc").toUpperCase(), 127, 185);
    doc.text(
      this.localisation.localise("facute_solde").toUpperCase(),
      127,
      195
    );

    // # Col 2

    doc.text(this.singleDevis.priceHT.toFixed(2), pageWidth - 30, 165);
    doc.text(this.singleDevis.priceTVAVal.toFixed(2), pageWidth - 30, 175);
    doc.text(this.singleDevis.priceTTC.toFixed(2), pageWidth - 30, 185);

    if (this.singleDevis.state === EnumDevisState.RefusedByClient) {
      doc.addImage(tamponRefused, "png", 30, 158, 45, 40);
    } else if (this.singleDevis.state === EnumDevisState.AcceptedByClient) {
      doc.addImage(tamponAccepted, "png", 30, 158, 45, 40);
    }

    doc.setTextColor("#d2163e");
    doc.setFontSize(15);
    doc.setFontType("bold");
    doc.text(this.singleDevis.priceTTC.toFixed(2) + " " + "EUR", 165, 195);

    doc.setFontType("normal");

    // Recap TVA
    doc.setFontSize(12);
    doc.setTextColor("black");
    doc.text(this.localisation.localise("recap_tva").toUpperCase(), 20, 208);
    doc.setFontSize(10);

    // doc.text('MONTANT DE TVA', )

    // doc.text('BASE HT', )

    // doc.text()

    doc.setTextColor("black");

    // Lignes inférieures

    // doc.setDrawColor('#702963')
    // doc.line(30, 230, 190, 230)
    // doc.line(20, 201, pageWidth-20, 201)
    doc.setFillColor(210, 22, 62);

    doc.rect(20, 211, pageWidth - 40, 10, "F");

    doc.setTextColor("#FFFFFF");
    doc.text(this.localisation.localise("recap_taux").toUpperCase(), 30, 217);
    doc.text(this.localisation.localise("recap_mt_tva").toUpperCase(), 90, 217);
    doc.text(
      this.localisation.localise("recap_base_ht").toUpperCase(),
      170,
      217
    );

    doc.setTextColor("black");

    doc.text(
      this.localisation.localise("facture_tva_fr").toUpperCase(),
      25,
      227
    );
    doc.text(this.singleDevis.priceTVAVal.toFixed(2), 100, 227);
    doc.text(this.singleDevis.priceHT.toFixed(2), 179, 227);

    // Pénalité et etc...
    doc.setFontType("normal");
    doc.setFontSize(10);

    doc.setTextColor("black");
    doc.text(
      this.localisation.localise("condition_paragraphe_1"),
      pageWidth / 2,
      245,
      {
        align: "center",
      }
    );
    doc.text(
      this.localisation.localise("condition_paragraphe_2"),
      pageWidth / 2,
      250,
      {
        align: "center",
      }
    );
    doc.text(
      this.localisation.localise("condition_paragraphe_3"),
      pageWidth / 2,
      255,
      { align: "center" }
    );

    // Footer

    doc.setDrawColor("#d2163e");
    doc.line(0, pageHeight - 16, pageWidth, pageHeight - 16);
    doc.setFontSize(8);
    doc.text(
      this.localisation.localise("capital_sasu") +
        " - " +
        this.localisation.localise("agency_address_number") +
        " " +
        this.localisation.localise("rcs_infos") +
        " - " +
        this.localisation.localise("agency_address_number") +
        " " +
        this.localisation.localise("siret") +
        " - " +
        this.localisation.localise("agency_address_number") +
        " " +
        this.localisation.localise("tva_fr"),
      pageWidth / 2,
      pageHeight - 10,
      { align: "center" }
    );

    doc.save(
      this.localisation.localise("devis") +
        "_" +
        this.singleDevis.numero +
        ".pdf"
    );
  }
}
