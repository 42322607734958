var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as firebase from "firebase";
import { HieroBDD } from "../../services/hierobdd.service";
import { AppModalConfirmComponent } from "../../../../common/src/utility/modal/confirm-modal.component";
import { LocalisationService } from "../../../../common/src/modules/localisation/localisation.service";
import { File } from "../../../../common/src/bdd/prestation/File";
import swal from "sweetalert";
export class SingleCommandComponent {
    constructor(route, hiero, router, modalService, localisation) {
        this.route = route;
        this.hiero = hiero;
        this.router = router;
        this.modalService = modalService;
        this.localisation = localisation;
        this.id = this.route.snapshot.paramMap.get("id");
        this.busy = true;
    }
    ngOnInit() {
        this.getCommand(this.id).then((value) => (this.command = value));
        this.getClientType(this.id).then((value) => (this.clientType = value));
        this.clientName(this.id).then((value) => (this.name = value));
    }
    resetPage() {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = "reload";
        this.router.navigate(["/app", "commandes", this.id], {
            relativeTo: this.route,
            queryParamsHandling: "merge",
        });
    }
    updateCommandStatus(id, status) {
        return __awaiter(this, void 0, void 0, function* () {
            const statusView = status === "refused-by-translator" ? "refuser" : "valider";
            const statusBtn = status === "refused-by-translator" ? "Refuser" : "Valider";
            const modalRef = this.modalService.open(AppModalConfirmComponent, {
                centered: true,
            });
            modalRef.componentInstance.header = "Confirmer le changement de statut";
            modalRef.componentInstance.message = `Voulez-vous vraiment ${statusView} cette commande ? Ce changement de statut est irréversible.`;
            modalRef.componentInstance.ok = statusBtn;
            modalRef.componentInstance.cancel = "Annuler";
            let result = false;
            try {
                result = yield modalRef.result;
            }
            catch (_a) { }
            if (result) {
                this.busy = true;
                try {
                    const req = this.hiero.DB.collection("particuliers_commandes").where("prestationId", "==", id);
                    const req2 = this.hiero.DB.collection("professionnel_commandes").where("prestationId", "==", id);
                    req.get().then((snapshot) => {
                        if (snapshot.docs[0] !== undefined) {
                            const requestClient = this.hiero.DB.collection("particuliers_commandes").doc(snapshot.docs[0].id);
                            if (status === "refused-by-translator") {
                                requestClient
                                    .update({
                                    "prestationInfo.state": status,
                                    "prestationInfo.refusedByTranslatorAt": new Date().getTime(),
                                })
                                    .then((value) => {
                                    this.hiero.DB.collection("prestations").doc(id).update({
                                        state: status,
                                        refusedByTranslatorAt: new Date().getTime(),
                                    });
                                })
                                    .then((value) => this.resetPage());
                            }
                            else {
                                requestClient
                                    .update({
                                    "prestationInfo.state": status,
                                    "prestationInfo.validatedByClientAt": new Date().getTime(),
                                })
                                    .then((value) => {
                                    this.hiero.DB.collection("prestations").doc(id).update({
                                        state: status,
                                        validatedByClientAt: new Date().getTime(),
                                    });
                                })
                                    .then((value) => this.resetPage());
                            }
                        }
                        else {
                            return req2.get().then((snap) => {
                                const requestPro = this.hiero.DB.collection("professionnel_commandes").doc(snap.docs[0].id);
                                if (status === "refused-by-translator") {
                                    requestPro
                                        .update({
                                        "prestationInfo.state": status,
                                        "prestationInfo.refusedByTranslatorAt": new Date().getTime(),
                                    })
                                        .then((value) => {
                                        this.hiero.DB.collection("prestations").doc(id).update({
                                            state: status,
                                            refusedByTranslatorAt: new Date().getTime(),
                                        });
                                    })
                                        .then((value) => this.resetPage());
                                }
                                else {
                                    requestPro
                                        .update({
                                        "prestationInfo.state": "validated-pro",
                                        "prestationInfo.validatedByClientAt": new Date().getTime(),
                                    })
                                        .then((value) => {
                                        this.hiero.DB.collection("prestations").doc(id).update({
                                            state: "validated-pro",
                                            validatedByClientAt: new Date().getTime(),
                                        });
                                    })
                                        .then((value) => this.resetPage());
                                }
                            });
                        }
                    });
                }
                catch (err) {
                    console.error(err.message);
                }
                finally {
                    this.busy = false;
                }
            }
        });
    }
    getCommand(id) {
        const req = this.hiero.DB.collection("particuliers_commandes").where("prestationId", "==", id);
        const req2 = this.hiero.DB.collection("professionnel_commandes").where("prestationId", "==", id);
        return req.get().then((snapshot) => {
            if (snapshot.docs[0] !== undefined) {
                return snapshot.docs[0].data();
            }
            else {
                return req2.get().then((snapshot) => {
                    return snapshot.docs[0].data();
                });
            }
        });
    }
    getClientType(id) {
        const req = this.hiero.DB.collection("particuliers_commandes").where("prestationId", "==", id);
        return req.get().then((snapshot) => {
            if (snapshot.docs[0] !== undefined) {
                return "Particulier";
            }
            else {
                return "Professionnel";
            }
        });
    }
    deleteCommand() {
        return __awaiter(this, void 0, void 0, function* () {
            const modalRef = this.modalService.open(AppModalConfirmComponent, {
                centered: true,
            });
            modalRef.componentInstance.header = "Confirmer la suppression";
            modalRef.componentInstance.message =
                "Voulez-vous vraiment supprimer cette commande ? Cette action est irréversible.";
            modalRef.componentInstance.ok = "Supprimer";
            modalRef.componentInstance.cancel = "Annuler";
            let result = false;
            try {
                result = yield modalRef.result;
            }
            catch (_a) { }
            if (result) {
                this.busy = true;
                try {
                    const db = firebase.firestore();
                    const type = yield this.getClientType(this.id);
                    const req = this.hiero.DB.collection("particuliers_commandes").where("prestationId", "==", this.id);
                    const reqPro = this.hiero.DB.collection("professionnel_commandes").where("prestationId", "==", this.id);
                    const req2 = this.hiero.DB.collection("facture_particuliers").where("prestation", "==", this.id);
                    const req3 = this.hiero.DB.collection("facture").where("prestation", "==", this.id);
                    req.get().then((snap) => {
                        if (snap.docs[0] !== undefined) {
                            db.collection("particuliers_commandes")
                                .doc(snap.docs[0].id)
                                .delete()
                                .then(() => {
                                db.collection("prestations")
                                    .doc(this.id)
                                    .delete()
                                    .then(() => {
                                    req2.get().then((snap) => {
                                        db.collection("facture_particuliers")
                                            .doc(snap.docs[0].id)
                                            .delete();
                                    });
                                });
                            })
                                .then((value) => this.router.navigate(["app/commandes"]))
                                .catch((error) => {
                                console.error("Error removing document: ", error);
                            });
                            this.router.navigate(["app/commandes"]);
                        }
                        else {
                            reqPro.get().then((snap) => {
                                db.collection("professionnel_commandes")
                                    .doc(snap.docs[0].id)
                                    .delete()
                                    .then(() => {
                                    db.collection("prestations")
                                        .doc(this.id)
                                        .delete()
                                        .then(() => {
                                        req3.get().then((snap) => {
                                            db.collection("facture").doc(snap.docs[0].id).delete();
                                        });
                                    });
                                })
                                    .then((value) => this.router.navigate(["app/commandes"]))
                                    .catch((error) => {
                                    console.error("Error removing document: ", error);
                                });
                            });
                        }
                    });
                }
                catch (err) {
                    console.error(err.message);
                }
                finally {
                    this.busy = false;
                }
            }
        });
    }
    clientName(id) {
        const req = this.hiero.DB.collection("particuliers_commandes").where("prestationId", "==", id);
        return req.get().then((snapshot) => {
            if (snapshot.docs[0] !== undefined) {
                return this.hiero.DB.collection("users")
                    .doc(snapshot.docs[0].data().prestationInfo.uid)
                    .get()
                    .then((snap) => {
                    return (snap.data().profile.givenName +
                        " " +
                        snap.data().profile.familyName);
                });
            }
            else {
                return this.hiero.DB.collection("professionnel_commandes")
                    .where("prestationId", "==", id)
                    .get()
                    .then((snap) => {
                    return this.hiero.DB.collection("professionnels")
                        .doc(snap.docs[0].data().professionnelId)
                        .get()
                        .then((snap) => {
                        return snap.data().businessName;
                    });
                });
            }
        });
    }
    profile(id) {
        this.router.navigate(["app/users", id]);
    }
    getDocumentName(code) {
        if (this.documentTypeMap) {
            return this.documentTypeMap.GetDocumentName(this.localisation.CurrentLanguageISO639, code);
        }
        else {
            return "";
        }
    }
    openFile(prestation, document, file, extra) {
        return __awaiter(this, void 0, void 0, function* () {
            const fileObj = new File(this.hiero.Storage, this.command.prestationInfo.uid, prestation, document.deviceStorageId, file);
            // console.log('Mon objet sur hiero  =>' ,fileObj)
            try {
                let link;
                extra
                    ? (link = yield fileObj.GetDownloadLinkExtra())
                    : (link = yield fileObj.GetDownloadLink());
                // console.log('ATTENTION CETTE CONSOLE LOG DOIT ETRE EFFACER NE PAS OUBLIEE (link)', link )
                window.open(link, "_blank");
            }
            catch (err) {
                console.error("Voici l erreur coté hiero =>", err);
            }
        });
    }
    redirectToOtherTrad() {
        swal({
            text: " Cette fonctionnalité arrive très bientôt. ",
        });
    }
}
