import { Injectable } from "@angular/core";
import {
  PrestationType,
  TypeProfessionnal,
} from "../../../../common/src/bdd/interfaces/types";
import * as firebase from "firebase";
import { COLLECTION } from "../../../../common/src/bdd/utility/collections";

@Injectable()
export class ProFactureService {
  constructor() {}
  public getFactures() {}

  public getFacture(id: string) {}

  public async addFacture(
    interpretationId,
    event,
    formValue,
    price,
    translatorId,
    translatorName,
    professionnelId,
    professionnelName,
    professionnelType
  ) {
    let facture = {
      numero: Math.floor(Math.random() * 1000000 + 1),
      event: event,
      date: new Date(),
      srcLang: formValue.srcLang,
      destLang: formValue.destLang,
      priceTTC: price.priceTTC,
      priceHT: price.priceHT,
      priceTVA: price.priceTVA,
      priceTVAVal: price.priceTVAVal,
      traducteur: translatorId,
      traducteurName: translatorName,
      prestation: interpretationId,
      typePrestation: PrestationType.INTERPRETATION,
      professionnelId: professionnelId,
    };

    if (professionnelType === TypeProfessionnal.seineMaritime)
      facture["isFactureSeineMaritime"] = true;
    if (professionnelType === TypeProfessionnal.fidinam)
      facture["isFactureFidinam"] = true;

    await firebase
      .firestore()
      .collection(COLLECTION.FACTURE)
      .add(facture)
      .then(() => {
        firebase
          .firestore()
          .collection("mail")
          .add({
            bcc: [
              "B.balsomi@hiero-solution.com",
              "a.luis@hiero-solution.com",
              "w.moingt@hiero-solution.com",
              "n.ziani@hiero-solution.com",
            ],
            message: {
              subject: "Nouvelle interpretation",
              text: "Nouvelle interpretation",
              html: `  <div style=" font-family: "Helvetica Neue", sans-serif; font-size: 20px;"> <div style="font-weight: bold"> <img src="https://firebasestorage.googleapis.com/v0/b/hiero-prod.appspot.com/o/Logos%2Fbird.png?alt=media&token=fa72f85d-1c09-48c1-ab47-ee7cde0eb80b" alt="" width="50" /> Hierø Solution </div> <hr /> <h3 style="text-align: center">Nouvelle interpretation</h3> <p>Bonjour,</p><p style="line-height: 2; text-align:justify"> Nous avons une nouvelle commande d'interpretation dans notre système du client ${professionnelName}. Retrouve les détails de cette prestation dans ton espace admin. <p>Bien cordialement,</p> <p>L\'équipe Hierø</p> <hr /><div><h3 style= "text-align:center">Description</h3><p > ${formValue.context}</p></div> </div> `,
            },
          })
          .then(() => console.log("Queued email for delivery!"));
      });
  }
}
