var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { TitleExtractor, } from "../../../../../common/src/utility/title_extractor.class";
import { HieroBDD } from "../../../services/hierobdd.service";
export class AppBaseComponent {
    constructor(hiero, router, route) {
        this.hiero = hiero;
        this.router = router;
        this.route = route;
        this.id = this.hiero.Auth.User.Id;
        this.titles = [];
        this._titleExtractor = new TitleExtractor(route, (key) => {
            // Replace strings here if necessary
            return "";
        }, (titles) => {
            this.titles = titles;
        });
    }
    ngOnInit() {
        this._subscription = this.hiero.Auth.WatchUser({
            next: (user) => {
                this.user = user;
                if (this.user) {
                    this.user.WatchProfile({
                        next: (profile) => {
                            this.profile = profile;
                        },
                    });
                }
            },
        });
        this._titleExtractor.update();
        this._routerSubs = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this._titleExtractor.update();
            }
        });
    }
    getTitle() {
        if (this.titles && this.titles.length > 0) {
            return this.titles[0].title;
        }
        else {
            return "";
        }
    }
    ngOnDestroy() {
        this._subscription.unsubscribe();
        this._routerSubs.unsubscribe();
    }
    logout() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.hiero.Auth.logout();
            this.router.navigate(["/"]);
        });
    }
}
