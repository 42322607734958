import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as firebase from "firebase";
import { HieroBDD } from "../../services/hierobdd.service";
import { AppModalConfirmComponent } from "../../../../common/src/utility/modal/confirm-modal.component";
import { LocalisationService } from "../../../../common/src/modules/localisation/localisation.service";
import { IPrestation } from "../../../../common/src/bdd/interfaces/IPrestation";
import { DocumentType } from "../../../../common/src/bdd/documents/DocumentType";
import { IDocument } from "../../../../common/src/bdd/interfaces/IDocument";
import { IFile } from "../../../../common/src/bdd/interfaces/IFile";
import { File } from "../../../../common/src/bdd/prestation/File";
import swal from "sweetalert";

@Component({
  selector: "single-command",
  templateUrl: "./single-command.component.html",
  styleUrls: ["./single-command.component.scss"],
})
export class SingleCommandComponent implements OnInit {
  id = this.route.snapshot.paramMap.get("id");
  command;
  clientType;
  name;
  busy = true;
  documentTypeMap: DocumentType;

  constructor(
    private route: ActivatedRoute,
    private hiero: HieroBDD,
    private router: Router,
    private modalService: NgbModal,
    private localisation: LocalisationService
  ) {}

  ngOnInit(): void {
    this.getCommand(this.id).then((value) => (this.command = value));
    this.getClientType(this.id).then((value) => (this.clientType = value));
    this.clientName(this.id).then((value) => (this.name = value));
  }

  resetPage(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = "reload";
    this.router.navigate(["/app", "commandes", this.id], {
      relativeTo: this.route,
      queryParamsHandling: "merge",
    });
  }

  async updateCommandStatus(id: string, status: string) {
    const statusView: string =
      status === "refused-by-translator" ? "refuser" : "valider";
    const statusBtn: string =
      status === "refused-by-translator" ? "Refuser" : "Valider";
    const modalRef = this.modalService.open(AppModalConfirmComponent, {
      centered: true,
    });
    modalRef.componentInstance.header = "Confirmer le changement de statut";
    modalRef.componentInstance.message = `Voulez-vous vraiment ${statusView} cette commande ? Ce changement de statut est irréversible.`;
    modalRef.componentInstance.ok = statusBtn;
    modalRef.componentInstance.cancel = "Annuler";

    let result = false;
    try {
      result = await modalRef.result;
    } catch {}

    if (result) {
      this.busy = true;
      try {
        const req = this.hiero.DB.collection("particuliers_commandes").where(
          "prestationId",
          "==",
          id
        );
        const req2 = this.hiero.DB.collection("professionnel_commandes").where(
          "prestationId",
          "==",
          id
        );

        req.get().then((snapshot) => {
          if (snapshot.docs[0] !== undefined) {
            const requestClient = this.hiero.DB.collection(
              "particuliers_commandes"
            ).doc(snapshot.docs[0].id);
            if (status === "refused-by-translator") {
              requestClient
                .update({
                  "prestationInfo.state": status,
                  "prestationInfo.refusedByTranslatorAt": new Date().getTime(),
                })
                .then((value) => {
                  this.hiero.DB.collection("prestations").doc(id).update({
                    state: status,
                    refusedByTranslatorAt: new Date().getTime(),
                  });
                })
                .then((value) => this.resetPage());
            } else {
              requestClient
                .update({
                  "prestationInfo.state": status,
                  "prestationInfo.validatedByClientAt": new Date().getTime(),
                })
                .then((value) => {
                  this.hiero.DB.collection("prestations").doc(id).update({
                    state: status,
                    validatedByClientAt: new Date().getTime(),
                  });
                })
                .then((value) => this.resetPage());
            }
          } else {
            return req2.get().then((snap) => {
              const requestPro = this.hiero.DB.collection(
                "professionnel_commandes"
              ).doc(snap.docs[0].id);
              if (status === "refused-by-translator") {
                requestPro
                  .update({
                    "prestationInfo.state": status,
                    "prestationInfo.refusedByTranslatorAt":
                      new Date().getTime(),
                  })
                  .then((value) => {
                    this.hiero.DB.collection("prestations").doc(id).update({
                      state: status,
                      refusedByTranslatorAt: new Date().getTime(),
                    });
                  })
                  .then((value) => this.resetPage());
              } else {
                requestPro
                  .update({
                    "prestationInfo.state": "validated-pro",
                    "prestationInfo.validatedByClientAt": new Date().getTime(),
                  })
                  .then((value) => {
                    this.hiero.DB.collection("prestations").doc(id).update({
                      state: "validated-pro",
                      validatedByClientAt: new Date().getTime(),
                    });
                  })
                  .then((value) => this.resetPage());
              }
            });
          }
        });
      } catch (err) {
        console.error(err.message);
      } finally {
        this.busy = false;
      }
    }
  }

  getCommand(id) {
    const req = this.hiero.DB.collection("particuliers_commandes").where(
      "prestationId",
      "==",
      id
    );
    const req2 = this.hiero.DB.collection("professionnel_commandes").where(
      "prestationId",
      "==",
      id
    );

    return req.get().then((snapshot) => {
      if (snapshot.docs[0] !== undefined) {
        return snapshot.docs[0].data();
      } else {
        return req2.get().then((snapshot) => {
          return snapshot.docs[0].data();
        });
      }
    });
  }

  getClientType(id) {
    const req = this.hiero.DB.collection("particuliers_commandes").where(
      "prestationId",
      "==",
      id
    );

    return req.get().then((snapshot) => {
      if (snapshot.docs[0] !== undefined) {
        return "Particulier";
      } else {
        return "Professionnel";
      }
    });
  }

  async deleteCommand() {
    const modalRef = this.modalService.open(AppModalConfirmComponent, {
      centered: true,
    });
    modalRef.componentInstance.header = "Confirmer la suppression";
    modalRef.componentInstance.message =
      "Voulez-vous vraiment supprimer cette commande ? Cette action est irréversible.";
    modalRef.componentInstance.ok = "Supprimer";
    modalRef.componentInstance.cancel = "Annuler";

    let result = false;
    try {
      result = await modalRef.result;
    } catch {}

    if (result) {
      this.busy = true;
      try {
        const db = firebase.firestore();

        const type = await this.getClientType(this.id);

        const req = this.hiero.DB.collection("particuliers_commandes").where(
          "prestationId",
          "==",
          this.id
        );

        const reqPro = this.hiero.DB.collection(
          "professionnel_commandes"
        ).where("prestationId", "==", this.id);

        const req2 = this.hiero.DB.collection("facture_particuliers").where(
          "prestation",
          "==",
          this.id
        );

        const req3 = this.hiero.DB.collection("facture").where(
          "prestation",
          "==",
          this.id
        );

        req.get().then((snap) => {
          if (snap.docs[0] !== undefined) {
            db.collection("particuliers_commandes")
              .doc(snap.docs[0].id)
              .delete()
              .then(() => {
                db.collection("prestations")
                  .doc(this.id)
                  .delete()
                  .then(() => {
                    req2.get().then((snap) => {
                      db.collection("facture_particuliers")
                        .doc(snap.docs[0].id)
                        .delete();
                    });
                  });
              })
              .then((value) => this.router.navigate(["app/commandes"]))
              .catch((error) => {
                console.error("Error removing document: ", error);
              });

            this.router.navigate(["app/commandes"]);
          } else {
            reqPro.get().then((snap) => {
              db.collection("professionnel_commandes")
                .doc(snap.docs[0].id)
                .delete()
                .then(() => {
                  db.collection("prestations")
                    .doc(this.id)
                    .delete()
                    .then(() => {
                      req3.get().then((snap) => {
                        db.collection("facture").doc(snap.docs[0].id).delete();
                      });
                    });
                })
                .then((value) => this.router.navigate(["app/commandes"]))
                .catch((error) => {
                  console.error("Error removing document: ", error);
                });
            });
          }
        });
      } catch (err) {
        console.error(err.message);
      } finally {
        this.busy = false;
      }
    }
  }

  clientName(id) {
    const req = this.hiero.DB.collection("particuliers_commandes").where(
      "prestationId",
      "==",
      id
    );

    return req.get().then((snapshot) => {
      if (snapshot.docs[0] !== undefined) {
        return this.hiero.DB.collection("users")
          .doc(snapshot.docs[0].data().prestationInfo.uid)
          .get()
          .then((snap) => {
            return (
              snap.data().profile.givenName +
              " " +
              snap.data().profile.familyName
            );
          });
      } else {
        return this.hiero.DB.collection("professionnel_commandes")
          .where("prestationId", "==", id)
          .get()
          .then((snap) => {
            return this.hiero.DB.collection("professionnels")
              .doc(snap.docs[0].data().professionnelId)
              .get()
              .then((snap) => {
                return snap.data().businessName;
              });
          });
      }
    });
  }

  profile(id: string) {
    this.router.navigate(["app/users", id]);
  }

  getDocumentName(code: string) {
    if (this.documentTypeMap) {
      return this.documentTypeMap.GetDocumentName(
        this.localisation.CurrentLanguageISO639,
        code
      );
    } else {
      return "";
    }
  }

  async openFile(
    prestation: IPrestation,
    document: IDocument,
    file: IFile,
    extra: boolean
  ) {
    const fileObj: File = new File(
      this.hiero.Storage,
      this.command.prestationInfo.uid,
      prestation,
      document.deviceStorageId,
      file
    );
    // console.log('Mon objet sur hiero  =>' ,fileObj)
    try {
      let link;
      extra
        ? (link = await fileObj.GetDownloadLinkExtra())
        : (link = await fileObj.GetDownloadLink());
      // console.log('ATTENTION CETTE CONSOLE LOG DOIT ETRE EFFACER NE PAS OUBLIEE (link)', link )
      window.open(link, "_blank");
    } catch (err) {
      console.error("Voici l erreur coté hiero =>", err);
    }
  }

  redirectToOtherTrad() {
    swal({
      text: " Cette fonctionnalité arrive très bientôt. ",
    });
  }
}
