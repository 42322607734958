<div class="container-fluid">
  <div class="d-flex">
    <h3 class="text-fidinam">Historique des factures</h3>
    <!--  <button class="ml-3 rounded-pill" style="background-color: #702963; color: white">Modifier mon addresse de facturation</button> -->
  </div>
  <table class="table table-striped" id="content" #content>
    <thead>
      <tr>
        <th scope="col" class="align-middle">Date</th>
        <th scope="col" class="align-middle">&#8470; facture</th>
        <th scope="col" class="align-middle">&#8470; commande</th>
        <th scope="col" class="align-middle">Type de prestation</th>
        <th scope="col" class="align-middle">Montant Total</th>
        <!--<th scope="col">Handle</th> -->
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="
          let facture of factures
            | paginate: { itemsPerPage: 10, currentPage: p }
        "
      >
        <td class="align-middle">
          <button class="btn btn-fidinam-date" disabled>
            {{ facture.date.toDate() | date: "dd/MM/yyyy" }}
          </button>
        </td>
        <td class="align-middle">{{ facture.numero }}</td>
        <td class="align-middle">{{ facture.prestation }}</td>
        <td
          class="align-middle"
          *ngIf="facture.typePrestation === 'Traduction assermentée'"
        >
          {{ "traduction_assermentee" | localise }}
        </td>
        <td
          class="align-middle"
          *ngIf="facture.typePrestation === 'Traduction classique'"
        >
          {{ "traduction_classic" | localise }}
        </td>
        <td
          class="align-middle"
          *ngIf="facture.typePrestation === 'Interprétation'"
        >
          {{ "interpretation" | localise }}
        </td>
        <td class="align-middle">{{ facture.priceTTC.toFixed(2) }} €</td>
        <td class="align-middle justify-content-around">
          <!--  <button class=" btn my-color mr-1" [routerLink]="[facture.value.id]" (click)="savePDF()" ><i class="fa fa-download"></i> {{'section_generate_facture' | localise}} </button> -->
          <button class="btn btn-fidinam mr-1" (click)="download(facture, 2)">
            <i class="fa fa-download"></i>
            {{ "section_generate_facture" | localise }}
          </button>
          <button
            class="btn btn-outline-fidinam mr-1"
            (click)="download(facture, 1)"
          >
            <i class="fa fa-print"></i> {{ "section_print" | localise }}
          </button>

          <!--(click)="savePDF()"-->
        </td>
      </tr>
    </tbody>
  </table>
  <div class="d-flex justify-content-center mt-4">
    <pagination-controls
      (pageChange)="p = $event"
      class="my-pagination"
    ></pagination-controls>
  </div>
</div>
