import { Professionnel } from "./Professionnel";
import { IProfessionnelService } from "../interfaces/IProfessionnelService";
import { BehaviorSubject } from "rxjs/BehaviorSubject";
import { PartialObserver, Subscription } from "rxjs";
import { IPrestation } from "../interfaces/IPrestation";

const FIREBASE_COLLECTION = "professionnel_commandes";

export class ProfessionnelService {
  private _professionnel: Professionnel;
  private _docId: string;
  private _data: IProfessionnelService;

  constructor(
    professionnel: Professionnel,
    docId: string,
    data: IProfessionnelService
  ) {
    this._professionnel = professionnel;
    this._docId = docId;
    this._data = data;
  }

  get Id(): string {
    return this._docId;
  }

  get data(): IProfessionnelService {
    return this._data;
  }

  get Professionnel(): Professionnel {
    return this._professionnel;
  }
}

export class ProfessionnelServiceList {
  constructor(professionnel: Professionnel) {
    this._professionnel = professionnel;
    this._services = [];
    this._servicesSubject = new BehaviorSubject<ProfessionnelService[]>(
      this._services
    );

    const query = professionnel.User.DB.collection(FIREBASE_COLLECTION).where(
      "professionnelId",
      "==",
      professionnel.Id
    );
    query.onSnapshot({
      next: (snapshort: firebase.firestore.QuerySnapshot) => {
        this._services = [];

        snapshort.forEach(
          (result: firebase.firestore.QueryDocumentSnapshot) => {
            const data: IProfessionnelService =
              result.data() as IProfessionnelService;
            const cmd: ProfessionnelService = new ProfessionnelService(
              professionnel,
              result.id,
              data
            );

            this._services.push(cmd);
          }
        );
      },
    });
    // tslint:disable-next-line:label-position no-unused-expression
    error: (error: Error) => {};

    try {
      query.get();
    } catch (err) {
      console.warn(err);
    }
  }
  private _professionnel: Professionnel;
  private _services: ProfessionnelService[];
  private _servicesSubject: BehaviorSubject<ProfessionnelService[]>;
  // list commande
  public WatchProfessionnelServiceList(
    observer: PartialObserver<ProfessionnelService[]>
  ): Subscription {
    return this._servicesSubject.subscribe(observer);
  }
  // Add commande
  public async addService(proService: IProfessionnelService) {
    const commande: IProfessionnelService = {
      professionnelId: proService.professionnelId,
      srcLanguageIso639: proService.srcLanguageIso639,
      originLanguage: proService.originLanguage,
      destLanguageIso639: proService.destLanguageIso639,
      documentType: proService.documentType,
      docFile: proService.docFile,
      traducteurName: proService.traducteurName,
      traducteurId: proService.traducteurId,
      budget: proService.budget,
      createdAt: proService.createdAt,
      prestationId: proService.prestationId,
      prestationInfo: proService.prestationInfo,
      pagesNumber: proService.pagesNumber,
      urgency: proService.urgency,
      description: proService.description,
      devisId: proService.devisId,
    };

    if (proService.isCommandSeineMaritime)
      commande["isCommandSeineMaritime"] = true;
    if (proService.isCommandFidinam) commande["isCommandFidinam"] = true;
    if (proService.isCommandExpat) commande["isCommandExpat"] = true;

    const exist = this._services.find((service) => {
      return (
        service.data.srcLanguageIso639 === proService.srcLanguageIso639 &&
        service.data.destLanguageIso639 === proService.destLanguageIso639 &&
        service.data.originLanguage === proService.originLanguage &&
        service.data.budget === proService.budget &&
        service.data.documentType === proService.documentType &&
        service.data.docFile === proService.docFile &&
        service.data.traducteurName === proService.traducteurName
      );
    });
    if (exist) {
      throw Error("This order service already exist");
    }
    await this._professionnel.User.DB.collection(FIREBASE_COLLECTION).add(
      commande
    );
  }

  public async removeService(prestationId: string) {
    await this._professionnel.User.DB.collection(FIREBASE_COLLECTION)
      .doc(prestationId)
      .delete();
  }

  public getSrcLanguages(professionnel: Professionnel): any {
    const documentTypeRef =
      professionnel.User.DB.collection("app_indexes").doc("services");
    documentTypeRef
      .get()
      .then((snapshot) => {
        const lang = snapshot.get("languages");
        // console.log(lang);

        /*
        for(const key in data){
          const value = data[key];
          return (value['iso639']);
        }
        */
      })
      .catch();
  }

  // brin test
  public async getList(professionnel: Professionnel): Promise<boolean> {
    {
      const query = professionnel.User.DB.collection(FIREBASE_COLLECTION).where(
        "professionnelId",
        "==",
        professionnel.Id
      );
      const results: firebase.firestore.QuerySnapshot = await query.get();
      if (results) {
        return results.size > 0;
      } else {
        return false;
      }
    }
  }

  public static async Init(
    professionnel: Professionnel
  ): Promise<ProfessionnelServiceList> {
    return Promise.resolve(new ProfessionnelServiceList(professionnel));
  }

  public static async HasService(
    professionnel: Professionnel
  ): Promise<boolean> {
    const query = professionnel.User.DB.collection(FIREBASE_COLLECTION).where(
      "professionnelId",
      "==",
      professionnel.Id
    );
    const results: firebase.firestore.QuerySnapshot = await query.get();
    if (results) {
      return results.size > 0;
    } else {
      return false;
    }
  }
}
