var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit } from "@angular/core";
import { HieroBDD } from "../../../services/hierobdd.service";
import { Professionnel } from "../../../../../common/src/bdd/professionnel/Professionnel";
import { ProfessionnelServiceList } from "../../../../../common/src/bdd/professionnel/ProfessionnelService";
import { Prestation } from "../../../../../common/src/bdd/prestation/Prestation";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { LocalisationService } from "../../../../../common/src/modules/localisation/localisation.service";
import * as i18nIsoLanguages from "@cospired/i18n-iso-languages";
import * as jsPDF from "jspdf";
import { ProInterpretationsService } from "../../../services/pro/pro-interpretations.service";
import { ProDevisService } from "../../../services/pro/pro-devis.service";
import { PrestationType, TypeProfessionnal, } from "../../../../../common/src/bdd/interfaces/types";
import { EnumDevisState } from "../../../../../common/src/bdd/interfaces/IDevis";
var doc = new jsPDF();
var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
let img = new Image();
img.src = "../../../../assets/img/logo/fact-blue.png";
let tamponRefused = new Image();
tamponRefused.src =
    "../../../../assets/img/tampon/image_2022-05-03_12-49-42.png";
let tamponAccepted = new Image();
tamponAccepted.src = "../../../../assets/img/tampon/MicrosoftTeams-image.png";
export const ROOM_LINK = "https://video-app-6852-1089-dev.twil.io?passcode=87123868521089";
export var EnumPrestationState;
(function (EnumPrestationState) {
    EnumPrestationState["Defining"] = "defining";
    EnumPrestationState["WaitingForTranslator"] = "waiting-for-translator";
    EnumPrestationState["CancelledByClient"] = "cancelled-by-client";
    EnumPrestationState["WaitingForPayment"] = "waiting-for-payment";
    EnumPrestationState["RefusedByTranslator"] = "refused-by-translator";
    EnumPrestationState["Translating"] = "translating";
    EnumPrestationState["WaitingForValidationFromClient"] = "waiting-for-client-to-validate";
    EnumPrestationState["Validated"] = "validated";
})(EnumPrestationState || (EnumPrestationState = {}));
export class SeineComponent {
    constructor(hiero, router, _location, localisation, proInterpretationsService, devisService) {
        this.hiero = hiero;
        this.router = router;
        this._location = _location;
        this.localisation = localisation;
        this.proInterpretationsService = proInterpretationsService;
        this.devisService = devisService;
        this.date = new Date();
        this.p = 1;
        this.EmptyPrestation = {
            uid: "",
            state: EnumPrestationState.WaitingForTranslator,
            srcLanguageIso639: "",
            destLanguageIso639: "",
            srcCountryCode: "",
            documents: [],
            traducteurId: null,
            traducteur: null,
            price: {
                traducteurHT: 0,
                hieroMarginPercent: 0,
                hieroMarginValue: 0,
                subtotalHT: 0,
                tva: 0,
                tvaValue: 0,
                ttc: 0,
            },
            deviceStorageId: "",
            sentToTranslatorAt: 0,
            cancelledByClientAt: 0,
            acceptedByTranslatorAt: 0,
            refusedByTranslatorAt: 0,
            dueAt: 0,
            paidAt: 0,
            completedAt: 0,
            validatedByClientAt: 0,
            lastModifiedAt: 0,
            createdAt: 0,
            isProfessionalPrestation: false,
        };
        // function to sort all commands by date (from recent to old)
        this.sortFn = (x, y) => {
            if (x.data || y.data) {
                if (x.data.createdAt)
                    if (y.data.createdAt)
                        return y.data.createdAt.toDate() - x.data.createdAt.toDate();
                    else
                        return (new Date(y.data.missions.date) - x.data.createdAt.toDate());
                else {
                    if (y.data.createdAt)
                        return (y.data.createdAt.toDate() - new Date(x.data.missions.date));
                    else
                        return (new Date(y.data.missions.date) -
                            new Date(x.data.missions.date));
                }
            }
            else {
                if (x.createdAt)
                    if (y.createdAt)
                        return y.createdAt.toDate() - x.createdAt.toDate();
                    else
                        return new Date(y.missions.date) - x.createdAt.toDate();
                else {
                    if (y.createdAt)
                        return y.createdAt.toDate() - new Date(x.missions.date);
                    else
                        return (new Date(y.missions.date) - new Date(x.missions.date));
                }
            }
        };
    }
    ngOnInit() {
        return __awaiter(this, void 0, void 0, function* () {
            this.allDevis().then((value) => (this.devis = value));
            this.allCommands().then((value) => {
                this.command = value;
            });
            this.allUsers().then((value) => (this.usersSeine = value));
            this.usersThisMonth().then((value) => (this.usersMonth = value));
            this.allCommands().then((value) => (this.commandsNbr = value.length));
            this.salesRevenues().then((value) => (this.sales = value));
            this.salesRevenuesThisMonth().then((value) => (this.salesMonth = value));
            this.allCommandsThisMonth().then((value) => (this.commandThisMonth = value));
            this.adminSeine = yield this.adminInfo();
            this.deviOrCommand = JSON.parse(localStorage.getItem("isDevi"));
            if (this.deviOrCommand === null) {
                this.deviOrCommand = true;
            }
        });
    }
    switchBetweenDeviCommands() {
        localStorage.setItem("isDevi", JSON.stringify(!this.deviOrCommand));
        this.deviOrCommand = JSON.parse(localStorage.getItem("isDevi"));
    }
    getClientData(uid) {
        return __awaiter(this, void 0, void 0, function* () {
            const user = this.hiero.DB.collection("users").doc(uid);
            const snap = yield user.get();
            return snap.data();
        });
    }
    getAdressPro(proId) {
        return __awaiter(this, void 0, void 0, function* () {
            const docRef = yield this.hiero.DB.collection("professionnels")
                .doc(proId)
                .get();
            return docRef.data();
        });
    }
    allDevis() {
        return this.hiero.DB.collection("devis")
            .where("isDevisSeineMaritime", "==", true)
            .orderBy("date", "desc")
            .get()
            .then((snap) => {
            return snap.docs;
        });
    }
    // get all traslations of seine maritime
    getTrad() {
        return __awaiter(this, void 0, void 0, function* () {
            const missions = this.hiero.DB.collection("professionnel_commandes").where("isCommandSeineMaritime", "==", true);
            return missions.get().then((trouve) => {
                return trouve.docs;
            });
        });
    }
    //  get all interpretations of seine maritime
    getInterpretations() {
        return __awaiter(this, void 0, void 0, function* () {
            const interpretation = this.hiero.DB.collection("interpretations_commandes").where("isInterpretationSeineMaritime", "==", true);
            return interpretation.get().then((trouve) => {
                return trouve.docs;
            });
        });
    }
    allCommands() {
        return __awaiter(this, void 0, void 0, function* () {
            let trads = yield this.getTrad();
            let inters = yield this.getInterpretations();
            let listCommand = [];
            for (let i = 0; i < trads.length; i++) {
                listCommand.push(trads[i].data());
            }
            for (let i = 0; i < inters.length; i++) {
                listCommand.push(inters[i].data());
            }
            // order the commands by date : from recent ones to old ones
            listCommand.sort(this.sortFn);
            return listCommand;
        });
    }
    allCommandsThisMonth() {
        return __awaiter(this, void 0, void 0, function* () {
            const date = new Date();
            const start = new Date(date.getFullYear(), date.getMonth(), 1);
            return this.hiero.DB.collection("devis")
                .where("isDevisSeineMaritime", "==", true)
                .where("state", "==", EnumDevisState.AcceptedByClient)
                .where("date", ">=", start)
                .get()
                .then((snap) => {
                return snap.docs.length;
            });
        });
    }
    salesRevenues() {
        return __awaiter(this, void 0, void 0, function* () {
            let prices = [];
            let sum = 0;
            return this.hiero.DB.collection("devis")
                .where("isDevisSeineMaritime", "==", true)
                .where("state", "==", EnumDevisState.AcceptedByClient)
                .get()
                .then((snap) => {
                snap.docs.map((price) => (prices = [price.data().priceTTC, ...prices]));
            })
                .then(() => {
                const initialValue = 0;
                sum = prices.reduce((previousValue, currentValue) => previousValue + currentValue, initialValue);
            })
                .then(() => {
                return sum.toFixed(2);
            });
        });
    }
    salesRevenuesThisMonth() {
        let prices = [];
        let sum = 0;
        const date = new Date();
        const start = new Date(date.getFullYear(), date.getMonth(), 1);
        return this.hiero.DB.collection("devis")
            .where("isDevisSeineMaritime", "==", true)
            .where("state", "==", EnumDevisState.AcceptedByClient)
            .where("date", ">=", start)
            .get()
            .then((snapshot) => {
            snapshot.docs.map((price) => (prices = [price.data().priceTTC, ...prices]));
        })
            .then(() => {
            const initialValue = 0;
            sum = prices.reduce((previousValue, currentValue) => previousValue + currentValue, initialValue);
        })
            .then(() => {
            return sum.toFixed(2);
        });
    }
    getSingleDevis(id) {
        return this.hiero.DB.collection("devis")
            .doc(id)
            .get()
            .then((snap) => {
            return snap.data();
        });
    }
    adminInfo() {
        return __awaiter(this, void 0, void 0, function* () {
            const currentUser = this.hiero.Auth.User;
            const docRef = yield this.hiero.DB.collection("professionnels")
                .where("uid", "==", currentUser.Id)
                .get();
            let pro = docRef.docs;
            this.adminId = pro[0].id;
            return pro[0].data();
        });
    }
    updateBudget(price) {
        if (this.adminSeine.budget > 0) {
            this.adminSeine.budget = this.adminSeine.budget - price;
            this.hiero.DB.collection("professionnels").doc(this.adminId).update({
                budget: this.adminSeine.budget,
            });
        }
    }
    sendCommand(id) {
        return __awaiter(this, void 0, void 0, function* () {
            this.singleDevis = yield this.getSingleDevis(id);
            this.client = yield this.getClientData(this.singleDevis.professionnelUid);
            /********************************
             * Faut récupérer l'objet User du pro qui a passé la commande pour le passer dans cette méthode
             */
            const pro = new Professionnel(this.hiero.Auth.User, this.adminId);
            //let pro = this.singleDevis.professionnelData as Professionnel;
            if (this.singleDevis.typePrestation === "Interprétation") {
                this.proInterpretationsService.addProInterpretation(this.singleDevis.event, this.singleDevis.missions, this.singleDevis.translatorName, this.singleDevis.translatorId, this.singleDevis.professionnelName, this.singleDevis.professionnelId, this.singleDevis.professionnelUid, TypeProfessionnal.seineMaritime, this.singleDevis.price, id);
            }
            else {
                const commands = new ProfessionnelServiceList(pro);
                this.EmptyPrestation.srcLanguageIso639 = this.singleDevis.srcLang;
                this.EmptyPrestation.price.ttc = this.singleDevis.priceTTC;
                this.EmptyPrestation.price.traducteurHT = this.singleDevis.priceHT;
                this.EmptyPrestation.price.tva = this.singleDevis.priceTVA;
                this.EmptyPrestation.price.tvaValue = this.singleDevis.priceTVAVal;
                if (this.singleDevis.originLangISO369 !== "classic")
                    this.EmptyPrestation.srcCountryCode = this.singleDevis.originLangISO369;
                else
                    this.EmptyPrestation.srcCountryCode = "";
                this.EmptyPrestation.destLanguageIso639 = this.singleDevis.destLang;
                this.EmptyPrestation.traducteurId = this.singleDevis.tradId;
                this.EmptyPrestation.documents = this.singleDevis.presta.documents;
                this.EmptyPrestation.uid = this.singleDevis.professionnelUid;
                this.EmptyPrestation.deviceStorageId = this.singleDevis.professionnelUid; // id prestation device
                this.EmptyPrestation.traducteur = this.singleDevis.presta.traducteur;
                this.EmptyPrestation.isProfessionalPrestation = true;
                /********************************
                 * Faut récupérer l'objet User du pro qui a passé la commande pour le passer dans cette méthode
                 * car sinon la prestation est créée avec l'uid de l'admin
                 */
                const newPrestation = yield Prestation.Create(this.hiero.Auth.User, this.EmptyPrestation);
                this.EmptyPrestation.uid = this.singleDevis.professionnelUid;
                let command = {
                    srcLanguageIso639: this.singleDevis.srcLang,
                    originLanguage: this.singleDevis.originLangISO369,
                    destLanguageIso639: this.singleDevis.destLang,
                    documentType: this.singleDevis.documentType,
                    docFile: this.singleDevis.docFile,
                    traducteurName: this.singleDevis.traducteurName,
                    traducteurId: this.singleDevis.tradId,
                    budget: this.singleDevis.budget,
                    createdAt: new Date(),
                    prestationId: newPrestation.Id,
                    prestationInfo: this.EmptyPrestation,
                    pagesNumber: this.singleDevis.pagesNumber,
                    urgency: this.singleDevis.urgency,
                    description: this.singleDevis.description,
                    professionnelId: this.singleDevis.professionnelId,
                    devisId: id,
                    isCommandSeineMaritime: true,
                };
                let facture = {
                    numero: Math.floor(Math.random() * 1000000 + 1),
                    date: new Date(),
                    devisId: id,
                    srcLang: this.singleDevis.srcLang,
                    destLang: this.singleDevis.destLang,
                    priceTTC: this.singleDevis.priceTTC,
                    priceHT: this.singleDevis.priceHT,
                    priceTVA: this.singleDevis.priceTVA,
                    priceTVAVal: this.singleDevis.priceTVAVal,
                    traducteur: this.singleDevis.traducteur,
                    traducteurName: this.singleDevis.traducteurName,
                    pagesNumber: this.singleDevis.pagesNumber,
                    prestation: newPrestation.Id,
                    typePrestation: this.singleDevis.typePrestation,
                    professionnelId: this.singleDevis.professionnelId,
                    purchaseOrder: this.singleDevis.purchaseOrder,
                    isFactureSeineMaritime: true,
                    product: command,
                    client: this.client,
                    adresseClient: this.singleDevis.professionnelData,
                };
                yield commands.addService(command);
                // await  this.updateBudget();
                yield this.hiero.DB.collection("facture").add(facture);
            }
            yield this.devisService.acceptProDevis(id);
            yield this.router
                .navigateByUrl("/refresh", { skipLocationChange: true })
                .then(() => {
                this.router.navigate([decodeURI(this._location.path())]);
            });
            this.updateBudget(this.singleDevis.priceTTC);
        });
    }
    refusedCommand(id) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.devisService.refuseProDevis(id);
            yield this.router
                .navigateByUrl("/refresh", { skipLocationChange: true })
                .then(() => {
                this.router.navigate([decodeURI(this._location.path())]);
            });
        });
    }
    allUsers() {
        return this.hiero.DB.collection("users")
            .where("profile.isSeineMaritime", "==", true)
            .get()
            .then((snap) => {
            return snap.docs.length;
        });
    }
    usersThisMonth() {
        const date = new Date();
        const start = new Date(date.getFullYear(), date.getMonth(), 1)
            .getTime()
            .toString();
        return this.hiero.DB.collection("users")
            .where("profile.isSeineMaritime", "==", true)
            .where("profile.createdAt", ">=", start)
            .get()
            .then((snap) => {
            return snap.docs.length;
        });
    }
    download(id) {
        return __awaiter(this, void 0, void 0, function* () {
            let currentLang = this.localisation.CurrentLanguageISO639;
            i18nIsoLanguages.registerLocale(require("@cospired/i18n-iso-languages/langs/" + currentLang + ".json"));
            this.singleDevis = yield this.getSingleDevis(id);
            this.client = yield this.getClientData(this.singleDevis.professionnelUid);
            let clientPro = yield this.getAdressPro(this.singleDevis.professionnelId);
            var doc = new jsPDF();
            // ####### Adresse de Hiero
            doc.setFontSize(12);
            doc.setFontType("bold");
            doc.text(this.localisation.localise("support_company"), 10, 10);
            doc.setFontSize(10);
            doc.setFontType("normal");
            doc.text(this.localisation.localise("support_addr1"), 10, 15);
            doc.text(this.localisation.localise("support_addr2"), 10, 20);
            doc.text(this.localisation.localise("support_tel"), 10, 25);
            doc.text(this.localisation.localise("support_email"), 10, 30);
            //##### Logo
            doc.addImage(img, "png", 150, 10, 50, 40);
            // ##### Titre facture
            if (this.client.profile.isSeineMaritime)
                doc.setTextColor("#004158");
            else
                doc.setTextColor("#702963");
            doc.setFontSize(20);
            doc.setFontType("bold");
            doc.text(this.localisation.localise("devis").toUpperCase(), 10, 70);
            doc.setFontType("normal");
            doc.setTextColor(255, 0, 0);
            doc.setFontSize(30);
            // Infos sous le titre de la facture:
            doc.setTextColor("black");
            doc.setFontSize(10);
            doc.setFontType("bold");
            doc.text(this.localisation.localise("devis_numero").toUpperCase(), 20, 80);
            doc.text(this.localisation.localise("devis_expiration").toUpperCase(), 20, 85);
            this.singleDevis.purchaseOrder
                ? doc.text(this.localisation.localise("bon_commande"), 20, 100)
                : null;
            doc.setFontType("normal");
            doc.text(`${this.singleDevis.numero}`, 60, 80);
            doc.text(new Date(this.singleDevis.date.seconds * 1000).toLocaleDateString("fr-FR"), 60, 85);
            this.singleDevis.purchaseOrder
                ? doc.text(this.singleDevis.purchaseOrder, 60, 100)
                : null;
            // ADRESSE DE FACTURATION
            doc.setFontType("bold");
            doc.text(this.localisation.localise("account_adresse").toUpperCase(), 130, 80);
            doc.setFontType("normal");
            doc.text(this.singleDevis.professionnelName, 130, 87);
            doc.text(this.client.profile.givenName +
                " " +
                this.client.profile.familyName.toUpperCase(), 130, 92);
            doc.text(clientPro.address.number + " " + clientPro.address.street, 130, 97);
            doc.text(clientPro.address.code + " " + clientPro.address.city, 130, 102);
            doc.text(clientPro.address.country, 130, 107);
            // Ligne horizontal
            if (this.client.profile.isSeineMaritime)
                doc.setDrawColor("#004158");
            else
                doc.setDrawColor("#702963");
            doc.line(pageWidth - 20, 120, 20, 120);
            // doc.line(190, 151, 30, 151);
            // ####### Le tableau
            // # En tête
            if (this.client.profile.isSeineMaritime)
                doc.setFillColor(0, 155, 181);
            else
                doc.setFillColor(112, 41, 99);
            doc.rect(20, 125, pageWidth - 40, 10, "F");
            doc.setTextColor("#FFFFFF");
            doc.text(this.localisation.localise("facture_qte").toUpperCase(), 21, 131);
            doc.text(this.localisation.localise("commande_produit").toUpperCase(), 33, 131);
            doc.text(this.localisation.localise("commande_desc").toUpperCase(), 73, 131);
            doc.text(this.localisation.localise("tasks_price_tva").toUpperCase(), 127, 131);
            doc.text(this.localisation.localise("facture_price").toUpperCase(), 147, 129);
            doc.text(this.localisation.localise("facture_unitaire").toUpperCase(), 147, 133);
            doc.text(this.localisation.localise("facture_montant").toUpperCase(), 171, 131);
            // # La data
            doc.setTextColor("black");
            doc.text(`${this.singleDevis.pagesNumber ? this.singleDevis.pagesNumber : 1}`, 21, 143); //  QTE
            if (this.singleDevis.typePrestation === PrestationType.INTERPRETATION)
                var strArr = doc.splitTextToSize(this.singleDevis.event, 35);
            else
                var strArr = doc.splitTextToSize(this.singleDevis.documentType, 35);
            doc.text(strArr, 33, 143); //  Product
            let type = "";
            if (this.singleDevis.typePrestation === PrestationType.INTERPRETATION)
                type = this.localisation.localise("interpretation");
            if (this.singleDevis.typePrestation === PrestationType.TRADUCTION_ASSERMENTEE)
                type = this.localisation.localise("traduction_assermentee");
            if (this.singleDevis.typePrestation === PrestationType.TRADUCTION_CLASSIQUE)
                type = this.localisation.localise("traduction_classic");
            var strDes = doc.splitTextToSize(type +
                " " +
                this.localisation.localise("prestation_order_from").toLowerCase() +
                " " +
                i18nIsoLanguages
                    .getName(this.singleDevis.srcLang, currentLang)
                    .toLowerCase() +
                " " +
                this.localisation.localise("order_language_to").toLowerCase() +
                " " +
                i18nIsoLanguages
                    .getName(this.singleDevis.destLang, currentLang)
                    .toLowerCase(), 40);
            doc.text(strDes, 73, 143); // Description (faire la gestion de l'espace direcetement avec substr() et \n)
            doc.text("20 %", 127, 143); // TVA
            if (this.singleDevis.typePrestation === PrestationType.INTERPRETATION)
                doc.text(this.singleDevis.price.priceHT.toFixed(2), 152, 143);
            // Question par rapport au Prix Unitaire
            else
                doc.text((this.singleDevis.priceHT.toFixed(2) /
                    (this.singleDevis.pagesNumber ? this.singleDevis.pagesNumber : 1)).toFixed(2), 152, 143); // Question par rapport au Prix Unitaire
            if (this.singleDevis.typePrestation === PrestationType.INTERPRETATION)
                doc.text(this.singleDevis.price.priceHT.toFixed(2), pageWidth - 30, 143);
            // Montant total
            else
                doc.text(this.singleDevis.priceHT.toFixed(2), pageWidth - 30, 143); // Montant total
            if (this.client.profile.isSeineMaritime)
                doc.setDrawColor("#004158");
            else
                doc.setDrawColor("#702963");
            // doc.line(190, 185, 30, 185);
            doc.line(pageWidth - 20, 155, 20, 155);
            // ##### Table des totaux
            //# Col 1
            doc.text(this.localisation.localise("total_ht").toUpperCase(), 127, 165);
            doc.text(this.localisation.localise("total_tva").toUpperCase(), 127, 175);
            doc.text(this.localisation.localise("total_ttc").toUpperCase(), 127, 185);
            doc.text(this.localisation.localise("facute_solde").toUpperCase(), 127, 195);
            // # Col 2
            if (this.singleDevis.typePrestation === PrestationType.INTERPRETATION) {
                doc.text(this.singleDevis.price.priceHT.toFixed(2), pageWidth - 30, 165);
                doc.text(this.singleDevis.price.priceTVAVal.toFixed(2), pageWidth - 30, 175);
                doc.text(this.singleDevis.price.priceTTC.toFixed(2), pageWidth - 30, 185);
            }
            else {
                doc.text(this.singleDevis.priceHT.toFixed(2), pageWidth - 30, 165);
                doc.text(this.singleDevis.priceTVAVal.toFixed(2), pageWidth - 30, 175);
                doc.text(this.singleDevis.priceTTC.toFixed(2), pageWidth - 30, 185);
            }
            if (this.singleDevis.state === EnumDevisState.RefusedByClient) {
                doc.addImage(tamponRefused, "png", 30, 158, 45, 40);
            }
            else if (this.singleDevis.state === EnumDevisState.AcceptedByClient) {
                doc.addImage(tamponAccepted, "png", 30, 158, 45, 40);
            }
            if (this.client.profile.isSeineMaritime)
                doc.setTextColor("#004158");
            else
                doc.setTextColor("#702963");
            doc.setFontSize(15);
            doc.setFontType("bold");
            if (this.singleDevis.typePrestation === PrestationType.INTERPRETATION)
                doc.text(this.singleDevis.price.priceTTC.toFixed(2) + " " + "EUR", 165, 195);
            else
                doc.text(this.singleDevis.priceTTC.toFixed(2) + " " + "EUR", 165, 195);
            doc.setFontType("normal");
            // Recap TVA
            doc.setFontSize(12);
            doc.setTextColor("black");
            doc.text(this.localisation.localise("recap_tva").toUpperCase(), 20, 208);
            doc.setFontSize(10);
            // doc.text('MONTANT DE TVA', )
            // doc.text('BASE HT', )
            // doc.text()
            doc.setTextColor("black");
            // Lignes inférieures
            // doc.setDrawColor('#702963')
            // doc.line(30, 230, 190, 230)
            // doc.line(20, 201, pageWidth-20, 201)
            if (this.client.profile.isSeineMaritime)
                doc.setFillColor(0, 155, 181);
            else
                doc.setFillColor(112, 41, 99);
            doc.rect(20, 211, pageWidth - 40, 10, "F");
            doc.setTextColor("#FFFFFF");
            doc.text(this.localisation.localise("recap_taux").toUpperCase(), 30, 217);
            doc.text(this.localisation.localise("recap_mt_tva").toUpperCase(), 90, 217);
            doc.text(this.localisation.localise("recap_base_ht").toUpperCase(), 170, 217);
            doc.setTextColor("black");
            doc.text(this.localisation.localise("facture_tva_fr").toUpperCase(), 25, 227);
            if (this.singleDevis.typePrestation === PrestationType.INTERPRETATION) {
                doc.text(this.singleDevis.price.priceTVAVal.toFixed(2), 100, 227);
                doc.text(this.singleDevis.price.priceHT.toFixed(2), 179, 227);
            }
            else {
                doc.text(this.singleDevis.priceTVAVal.toFixed(2), 100, 227);
                doc.text(this.singleDevis.priceHT.toFixed(2), 179, 227);
            }
            // Pénalité et etc...
            doc.setFontType("normal");
            doc.setFontSize(10);
            doc.setTextColor("black");
            doc.text(this.localisation.localise("condition_paragraphe_1"), pageWidth / 2, 245, {
                align: "center",
            });
            doc.text(this.localisation.localise("condition_paragraphe_2"), pageWidth / 2, 250, {
                align: "center",
            });
            doc.text(this.localisation.localise("condition_paragraphe_3"), pageWidth / 2, 255, { align: "center" });
            // Footer
            if (this.client.profile.isSeineMaritime)
                doc.setDrawColor("#004158");
            else
                doc.setDrawColor("#702963");
            doc.line(0, pageHeight - 16, pageWidth, pageHeight - 16);
            doc.setFontSize(8);
            doc.text(this.localisation.localise("capital_sasu") +
                " - " +
                this.localisation.localise("agency_address_number") +
                " " +
                this.localisation.localise("rcs_infos") +
                " - " +
                this.localisation.localise("agency_address_number") +
                " " +
                this.localisation.localise("siret") +
                " - " +
                this.localisation.localise("agency_address_number") +
                " " +
                this.localisation.localise("tva_fr"), pageWidth / 2, pageHeight - 10, { align: "center" });
            doc.save(this.localisation.localise("devis") +
                "_" +
                this.singleDevis.numero +
                ".pdf");
        });
    }
}
