import { Component, OnDestroy, OnInit } from "@angular/core";
import { HieroBDD } from "../../services/hierobdd.service";
import { Subscription } from "rxjs/Subscription";
import { ParticularsServices } from "../../services/particuliers/particulars-services.service";
import { UsersParticularsServices } from "../../services/particuliers/users-particulars-services.services";
import { ChartsServices } from "../../services/charts.services";
import { ChartConfig } from "../../interfaces/chart";
import { ProServices } from "../../services/pro/pro-services.services";
import { UsersProServices } from "../../services/pro/users-pro-services.services";

@Component({
  selector: "app-expat",
  templateUrl: "./expat.component.html",
  styleUrls: ["./expat.component.scss"],
})
export class ExpatComponent implements OnInit, OnDestroy {
  switchParticularPro: boolean = false;
  barChart: any[] = [];
  commandsExpat = [];
  commandsProExpat = [];
  commandsExpatThisMonth;
  commandsProExpatThisMonth;
  usersExpat;
  usersProExpat;
  userThisMonth: number;
  usersProExpatThisMonth: number;
  sales: number;
  salesPro: number;
  salesThisMonth;
  salesProThisMonth;
  sales12Month: number[];
  sales12MonthProExpat: number[];
  p: number = 1;
  p2: number = 1;
  date: Date = new Date();
  subscription!: Subscription;
  subscriptionForMonth!: Subscription;
  subscriptionProForMonth: Subscription;
  subscriptionUsers!: Subscription;
  subscriptionUsersPro!: Subscription;
  subscriptionCommand12Month: Subscription;
  subscriptionCommandPro12Month: Subscription;
  subscriptionProCommands: Subscription;

  constructor(
    private hiero: HieroBDD,
    private particualarService: ParticularsServices,
    private proServices: ProServices,
    private usersService: UsersParticularsServices,
    private usersProService: UsersProServices,
    private chartServices: ChartsServices
  ) {}

  ngOnInit(): void {
    this.subscription = this.particualarService.commandSubject.subscribe({
      next: (commands: any[]) => {
        this.commandsExpat = commands.filter((command) => {
          if (command.prestationInfo.partenaire !== "science-acceuil") {
            return command;
          }
        });
        const commandsValidated = this.commandsExpat.filter((command) => {
          if (command.prestationInfo.state === "validated") {
            return command;
          }
        });
        this.sales = +commandsValidated
          .map((price) => price.prestationInfo.price.ttc)
          .reduce(
            (previousValue, currentValue) => previousValue + currentValue,
            0
          )
          .toFixed(2);
      },
    });

    this.subscriptionForMonth =
      this.particualarService.commandSubjectThisMonth.subscribe({
        next: (commandsMonth: any[]) => {
          this.commandsExpatThisMonth = commandsMonth.filter((command) => {
            if (command.prestationInfo.partenaire !== "science-acceuil") {
              return command;
            }
          });
          const commandsValidated = this.commandsExpat.filter((command) => {
            if (command.prestationInfo.state === "validated") {
              return command;
            }
          });
          this.salesThisMonth = +commandsValidated
            .map((price) => price.prestationInfo.price.ttc)
            .reduce(
              (previousValue, currentValue) => previousValue + currentValue,
              0
            )
            .toFixed(2);
        },
      });

    this.subscriptionProForMonth =
      this.proServices.commandSubjectThisMonth.subscribe({
        next: (value) => {
          this.commandsProExpatThisMonth = value;

          const commandsValidated = this.commandsProExpatThisMonth.filter(
            (command) => {
              if (command.prestationInfo.state === "validated-pro") {
                return command;
              }
            }
          );
          this.salesProThisMonth = +commandsValidated
            .map((price) => price.prestationInfo.price.ttc)
            .reduce(
              (previousValue, currentValue) => previousValue + currentValue,
              0
            )
            .toFixed(2);
        },
      });

    this.subscriptionUsers = this.usersService.usersSubject.subscribe({
      next: (users: any[]) => {
        const expat = users.filter((user) => {
          if (user.user.profile.partenaire !== "science-acceuil") {
            return user;
          }
        });
        let partV = expat.filter(
          (user) => user.otherInfos.emailVerified == true
        );
        console.log(partV.length);

        this.usersExpat = expat.length;
        this.userThisMonth = expat.filter((user) => {
          if (
            new Date(user.otherInfos.tokensValidAfterTime).getMonth() ===
              new Date().getMonth() &&
            new Date(user.otherInfos.tokensValidAfterTime).getFullYear() ===
              new Date().getFullYear()
          ) {
            return user;
          }
        }).length;
      },
    });

    this.subscriptionUsersPro = this.usersProService.usersSubject.subscribe({
      next: (value) => {
        this.usersProExpat = value.length;
        this.usersProExpatThisMonth = value.filter((user) => {
          if (
            new Date(user.otherInfos.tokensValidAfterTime).getMonth() ===
              new Date().getMonth() &&
            new Date(user.otherInfos.tokensValidAfterTime).getFullYear() ===
              new Date().getFullYear()
          ) {
            return user;
          }
        }).length;
      },
    });

    this.subscriptionCommand12Month =
      this.particualarService.commandSubject12Months.subscribe({
        next: (value) => {
          if (value.length !== 0) {
            this.sales12Month = value.map((sale) => {
              return sale
                .map((prix) => prix.prestationInfo.price.ttc)
                .reduce(
                  (previousValue, currentValue) => previousValue + currentValue,
                  0
                )
                .toFixed(2);
            });
            this.graphic();
          }
        },
      });

    this.subscriptionCommandPro12Month =
      this.proServices.commandSubject12Months.subscribe({
        next: (value) => {
          if (value.length !== 0) {
            this.sales12MonthProExpat = value.map((sale) => {
              return sale
                .map((prix) => prix.prestationInfo.price.ttc)
                .reduce(
                  (previousValue, currentValue) => previousValue + currentValue,
                  0
                )
                .toFixed(2);
            });
          }
        },
      });

    this.subscriptionProCommands = this.proServices.commandSubject.subscribe({
      next: (value) => {
        this.commandsProExpat = value;

        const commandsValidated = this.commandsProExpat.filter((command) => {
          if (command.prestationInfo.state === "validated-pro") {
            return command;
          }
        });
        this.salesPro = +commandsValidated
          .map((price) => price.prestationInfo.price.ttc)
          .reduce(
            (previousValue, currentValue) => previousValue + currentValue,
            0
          )
          .toFixed(2);
      },
    });

    this.proServices.getCommandsTraductionExpat();

    this.usersService.getUsersWeb();

    this.usersProService.getUsersWeb();

    this.particualarService.getCommandsThisMonth();

    this.proServices.getCommandsTraductionThisMonthExpat();

    this.particualarService.commandsLast12MonthExpat();

    this.proServices.commandsLast12MonthExpat();

    this.particualarService.getCommands();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptionUsers.unsubscribe();
    this.subscriptionUsersPro.unsubscribe();
    this.subscriptionForMonth.unsubscribe();
    this.subscriptionCommand12Month.unsubscribe();
  }

  switchBetweenParticularPro(): void {
    this.switchParticularPro = !this.switchParticularPro;
  }

  graphic(): void {
    const config: ChartConfig = {
      type: "bar",
      labels: this.chartServices.last12Months(),
      label: "Commandes de particuliers",
      data: this.sales12Month,
      backgroundColor: Array(12).fill("rgba(13, 36, 57, 0.9)"),
      borderColor: Array(12).fill("rgba(13, 36, 57, 0.3)"),
      title: "Chiffre d'affaires sur les 12 derniers mois (en EUR)",
    };
    const configPro: ChartConfig = {
      type: "bar",
      labels: this.chartServices.last12Months(),
      label: "Commandes de professionnel",
      data: this.sales12MonthProExpat,
      backgroundColor: Array(12).fill("rgba(112, 41, 99, 0.9)"),
      borderColor: Array(12).fill("rgba(112, 41, 99, 0.3)"),
      title: "Chiffre d'affaires sur les 12 derniers mois (en EUR)",
    };
    this.barChart = this.chartServices.addBarChart(config, configPro);
  }
}
