/**
 * Permits a type T to be null.
 */
export type Nullable<T> = T | null;

export enum TypeProfessionnal {
  expat = "isExpat",
  seineMaritime = "isSeineMaritime",
  fidinam = "isFidinam",
  ANR = "isANR",
  normal = "isNormal",
}

export enum PrestationType {
  TRADUCTION_CLASSIQUE = "Traduction classique",
  TRADUCTION_ASSERMENTEE = "Traduction assermentée",
  INTERPRETATION = "Interprétation",
}
