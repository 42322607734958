import { Component, OnInit, Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { HieroBDD } from "../services/hierobdd.service";

@Injectable()
export class ExpatGuard implements CanActivate {
  constructor(private hiero: HieroBDD, private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    if (
      this.hiero.Auth.User.Id === "7quZHi9nKcN8l3KO9P0SMlejQbO2" ||
      this.hiero.Auth.User.Id === "YAaYTqtThwUzx3RMj7lpl9OVNb92"
    ) {
      this.router.navigate(["app/expat"]);
      return false;
    } else {
      return true;
    }
  }

  ngOnInit(): void {}
}
