var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { CreatePrestationSchema, EnumPrestationState, } from '../interfaces/IPrestation';
import { EnumTraducteurServiceExceptionType } from '../interfaces/ITraducteurService';
import { Price } from '../utility/Price';
import * as UUID from 'uuid';
import { EnumFileTypes } from '../interfaces/IFile';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
const FIREBASE_PRESTATION_COLLECTION = 'prestations';
const FIREBASE_TRADUCTEUR_SERVICE_COLLECTION = 'traducteur_services';
const FIREBASE_TEMPLATE_COLLECTION = 'templates';
const FIREBASE_TRADUCTEUR_COLLECTION = 'traducteurs';
/**
 * Represents a client order.
 * Consult IPrestation.ts for the structure of the data.
 * User the Data getter to get the data regarding the order, but do not modify this reference.
 * All modifications must be made through the proper methods of this class in order assure correct state evolution.
 */
export class Prestation {
    /**
     * Do not invoke. Rather user TraducteurPrestations or ClientPrestations in order to get a list of Prestations.
     * @param user
     * @param isTraducteur
     * @param docRef
     * @param raw
     */
    constructor(user, isTraducteur, docRef, id, raw, watch) {
        this._user = user;
        this._docRef = docRef;
        this._id = id;
        this._raw = raw;
        this._isTraducteur = isTraducteur;
        this._prestationSubject = new BehaviorSubject(this);
        if (watch) {
            this._stopListening = docRef.onSnapshot((snapshot) => {
                if (snapshot.exists) {
                    this._raw = snapshot.data();
                    this._prestationSubject.next(this);
                }
            }, (err) => {
            });
        }
    }
    cleanup() {
        if (this._stopListening) {
            this._stopListening();
            this._stopListening = null;
        }
    }
    get Id() {
        return this._id;
    }
    /**
     * Get the data for this prestation. If you modify this reference, it will have no impact on the server. Rather call the appropriate function on this class.
     */
    get Data() {
        return this._raw;
    }
    Watch(observer) {
        return this._prestationSubject.subscribe(observer);
    }
    /**
     * (TRANSLATOR) Accept a prestation : call by a translator when he/she wants to accept an order. The client will be notified and asked to pay for the order.
     */
    AcceptPrestation() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this._isTraducteur) {
                throw Error('Action denied');
            }
            if (this.Data.state !== EnumPrestationState.WaitingForTranslator) {
                throw Error('Not in correct state for validation: ' + this.Data.state);
            }
            yield this.doUpdate({
                state: EnumPrestationState.WaitingForPayment,
                acceptedByTranslatorAt: Date.now()
            });
        });
    }
    /**
     * (TRANSLATOR) Refuse a prestation: call by a translator when they refuse an order. The client will be notified and asked to choose another translator.
     */
    RefusePrestation() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this._isTraducteur) {
                throw Error('Action denied');
            }
            if (this.Data.state !== EnumPrestationState.WaitingForTranslator) {
                throw Error('Not in correct state for validation: ' + this.Data.state);
            }
            yield this.doUpdate({
                state: EnumPrestationState.RefusedByTranslator,
                refusedByTranslatorAt: Date.now()
            });
        });
    }
    /**
     * (CLIENT) Cancel a prestation: call by a client if they decide to cancel an order before making a payment
     */
    CancelPrestation() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this._isTraducteur) {
                throw Error('Action denied');
            }
            if (this.Data.state !== EnumPrestationState.WaitingForTranslator) {
                throw Error('Not in correct state for cancellation: ' + this.Data.state);
            }
            yield this.doUpdate({
                state: EnumPrestationState.CancelledByClient,
                cancelledByClientAt: Date.now()
            });
        });
    }
    /**
     * (CLIENT) Validate a prestation
     */
    ClientValidatePrestation() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this._isTraducteur) {
                throw Error('Action denied');
            }
            if (this.Data.state !== EnumPrestationState.WaitingForValidationFromClient) {
                throw Error('Not in correct state for validation: ' + this.Data.state);
            }
            yield this.doUpdate({
                state: EnumPrestationState.Validated,
                validatedByClientAt: Date.now()
            });
        });
    }
    UpdateDocumentTranslation(document, translation) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this._isTraducteur) {
                throw Error('Action denied');
            }
            if (this.Data.state !== EnumPrestationState.Translating && this.Data.state !== EnumPrestationState.WaitingForValidationFromClient) {
                throw Error('Not in correct state for update: ' + this.Data.state);
            }
            const updater = this.Data.documents.find(doc => doc.deviceStorageId === document.deviceStorageId);
            if (updater) {
                updater.translation = translation;
                yield this._docRef.update({
                    documents: this.Data.documents
                });
            }
        });
    }
    UploadTranslatedFile(storage, document, filename, file) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this._isTraducteur) {
                throw Error('Action denied');
            }
            if (this.Data.state !== EnumPrestationState.Translating && this.Data.state !== EnumPrestationState.WaitingForValidationFromClient) {
                throw Error('Not in correct state for updating: ' + this.Data.state);
            }
            const uid = UUID.v4();
            const refId = this.Data.uid + '/' + this.Data.deviceStorageId + '/' + document.deviceStorageId + '/' + uid;
            const storageRef = storage.ref(refId);
            yield storageRef.put(file)
                .then((fileSnapshot) => {
                return this._user.DB.runTransaction((t) => {
                    return t.get(this._docRef)
                        .then((snapshot) => {
                        const locked = snapshot.data();
                        const docToUpdate = locked.documents.find(doc => doc.deviceStorageId === document.deviceStorageId);
                        if (docToUpdate) {
                            const fileDef = {
                                type: EnumFileTypes.PDF,
                                ext: '.pdf',
                                name: filename,
                                deviceStorageId: uid
                            };
                            docToUpdate.translated = [fileDef];
                            console.log(docToUpdate);
                            // Update the document list
                            return t.update(this._docRef, {
                                documents: locked.documents,
                                lastModifiedAt: Date.now()
                            });
                        }
                    });
                });
            });
        });
    }
    DeleteTranslatedFile(storage, document, file) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this._isTraducteur) {
                throw Error('Action denied');
            }
            if (this.Data.state !== EnumPrestationState.Translating) {
                throw Error('Not in correct state for validation: ' + this.Data.state);
            }
            const fileRefId = this.Data.uid + '/' + this.Data.deviceStorageId + '/' + document.deviceStorageId + '/' + file.deviceStorageId;
            return this._user.DB.runTransaction((t) => {
                return t.get(this._docRef)
                    .then((snapshot) => {
                    const locked = snapshot.data();
                    const docToUpdate = locked.documents.find(doc => doc.deviceStorageId === document.deviceStorageId);
                    if (docToUpdate) {
                        // Recreate the list without the passed file
                        docToUpdate.translated = docToUpdate.translated.filter((f) => {
                            return (f.deviceStorageId !== file.deviceStorageId);
                        });
                        // Update the document list
                        return t.update(this._docRef, {
                            documents: locked.documents,
                            lastModifiedAt: Date.now()
                        });
                    }
                    else {
                        return Promise.resolve(null);
                    }
                });
            })
                .then(() => {
                const fileRef = storage.ref(fileRefId);
                return fileRef.delete()
                    .catch((err) => {
                    console.warn('Error removing file: ' + fileRefId);
                });
            });
        });
    }
    Validate() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this._isTraducteur) {
                throw Error('Action denied');
            }
            if (this.Data.state !== EnumPrestationState.Translating) {
                throw Error('Not in correct state for validation: ' + this.Data.state);
            }
            // Validate that we have an uploaded file for each document
            let valid = true;
            this.Data.documents.forEach((doc) => {
                if (!doc.translated || doc.translated.length === 0) {
                    valid = false;
                }
            });
            if (!valid) {
                throw Error('You have not translated all the documents! Cannot validate.');
            }
            this.doUpdate({
                state: EnumPrestationState.WaitingForValidationFromClient,
                completedAt: Date.now()
            });
        });
    }
    doUpdate(data) {
        return __awaiter(this, void 0, void 0, function* () {
            data.lastModifiedAt = Date.now();
            yield this._user.DB.runTransaction((t) => {
                return t.get(this._docRef)
                    .then((snapshot) => {
                    // Update the document list
                    return t.update(this._docRef, data);
                });
            });
        });
    }
    /**
     * Create a new prestation. In general this is called by the Hiero app and you will not need to call this function on the web.
     * @param user
     * @param prestation
     */
    static Create(user, prestation) {
        return __awaiter(this, void 0, void 0, function* () {
            //console.log('je suis dans ma methode create pour les presstation,', user)
            // Set the user id for this prestation
            prestation.uid = user.Id;
            // Record the date/time of this event
            const date = Date.now();
            prestation.createdAt = date;
            prestation.lastModifiedAt = date;
            prestation.sentToTranslatorAt = date;
            // Set the state
            prestation.state = EnumPrestationState.WaitingForTranslator;
            const validated = yield CreatePrestationSchema.validate(prestation, {
                strict: true,
                stripUnknown: true,
                recursive: true
            });
            const docRef = yield user.DB.collection(FIREBASE_PRESTATION_COLLECTION).add(validated);
            const snapshot = yield docRef.get();
            const raw = snapshot.data();
            return new Prestation(user, false, docRef, snapshot.id, raw);
        });
    }
    static Load(user, isTraducteur, prestationId, watch) {
        return __awaiter(this, void 0, void 0, function* () {
            const doc = user.DB.collection(FIREBASE_PRESTATION_COLLECTION).doc(prestationId);
            const snapshot = yield doc.get();
            if (!snapshot.exists) {
                return Promise.reject('No prestation found with that id');
            }
            else {
                return new Prestation(user, isTraducteur, doc, prestationId, snapshot.data(), watch);
            }
        });
    }
    /**
     * Find the euclidean distance between two coordinates, modified to work in geodesic space
     * @param coord0
     * @param coord1
     */
    static GeoDistance(coord0, coord1) {
        const degLen = 110.25;
        const x = coord0.latitude - coord1.latitude;
        const y = (coord0.longitude - coord1.longitude) * Math.cos(coord1.latitude);
        return degLen * Math.sqrt(x * x + y * y);
    }
    /**
     * Helper function for the mobile app, that searched for translators that match the order.
     * @param user The client requesting the list
     * @param prestation The details of the order
     * @param coords The coordinates of the user.
     * @param settings The global settings for Hiero.
     */
    static FindTraducteurs(user, prestation, coords, settings) {
        return __awaiter(this, void 0, void 0, function* () {
            // Make a map of document types in this prestation
            const docTypeSet = new Set();
            prestation.documents.forEach((doc) => {
                if (!docTypeSet.has(doc.documentTypeId)) {
                    docTypeSet.add(doc.documentTypeId);
                }
            });
            // Get templates that match this prestation
            // src, dest, country (not doctype)
            const templateQuery = user.DB.collection(FIREBASE_TEMPLATE_COLLECTION)
                .where('srcLanguageIso639', '==', prestation.srcLanguageIso639)
                .where('destLanguageIso639', '==', prestation.destLanguageIso639)
                .where('srcCountryCode', '==', prestation.srcCountryCode);
            // Construct a map of templates that would fit this query
            const templateMap = new Map();
            const templateDocMap = new Map();
            const templateSnapshot = yield templateQuery.get();
            templateSnapshot.docs.forEach((tempSnap) => {
                const template = tempSnap.data();
                if (docTypeSet.has(template.documentTypeId)) {
                    // Key by template Id
                    templateMap.set(tempSnap.id, template);
                    // Key by documentType
                    templateDocMap.set(template.documentTypeId, tempSnap.id);
                }
            });
            // If the number of templates is not the same as the number of unique document
            // types, quit early... cannot fill the order
            if (templateDocMap.size !== docTypeSet.size) {
                console.log('Not enough templates to match all document types for this order');
                return [];
            }
            // Find all services that match this query
            const query = user.DB.collection(FIREBASE_TRADUCTEUR_SERVICE_COLLECTION)
                .where('srcLanguageIso639', '==', prestation.srcLanguageIso639)
                .where('destLanguageIso639', '==', prestation.destLanguageIso639);
            const snapshot = yield query.get();
            // Filter by exceptions
            const translatorMap = new Map();
            snapshot.docs.forEach((docSnapshot) => {
                const service = docSnapshot.data();
                console.log('SERVICE MATCH: ' + docSnapshot.id);
                // Go through all service types, and make sure we have all document types
                /*let matches = 0;
                service.types.forEach(
                  (servDocType: ITraducteurServiceDocumentType) => {
                    if (docTypeSet.has(servDocType.documentTypeId)) {
                      matches += 1;
                    }
                  }
                );*/
                // Go through exceptions to make sure that this service matches
                let hasException = false;
                if (service.exceptions) {
                    const exception = service.exceptions.find((exc) => {
                        return templateMap.has(exc.templateId) && exc.type === EnumTraducteurServiceExceptionType.DO_NOT_HANDLE;
                    });
                    hasException = !!exception;
                }
                if (!hasException) {
                    console.log('DOCUMENTS MATCH PERFECTLY');
                    // Perfect match
                    let sortResult = {
                        distance: -1,
                        translatorId: service.traducteurId,
                        services: [],
                        translator: null,
                    };
                    if (translatorMap.has(service.traducteurId)) {
                        // Already have, get
                        sortResult = translatorMap.get(service.traducteurId);
                    }
                    else {
                        // Add
                        translatorMap.set(service.traducteurId, sortResult);
                    }
                    sortResult.services.push(service);
                }
            });
            // Get unique translators
            const promises = [];
            const finalResults = [];
            translatorMap.forEach((service, translatorId) => {
                const doc = user.DB.collection(FIREBASE_TRADUCTEUR_COLLECTION).doc(translatorId);
                promises.push(doc.get()
                    .then((translatorSnapshot) => {
                    if (translatorSnapshot.exists) {
                        service.translator = translatorSnapshot.data();
                        service.distance = this.GeoDistance(service.translator.coords, coords);
                        console.log('Found translator at: ' + service.distance);
                        finalResults.push(service);
                    }
                })
                    .catch(err => {
                    console.warn(err.message);
                }));
            });
            // Wait for all translators to result
            yield Promise.all(promises);
            // Sort by distance
            const sortedResults = finalResults.sort((a, b) => {
                return (a.distance - b.distance);
            });
            const repackagedResults = [];
            sortedResults.forEach((sortResult) => {
                const overallPrice = new Price(0, settings.Current.tva, settings.Current.margin);
                const res = {
                    distance: sortResult.distance,
                    traducteurId: sortResult.translatorId,
                    traducteur: sortResult.translator,
                    documents: [],
                    price: overallPrice.breakdown
                };
                // Go over each document in the prestation and find its price
                prestation.documents.forEach((doc) => {
                    // Find template for doc
                    const templateId = templateDocMap.get(doc.documentTypeId);
                    const template = templateMap.get(templateId);
                    let finalPrice = template.priceHT;
                    // Choose first service, because if arrive here, should all be same
                    const service = sortResult.services[0];
                    if (service.exceptions) {
                        const exception = service.exceptions.find((exc) => {
                            // This template has the same docId as the one under consideration
                            return (exc.templateId === templateId && exc.type === EnumTraducteurServiceExceptionType.DIFFERENT_PRICE);
                        });
                        if (exception) {
                            finalPrice = exception.priceHT;
                        }
                    }
                    const price = new Price(finalPrice, settings.Current.tva, settings.Current.margin);
                    // Item price
                    res.documents.push({
                        price: price.breakdown,
                        documentTypeId: doc.documentTypeId
                    });
                    // Total price
                    overallPrice.add(finalPrice);
                });
                res.price = overallPrice.breakdown;
                console.log(res);
                repackagedResults.push(res);
            });
            return repackagedResults;
        });
    }
}
