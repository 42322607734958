var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AfterViewInit, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { DocumentType } from '../../../../../../common/src/bdd/documents/DocumentType';
import { Template } from '../../../../../../common/src/bdd/documents/Template';
import { Countries } from '../../../../../../common/src/bdd/utility/countries';
import { Language } from '../../../../../../common/src/bdd/utility/Language';
import { PDFParser } from '../../../../../../common/src/components/pdfpreview/pdfparser';
import { PDFRender } from '../../../../../../common/src/components/pdfpreview/pdfrender';
import { LocalisationService } from '../../../../../../common/src/modules/localisation/localisation.service';
import { SubmitForm } from '../../../../../../common/src/utility/forms/submitform.class';
import { HieroBDD } from '../../../../services/hierobdd.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppModalConfirmComponent } from '../../../../../../common/src/utility/modal/confirm-modal.component';
import { haveValidLanguageValidator, haveValidCountryValidator } from '../addTemplate/addtemplate.component';
export class TemplateComponent {
    constructor(hiero, fb, router, route, localisation, modalService) {
        this.hiero = hiero;
        this.fb = fb;
        this.router = router;
        this.route = route;
        this.localisation = localisation;
        this.modalService = modalService;
        this.busy = true;
        this.deleteError = null;
        this.formatter = (lang) => lang ? lang : '';
        this.search = (text$) => text$.pipe(debounceTime(200), distinctUntilChanged(), map(term => term.length < 2
            ? []
            : this.fullLanguageList.filter((value) => (value.toLowerCase().indexOf(term.toLowerCase()) > -1 ? value : null), 10)));
        this.countryFormatter = (country) => country ? country.label : '';
        this.countrySearch = (text$) => text$.pipe(debounceTime(200), distinctUntilChanged(), map(term => term.length < 2
            ? []
            : Countries.GetCountryArray('fr').filter((value) => (value.label.toLowerCase().indexOf(term.toLowerCase()) > -1 ? value : null), 10)));
        this.fullLanguageList = Language.GetAllNativeAndRegularNames();
        this.createForm(null);
    }
    ngAfterViewInit() {
        this.templateId = this.route.snapshot.paramMap.get('templateId');
        // console.log(this.templateId);
        this.adminSub = this.hiero.WatchAdmin({
            next: (admin) => {
                this.admin = admin;
                DocumentType.Init(admin.User, true)
                    .then((map) => {
                    this.docTypeMap = map;
                    return Template.Load(admin.User, this.templateId, true);
                })
                    .then((template) => {
                    this.template = template;
                    this.templateSub = this.template.Watch({
                        next: (updated) => {
                            this.template = updated;
                            this.createForm(this.template.Data);
                            this.busy = false;
                        }
                    });
                });
            }
        });
    }
    ngOnDestroy() {
        if (this.adminSub) {
            this.adminSub.unsubscribe();
        }
        if (this.templateSub) {
            this.templateSub.unsubscribe();
        }
    }
    getDocTypeFor(code, documentTypeId) {
        return this.docTypeMap.GetDocumentName(code, documentTypeId);
    }
    getCountryName(code) {
        return Countries.GetCountryMap('fr').get(code);
    }
    createForm(data) {
        if (!data) {
            data = {
                srcLanguageIso639: '',
                destLanguageIso639: '',
                srcCountryCode: '',
                priceHT: 0,
                documentTypeId: '',
                config: null
            };
        }
        const country = {
            value: data.srcCountryCode,
            label: Countries.GetCountryMap('fr').get(data.srcCountryCode)
        };
        this.updateForm = new SubmitForm(this.fb, [
            {
                name: 'srcCode',
                value: Language.GetNameFromISO639(data.srcLanguageIso639),
                validators: [haveValidLanguageValidator('srcCode')],
                type: 'text',
                title: 'Langue d\'origine',
                autocomplete: 'off',
                placeholder: 'Commencez à taper le nom de la langue',
                help: '',
                errors: [
                    {
                        code: 'required',
                        message: 'Champs obligatoire',
                    },
                    {
                        code: 'validLanguage',
                        message: 'Merci de sélectionner la langue de la liste défilante !',
                    },
                ]
            },
            {
                name: 'srcCountry',
                value: country,
                validators: [haveValidCountryValidator('srcCountry')],
                type: 'text',
                title: 'Pays d\'origine',
                autocomplete: 'off',
                placeholder: 'Taper le nom du pays...',
                help: '',
                errors: [
                    {
                        code: 'required',
                        message: 'Champs obligatoire',
                    },
                    {
                        code: 'validCountry',
                        message: 'Merci de sélectionner le pays de la liste défilante !',
                    },
                ]
            },
            {
                name: 'dstCode',
                value: Language.GetNameFromISO639(data.destLanguageIso639),
                validators: [haveValidLanguageValidator('dstCode')],
                type: 'text',
                title: 'Traduire vers...',
                autocomplete: 'off',
                placeholder: 'Commencez à taper le nom de la langue',
                help: '',
                errors: [
                    {
                        code: 'required',
                        message: 'Champs obligatoire',
                    },
                    {
                        code: 'validLanguage',
                        message: 'Merci de sélectionner la langue de la liste défilante !',
                    },
                ]
            },
            {
                name: 'type',
                value: '',
                validators: [],
                type: 'text',
                title: 'Type de document',
                autocomplete: 'off',
                placeholder: '',
                help: 'Le type de document representé par ce document',
                errors: [
                    {
                        code: 'required',
                        message: 'Champs obligatoire',
                    },
                ]
            },
            {
                name: 'price',
                value: data.priceHT,
                validators: [Validators.required, Validators.min(0.1)],
                type: 'number',
                title: 'Prix HT',
                autocomplete: 'off',
                placeholder: '',
                min: 0.1,
                help: 'Le prix HT du document percu par le traducteur pour une prestation de ce type',
                errors: [
                    {
                        code: 'required',
                        message: 'Champs obligatoire',
                    },
                    {
                        code: 'min',
                        message: 'Minimum de 0.1€ obligatoire',
                    },
                ]
            }
        ], 
        // Submit callback
        (changes) => {
        }, 
        // Success callback
        () => {
            // What to do with login success ?
            this.busy = false;
        }, 
        // Fail callback
        err => {
            // What to do with login failuer
            console.error(err);
            this.busy = false;
        }, 
        // Changes callback
        null);
        this.setupDropdown(data.documentTypeId);
        // console.log(data);
        if (data && data.config) {
            this.pdfRender = new PDFRender(data.config);
        }
    }
    setupDropdown(documentTypeId) {
        const docTypes = [];
        let selectedIndex = 0;
        if (this.docTypeMap) {
            this.docTypeMap.ToArray.forEach((docType) => {
                const docName = docType.label.find(label => label.code === 'fr');
                if (docName) {
                    docTypes.push({
                        name: docName.value,
                        data: docType._id
                    });
                }
                else {
                    docTypes.push({
                        name: 'Pas de nom: ' + docType._id,
                        data: docType._id
                    });
                }
                if (docType._id === documentTypeId) {
                    this.selectedDocType = docType._id;
                    selectedIndex = docTypes.length - 1;
                }
            });
        }
        this.documentTypeDropdown = {
            choices: docTypes,
            selectedIndex: selectedIndex,
            title: '',
            input_title: 'Type de document',
            hasButton: false,
            button_title: '',
            help: ''
        };
    }
    selectDocumentType(data) {
        this.selectedDocType = data.data;
    }
    update() {
        return __awaiter(this, void 0, void 0, function* () {
            const src = this.updateForm.GetValue('srcCode');
            const dst = this.updateForm.GetValue('dstCode');
            const srcCountryData = this.updateForm.GetValue('srcCountry');
            const price = this.updateForm.GetValue('price');
            const srcCode = Language.GetISO639(src);
            const dstCode = Language.GetISO639(dst);
            const srcCountry = srcCountryData.value;
            /// VALIDIITY
            if (!this.updateForm.CheckValidity()) {
                return;
            }
            if (!srcCode) {
                this.updateForm.SetGeneralError("Error: missing info: srcCode");
            }
            if (!dstCode) {
                this.updateForm.SetGeneralError("Error: missing info: dstCode");
            }
            if (!srcCountry) {
                this.updateForm.SetGeneralError("Error: missing info: srcCountry");
            }
            if (!this.selectedDocType) {
                this.updateForm.SetGeneralError("Error: missing info: this.selectedDocType");
            }
            if (!price) {
                this.updateForm.SetGeneralError("Error: missing info: price");
            }
            if (!this.pdfRender) {
                this.updateForm.SetGeneralError("Error: missing info: this.pdfRender");
            }
            if (!srcCode || !dstCode || !srcCountry || !this.selectedDocType || !price || !this.pdfRender) {
                console.log(srcCode);
                console.log(dstCode);
                console.log(srcCountry);
                console.log(this.selectedDocType);
                console.log(price);
                return;
            }
            this.busy = true;
            this.pdfRender.clearHighlights();
            const priceNum = parseFloat(price);
            const t = {
                srcLanguageIso639: srcCode,
                srcCountryCode: srcCountry,
                destLanguageIso639: dstCode,
                documentTypeId: this.selectedDocType,
                priceHT: priceNum,
                config: this.pdfRender.Data
            };
            try {
                yield this.template.Update(t);
            }
            catch (err) {
                this.updateForm.SetGeneralError(err.message);
            }
            finally {
                this.busy = false;
            }
        });
    }
    remove() {
        return __awaiter(this, void 0, void 0, function* () {
            const modalRef = this.modalService.open(AppModalConfirmComponent, {
                centered: true,
            });
            modalRef.componentInstance.header = 'Confirmation';
            modalRef.componentInstance.message = 'Vous êtes sur de vouloir supprimer ce template ?';
            modalRef.componentInstance.ok = 'Oui';
            modalRef.componentInstance.cancel = 'Non';
            let result = false;
            try {
                result = yield modalRef.result;
            }
            catch (_a) { }
            if (result) {
                this.busy = true;
                this.deleteError = null;
                try {
                    yield this.template.Remove();
                    this.router.navigate(['/app', 'templates']);
                }
                catch (err) {
                    this.deleteError = err.message;
                }
                finally {
                    this.busy = false;
                }
            }
        });
    }
    dropped(files) {
        return __awaiter(this, void 0, void 0, function* () {
            if (files.length === 0) {
                return;
            }
            const droppedFile = files[0];
            // Is it a file?
            if (droppedFile.fileEntry.isFile) {
                const fileEntry = droppedFile.fileEntry;
                this.busy = true;
                try {
                    const pdfRender = yield PDFParser.Parse(fileEntry);
                    this.pdfRender = pdfRender;
                    this.busy = false;
                }
                catch (err) {
                    console.warn(err.message);
                    this.busy = false;
                }
            }
        });
    }
    fileOver(event) {
        console.log(event);
    }
    fileLeave(event) {
        console.log(event);
    }
    /// Config
    downloadJson() {
        const dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(this.pdfRender.Data));
        const downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute('href', dataStr);
        downloadAnchorNode.setAttribute('download', 'hiero_pdf_config.json');
        document.body.appendChild(downloadAnchorNode); // required for firefox
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
    }
    droppedJson(files) {
        return __awaiter(this, void 0, void 0, function* () {
            if (files.length === 0) {
                return;
            }
            const droppedFile = files[0];
            // Is it a file?
            if (droppedFile.fileEntry.isFile) {
                const fileEntry = droppedFile.fileEntry;
                this.busy = true;
                try {
                    fileEntry.file((fileData) => {
                        const fileReader = new FileReader();
                        fileReader.onload = () => {
                            // Step 4:turn array buffer into typed array
                            const json = fileReader.result;
                            try {
                                const ipdf = JSON.parse(json);
                                this.pdfRender = new PDFRender(ipdf);
                            }
                            catch (err) {
                                console.error(err);
                            }
                        };
                        fileReader.onerror = () => {
                            fileReader.abort();
                            console.error("Error reading file");
                        };
                        // Step 3: Read the file as ArrayBuffer
                        fileReader.readAsText(fileData, 'utf-8');
                    });
                    this.busy = false;
                }
                catch (err) {
                    console.warn(err.message);
                    this.busy = false;
                }
            }
        });
    }
}
