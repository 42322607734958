<div class="container mt-3 mb-4 d-flex justify-content-center">
  <div class="col-lg-6 mt-4 mt-lg-0">
    <div class="row">
      <div class="col-md-12">
        <div
          class="user-dashboard-info-box table-responsive mb-0 bg-light p-4 shadow-sm"
        >
          <table class="table manage-candidates-top mb-0">
            <thead>
              <tr>
                <td class="lead text-center">
                  <img
                    class="img-fluid"
                    src="../../../../../assets/img/logo/seine_maritime_logo.png"
                    alt=""
                    width="100"
                  />
                </td>
              </tr>
            </thead>
            <tbody>
              <tr
                class="candidates-list"
                *ngFor="
                  let user of utilisateurs
                    | paginate: { itemsPerPage: 3, currentPage: p }
                "
              >
                <td class="title">
                  <div class="thumb">
                    <img
                      class="img-fluid"
                      src="
                            ../../../../../assets/img/user/avatarUser.png
                        "
                      alt=""
                    />
                  </div>
                  <div class="candidate-list-details">
                    <div class="candidate-list-info">
                      <div class="candidate-list-title">
                        <h5 class="mb-0 d-flex">
                          <span>
                            {{
                              user.data().profile.givenName +
                                " " +
                                user.data().profile.familyName
                            }}
                          </span>
                        </h5>
                      </div>
                      <div class="candidate-list-option">
                        <ul class="list-unstyled">
                          <li>
                            <i
                              class="fa fa-envelope mr-3 text-seine-maritime"
                            ></i>
                            {{ user.data().profile.email }}
                          </li>
                          <li>
                            <i class="fas fa-map-marker-alt pr-1"></i>
                            <span>
                              <i
                                class="fa fa-phone mr-2 text-seine-maritime"
                              ></i>
                              {{ user.data().profile.telephone }}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </td>
                <td class="candidate-list-favourite-time text-center"></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="d-flex justify-content-center mt-4">
          <pagination-controls
            (pageChange)="p = $event"
            class="my-pagination"
          ></pagination-controls>
        </div>
        <div class="d-flex justify-content-center mt-4"></div>
      </div>
    </div>
  </div>
</div>
