var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnDestroy, OnInit } from "@angular/core";
import { InterpretationServices } from "../../services/particuliers&pro/interpretation.services";
import { ActivatedRoute, Router } from "@angular/router";
import { filter } from "rxjs/operators";
import swal from "sweetalert";
import { AppModalConfirmComponent } from "../../../../common/src/utility/modal/confirm-modal.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
export class SingleInterpretationsComponent {
    constructor(interpretations, route, router, modalService) {
        this.interpretations = interpretations;
        this.route = route;
        this.router = router;
        this.modalService = modalService;
        this.busy = true;
    }
    ngOnInit() {
        this.id = this.route.snapshot.paramMap.get("id");
        this.subscription = this.interpretations.singleCommandSubject
            .pipe(filter((val) => JSON.stringify(val) !== "{}"))
            .subscribe({
            next: (value) => {
                this.singleInterpretation = value;
            },
        });
        this.interpretations.getSingleInterpretation(this.id, "pro");
    }
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
    profile(uid) {
        this.router.navigate(["app/users", uid]);
    }
    resetPage() {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = "reload";
        this.router.navigate(["/app", "interpretations", this.id], {
            relativeTo: this.route,
            queryParamsHandling: "merge",
        });
    }
    deleteCommand() {
        swal({
            text: " En cours de developpement. ",
        });
    }
    changeStatus(status, type) {
        return __awaiter(this, void 0, void 0, function* () {
            const statusView = status === "refused-by-translator" ? "refuser" : "valider";
            const statusBtn = status === "refused-by-translator" ? "Refuser" : "Valider";
            const modalRef = this.modalService.open(AppModalConfirmComponent, {
                centered: true,
            });
            modalRef.componentInstance.header = "Confirmer le changement de statut";
            modalRef.componentInstance.message = `Voulez-vous vraiment ${statusView} cette commande ? Ce changement de statut est irréversible.`;
            modalRef.componentInstance.ok = statusBtn;
            modalRef.componentInstance.cancel = "Annuler";
            let result = false;
            try {
                result = yield modalRef.result;
            }
            catch (_a) { }
            if (result) {
                this.busy = true;
                try {
                    this.interpretations
                        .updateStatus(this.id, status, type)
                        .then((value) => {
                        console.log(value);
                        this.resetPage();
                    });
                }
                catch (err) {
                    console.error(err.message);
                }
                finally {
                    this.busy = false;
                }
            }
        });
    }
    redirectToOtherTrad() {
        swal({
            text: " Cette fonctionnalité arrive très bientôt. ",
        });
    }
}
