import { Component, OnDestroy, OnInit } from "@angular/core";
import { InterpretationServices } from "../../services/particuliers&pro/interpretation.services";
import { Subscription } from "rxjs/Subscription";
import { Router } from "@angular/router";

@Component({
  selector: "app-interpretations",
  templateUrl: "./interpretations.component.html",
  styleUrls: ["./interpretations.component.scss"],
})
export class InterpretationsComponent implements OnInit, OnDestroy {
  p: number = 1;
  subscription!: Subscription;
  allCommands!: any[];

  constructor(
    private interpretationService: InterpretationServices,
    private router: Router
  ) {}

  ngOnInit() {
    this.subscription = this.interpretationService.commandSubject.subscribe({
      next: (value) => (this.allCommands = value),
    });
    this.interpretationService.getInterpretationsCommands();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
