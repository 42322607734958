<div class="container-fluid">
  <div class="row">
    <!-- BEGIN col-3 -->
    <div class="col-xl-4 col-md-6">
      <div class="card widget widget-stats bg-white">
        <div class="stats-icon stats-icon-lg">
          <i class="fa fa-users fa-fw"></i>
        </div>
        <div class="stats-content">
          <div class="stats-title text-dark">
            <i class="text-fidinam h4 fa fa-users fa-fw"></i>
            <span class="h6 ml-3">Nombre total d'utilisateurs</span>
          </div>

          <div class="stats-number text-dark d-flex justify-content-around">
            <span class="font-weight-bold">{{ usersFidinam }}</span>
            <span class="stats-desc text-dark font-italic h6 mt-3"
              >au {{ date | date: "dd/MM/yyyy" }}</span
            >
          </div>

          <div class="stats-desc text-dark">Utilisateurs inscrits ce mois</div>

          <div class="stats-number text-dark d-flex justify-content-end">
            <span class="font-weight-bold text-info font-italic">-</span>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xl-4 col-md-6">
      <div class="card widget widget-stats bg-white">
        <div class="stats-icon stats-icon-lg">
          <i class="fa fa-shopping-cart fa-fw"></i>
        </div>
        <div class="stats-content">
          <div class="stats-title text-dark">
            <i class="text-fidinam h4 fa fa-shopping-cart fa-fw"></i>
            <span class="h6 ml-3">Nombre total de commandes</span>
          </div>

          <div class="stats-number text-dark d-flex justify-content-around">
            <span class="font-weight-bold">{{ commandsNbr }}</span>
            <span class="stats-desc text-dark font-italic h6 mt-3"
              >au {{ date | date: "dd/MM/yyyy" }}</span
            >
          </div>

          <div class="stats-desc text-dark">Commandes passées ce mois</div>

          <div class="stats-number text-dark d-flex justify-content-end">
            <span class="font-weight-bold text-fidinam font-italic">{{
              commandThisMonth
            }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xl-4 col-md-6">
      <div class="card widget widget-stats bg-white">
        <div class="stats-icon stats-icon-lg">
          <i class="fa fa-money fa-fw"></i>
        </div>
        <div class="stats-content">
          <div class="stats-title text-dark">
            <i class="text-fidinam h4 fa fa-money fa-fw"></i>
            <span class="h6 ml-3">Budget TTC</span>
          </div>

          <div class="stats-number text-dark d-flex justify-content-around">
            <span class="font-weight-bold">{{ sales }} &euro;</span>
            <span class="stats-desc text-dark font-italic h6 mt-3"
              >au {{ date | date: "dd/MM/yyyy" }}</span
            >
          </div>

          <div class="stats-desc text-dark">Budget ce mois</div>

          <div class="stats-number text-dark d-flex justify-content-end">
            <span class="font-weight-bold text-fidinam font-italic"
              >{{ salesMonth }} &euro;</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card mt-4" *ngIf="deviOrCommand === false">
    <div class="d-flex justify-content-between">
      <div class="h5 mt-3 font-weight-bold text-fidinam ml-4">
        Historique des commandes
      </div>
      <div class="h5 mt-3 font-weight-bold text-info mr-4">
        <button class="btn btn-fidinam" (click)="switchBetweenDeviCommands()">
          Voir les devis
        </button>
      </div>
    </div>

    <div>
      <table class="table table-striped mt-4">
        <thead>
          <tr>
            <th scope="col" class="align-middle">Envoyé le</th>
            <th scope="col" class="align-middle">Type de prestation</th>
            <th scope="col" class="align-middle">Nom du document</th>
            <th scope="col" class="align-middle">De</th>
            <th scope="col" class="align-middle">Vers</th>
            <th scope="col" class="align-middle">Prix (TTC)</th>
            <th scope="col" class="align-middle">Traducteur</th>
            <th scope="col" class="align-middle">Statut</th>
          </tr>
        </thead>

        <tbody>
          <tr
            *ngFor="
              let item of command
                | paginate: { itemsPerPage: 5, currentPage: p }
            "
          >
            <td class="align-middle">
              <button class="btn btn-fidinam-date" disabled>
                {{ item.createdAt.toDate() | date: "dd/MM/yyyy" }}
              </button>
            </td>
            <td class="align-middle">
              {{
                item.prestationInfo.srcCountryCode === ""
                  ? "Traduction classique"
                  : "Traduction assermentée"
              }}
            </td>
            <td class="align-middle">
              {{
                item.documentType.length > 17
                  ? item.documentType.substr(0, 17) + "..."
                  : item.documentType
              }}
            </td>

            <td class="align-middle">
              {{ item.srcLanguageIso639 | iso639ToNative }}
            </td>

            <td class="align-middle">
              {{ item.destLanguageIso639 | iso639ToNative }}
            </td>
            <td class="align-middle">
              {{ item.prestationInfo.price.ttc.toFixed(2) }} &euro;
            </td>
            <td class="align-middle">{{ item.traducteurName }}</td>
            <td class="align-middle font-weight-bold h7">
              <span
                style="color: #935e10"
                *ngIf="item.prestationInfo.state == 'waiting-for-translator'"
                >Nouveau</span
              >
              <span
                style="color: #257f25"
                *ngIf="item.prestationInfo.state == 'translating'"
                >En cours de traduction</span
              >
              <span
                style="color: #257f25"
                *ngIf="item.prestationInfo.state == 'validated-pro'"
                >{{ item.prestationInfo.state }}</span
              >
              <span
                style="color: #935e10"
                *ngIf="
                  item.prestationInfo.state == 'waiting-for-client-to-validate'
                "
                >En attente de validation par le client</span
              >
              <span
                style="color: #935e10"
                *ngIf="item.prestationInfo.state == 'refused-by-translator'"
                >Refusé par le traducteur</span
              >
              <span
                style="color: #935e10"
                *ngIf="item.prestationInfo.state == 'cancelled-by-client'"
                >{{ item.prestationInfo.state }}</span
              >
              <span
                style="color: #935e10"
                *ngIf="item.prestationInfo.state == 'validated'"
                >Validé</span
              >
              <span
                style="color: #935e10"
                *ngIf="item.prestationInfo.state == 'waiting-for-payment'"
                >En attente de paiement</span
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="d-flex justify-content-center mt-4">
      <pagination-controls
        (pageChange)="p = $event"
        class="my-pagination"
      ></pagination-controls>
    </div>
  </div>

  <div class="card mt-4" *ngIf="deviOrCommand === true">
    <div class="d-flex justify-content-between">
      <div class="h5 mt-3 font-weight-bold text-fidinam ml-4">
        Historique des devis
      </div>
      <div class="h5 mt-3 font-weight-bold text-info mr-4">
        <button class="btn btn-fidinam" (click)="switchBetweenDeviCommands()">
          Voir les commandes
        </button>
      </div>
    </div>

    <div>
      <table class="table table-striped mt-4">
        <thead>
          <tr>
            <th scope="col">Date</th>
            <th scope="col">Numéro de devis</th>
            <th scope="col">Type de prestation</th>
            <th scope="col">Devis</th>
          </tr>
        </thead>

        <tbody>
          <tr
            *ngFor="
              let devi of devis | paginate: { itemsPerPage: 4, currentPage: p }
            "
          >
            <th scope="row">
              <button class="btn btn-fidinam-date" disabled>
                {{ devi.data().date.toDate() | date: "dd/MM/yyyy" }}
              </button>
            </th>
            <td>
              <span>{{ devi.data().numero }}</span>
            </td>
            <td>{{ devi.data().typePrestation }}</td>
            <td>
              <button class="btn btn-fidinam" (click)="download(devi.id)">
                <i class="fa fa-download mr-1"></i> Générer
              </button>
            </td>
            <td
              class="text-warning font-weight-bold font-italic"
              *ngIf="devi.data().status === 'waiting'"
            >
              <button
                class="btn btn-outline-success"
                (click)="sendCommand(devi.id)"
              >
                Valider <i class="fa fa-check-circle ml-1"></i>
              </button>
              <button
                class="btn btn-outline-danger ml-2"
                (click)="refusedCommand(devi.id)"
              >
                Refuser <i class="fa fa-remove ml-1"></i>
              </button>
            </td>
            <td
              class="text-success font-weight-bold font-italic"
              *ngIf="devi.data().status === 'accept'"
            >
              <span class="lead">
                <i class="fa fa-check-circle mr-1"></i>
                Devis accepté
              </span>
            </td>

            <td
              class="text-danger font-weight-bold font-italic"
              *ngIf="devi.data().status === 'refused'"
            >
              <span class="lead">
                <i class="fa fa-remove mr-1"></i>
                Devis refusé</span
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="d-flex justify-content-center mt-4">
      <pagination-controls
        (pageChange)="p = $event"
        class="my-pagination"
      ></pagination-controls>
    </div>
  </div>
</div>
