<div class="container-fluid">
  <div class="d-flex">
    <h3 class="text-seine-maritime">Historique des factures</h3>
    <!--  <button class="ml-3 rounded-pill" style="background-color: #702963; color: white">Modifier mon addresse de facturation</button> -->
  </div>
  <div class="card mt-4" *ngIf="!pro">
    <div class="d-flex justify-content-between">
      <div
        class="h5 mt-3 font-weight-bold text-info ml-4 d-flex justify-content-between"
      >
        <div class="facture mr-3">Factures de particuliers</div>

        <div class="dropdown">
          <button
            class="btn btn-outline-secondary dropdown-toggle"
            type="button"
            data-toggle="dropdown"
          >
            {{ filter }}
          </button>
          <ul class="dropdown-menu">
            <li (click)="facturesAsserPart()">
              <a href="javascript:void(0);">Traductions assermentées</a>
            </li>
            <li (click)="facturesClassicPart()">
              <a href="javascript:void(0);">Traductions classiques</a>
            </li>
            <li (click)="facturesInterPart()">
              <a href="javascript:void(0);">Interprétations</a>
            </li>
            <li (click)="allfacturesPart()">
              <a href="javascript:void(0);">Toute les factures</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="mt-3 mr-4">
        <button class="btn btn-outline-primary" (click)="switchBetweenProPar()">
          <i class="fa fa-repeat"></i> Voir factures professionnels
        </button>
      </div>
    </div>

    <div>
      <table class="table table-striped" id="content" #content>
        <thead>
          <tr>
            <th scope="col" class="align-middle">Date</th>
            <th scope="col" class="align-middle">&#8470; facture</th>
            <th scope="col" class="align-middle">&#8470; commande</th>
            <th scope="col" class="align-middle">Type de prestation</th>
            <th scope="col" class="align-middle">Montant Total</th>
            <th scope="col" class="align-middle">Facture</th>
            <th scope="col" class="align-middle">Suppression</th>
            <!--<th scope="col">Handle</th> -->
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let facture of facturesPart
                | paginate: { itemsPerPage: 10, currentPage: p }
            "
          >
            <td class="align-middle">
              <button class="btn btn-seine-maritime-date" disabled>
                {{ facture.data().date.toDate() | date: "dd/MM/yyyy" }}
              </button>
            </td>
            <td class="align-middle">{{ facture.data().numero }}</td>
            <td class="align-middle">{{ facture.data().prestation }}</td>
            <td
              class="align-middle"
              *ngIf="facture.data().typePrestation === 'Traduction assermentée'"
            >
              {{ "traduction_assermentee" | localise }}
            </td>
            <td
              class="align-middle"
              *ngIf="facture.data().typePrestation === 'Traduction classique'"
            >
              {{ "traduction_classic" | localise }}
            </td>
            <td
              class="align-middle"
              *ngIf="facture.data().typePrestation === 'Interprétation'"
            >
              {{ "interpretation" | localise }}
            </td>
            <td class="align-middle">
              {{ facture.data().priceTTC.toFixed(2) }} €
            </td>
            <td class="align-middle justify-content-around">
              <!--  <button class=" btn my-color mr-1" [routerLink]="[facture.data().value.id]" (click)="savePDF()" ><i class="fa fa-download"></i> {{'section_generate_facture' | localise}} </button> -->
              <button
                class="btn btn-danger mr-1"
                (click)="download(facture.data(), 2)"
              >
                <i class="fa fa-download"></i>
                Générer
              </button>
              <button
                class="btn btn-outline-danger mr-1"
                (click)="download(facture.data(), 1)"
              >
                <i class="fa fa-print"></i> {{ "section_print" | localise }}
              </button>

              <!--(click)="savePDF()"-->
            </td>
            <td>
              <button
                class="btn btn-danger"
                (click)="deleteFacture(facture.id)"
              >
                <i class="fa fa-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="d-flex justify-content-center mt-4">
      <pagination-controls
        (pageChange)="p = $event"
        class="my-pagination"
      ></pagination-controls>
    </div>
  </div>

  <div class="card mt-4" *ngIf="pro">
    <div class="d-flex justify-content-between">
      <div
        class="h5 mt-3 font-weight-bold text-info ml-4 d-flex justify-content-between"
      >
        <div class="facture mr-3">Factures de professionels</div>
        <div class="dropdown">
          <button
            class="btn btn-outline-secondary dropdown-toggle"
            type="button"
            data-toggle="dropdown"
          >
            {{ filterPro }}
          </button>
          <ul class="dropdown-menu">
            <li (click)="facturesAsserPro()">
              <a href="javascript:void(0);">Traductions assermentées</a>
            </li>
            <li (click)="facturesClassicPro()">
              <a href="javascript:void(0);">Traductions classiques</a>
            </li>
            <li (click)="facturesInterPro()">
              <a href="javascript:void(0);">Interprétations</a>
            </li>
            <li (click)="allfacturesPro()">
              <a href="javascript:void(0);">Toute les factures</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="mt-3 mr-4">
        <button class="btn btn-outline-primary" (click)="switchBetweenProPar()">
          <i class="fa fa-repeat"></i> Voir factures de particuliers
        </button>
      </div>
    </div>

    <div>
      <table class="table table-striped" id="content" #content>
        <thead>
          <tr>
            <th scope="col" class="align-middle">Date</th>
            <th scope="col" class="align-middle">&#8470; facture</th>
            <th scope="col" class="align-middle">&#8470; commande</th>
            <th scope="col" class="align-middle">Type de prestation</th>
            <th scope="col" class="align-middle">Montant Total</th>
            <th scope="col" class="align-middle">Facture</th>
            <th scope="col" class="align-middle">Suppression</th>
            <!--<th scope="col">Handle</th> -->
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let facture of facturesPro
                | paginate: { itemsPerPage: 10, currentPage: p }
            "
          >
            <td class="align-middle">
              <button class="btn btn-seine-maritime-date" disabled>
                {{ facture.data().date.toDate() | date: "dd/MM/yyyy" }}
              </button>
            </td>
            <td class="align-middle">{{ facture.data().numero }}</td>
            <td class="align-middle">{{ facture.data().prestation }}</td>
            <td
              class="align-middle"
              *ngIf="facture.data().typePrestation === 'Traduction assermentée'"
            >
              {{ "traduction_assermentee" | localise }}
            </td>
            <td
              class="align-middle"
              *ngIf="facture.data().typePrestation === 'Traduction classique'"
            >
              {{ "traduction_classic" | localise }}
            </td>
            <td
              class="align-middle"
              *ngIf="facture.data().typePrestation === 'Interprétation'"
            >
              {{ "interpretation" | localise }}
            </td>
            <td class="align-middle">
              {{ facture.data().priceTTC.toFixed(2) }} €
            </td>
            <td class="align-middle justify-content-around">
              <!--  <button class=" btn my-color mr-1" [routerLink]="[facture.data().value.id]" (click)="savePDF()" ><i class="fa fa-download"></i> {{'section_generate_facture' | localise}} </button> -->
              <button
                class="btn btn-danger mr-1"
                (click)="download(facture.data(), 2)"
              >
                <i class="fa fa-download"></i>
                Générer
              </button>
              <button
                class="btn btn-outline-danger mr-1"
                (click)="download(facture.data(), 1)"
              >
                <i class="fa fa-print"></i> {{ "section_print" | localise }}
              </button>
            </td>
            <td>
              <button
                class="btn btn-danger"
                (click)="deleteFacture(facture.id)"
              >
                <i class="fa fa-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="d-flex justify-content-center mt-4">
      <pagination-controls
        (pageChange)="p = $event"
        class="my-pagination"
      ></pagination-controls>
    </div>
  </div>
</div>
