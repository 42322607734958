import * as yup from "yup";

export interface IProfessionnel {
  uid: string;
  businessName: string;
  address: IAddress;
  coords: IGeoCoords;
  telephone: string;
  email: string;
  registration: string; // SIRET, SIREN, ...
  budget?: number;
  subscription?: string;
  createdAt?: Date;
  service?: string;
  siret?: string;
  poste?: string;
  isDevis?: boolean;
}

export interface IAddress {
  formatted: string;
  number: string;
  street: string;
  city: string;
  country: string;
  code: string;
  extra: string;
}

export interface IGeoCoords {
  latitude: number;
  longitude: number;
}

export const ProfessionnelAddressSchema = yup.object({
  formatted: yup.string().default(""),
  number: yup.string().default(""),
  street: yup.string().default(""),
  city: yup.string().default(""),
  country: yup.string().default(""),
  code: yup.string().default(""),
  extra: yup.string().default(""),
});

export const ProfessionnelCoordsSchema = yup.object({
  latitude: yup.number().default(0),
  longitude: yup.number().default(0),
});

export const ProfessionnelProfileSchema = yup.object({
  uid: yup.string(),
  businessName: yup.string(),
  address: ProfessionnelAddressSchema,
  coords: ProfessionnelCoordsSchema,
  telephone: yup.string(),
  email: yup.string().email(),
  registration: yup.string().default(""),
  budget: yup.number().default(0),
});

export const EmptyProfessionnelProfile = ProfessionnelProfileSchema.cast({});
export const EmptyProfessionnelAddress = ProfessionnelAddressSchema.cast({});
export const EmptyProfessionnelCoords = ProfessionnelCoordsSchema.cast({});
