var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { EnumPrestationState } from "../../../../common/src/bdd/interfaces/IPrestation";
import { COLLECTION } from "../../../../common/src/bdd/utility/collections";
import { HieroBDD } from "../hierobdd.service";
import { TypeProfessionnal } from "../../../../common/src/bdd/interfaces/types";
import { ProFactureService } from "./pro-factures.service";
export class ProInterpretationsService {
    constructor(hiero, proFactureService) {
        this.hiero = hiero;
        this.proFactureService = proFactureService;
    }
    getProInterpretation() {
        return new Promise((resolve, reject) => {
            this.hiero.DB.collection(COLLECTION.INTERPRETATIONS_COMMANDES)
                .orderBy("sendTime", "desc")
                .get()
                .then((snap) => {
                resolve(snap.docs);
            })
                .catch(reject);
        });
    }
    getSingleProInterpretation(id) {
        return new Promise((resolve, reject) => {
            this.hiero.DB.collection(COLLECTION.INTERPRETATIONS_COMMANDES)
                .doc(id)
                .get()
                .then((snap) => {
                resolve(snap.data());
            })
                .catch(reject);
        });
    }
    updateProInterpretationStatus(id, status) {
        return new Promise((resolve, reject) => {
            this.hiero.DB.collection(COLLECTION.INTERPRETATIONS_COMMANDES)
                .doc(id)
                .update({ state: status })
                .then((value) => resolve("Mis à jours"))
                .catch(reject);
        });
    }
    /*
    addProInterpretation(idDevis: string): Promise<string> {
      return new Promise((resolve, reject) => {
        this.hiero.DB.collection(COLLECTION.INTERPRETATIONS_COMMANDES).add()
          .then((value) => resolve("Mis à jours"))
          .catch(reject);
      });
    } */
    addProInterpretation(event, formValue, translatorName, translatorId, professionnelName, professionnelId, professionnelUid, professionnelType, price, devisId) {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => __awaiter(this, void 0, void 0, function* () {
                let interpretation = {
                    sendTime: new Date(),
                    missions: formValue,
                    state: EnumPrestationState.WaitingForTranslator,
                    translatorName: translatorName,
                    translatorId: translatorId,
                    professionnelName: professionnelName,
                    professionnelId: professionnelId,
                    professionnelUid: professionnelUid,
                    event: event,
                    price: price,
                    devisId: devisId,
                };
                if (professionnelType === TypeProfessionnal.seineMaritime)
                    interpretation["isInterpretationSeineMaritime"] = true;
                if (professionnelType === TypeProfessionnal.fidinam)
                    interpretation["isInterpretationFidinam"] = true;
                this.hiero.DB.collection(COLLECTION.INTERPRETATIONS_COMMANDES)
                    .add(interpretation)
                    .then((value) => {
                    this.proFactureService.addFacture(value.id, event, formValue, price, translatorId, translatorName, professionnelId, professionnelName, professionnelType);
                    resolve(value.id);
                })
                    .catch(reject);
            }));
        });
    }
}
