<div *ngIf="singleInterpretation">
  <div class="lead font-italic mb-5">
    Gestion de commande d'interpretations /
    <span class="text-danger"> Commande n° {{ id }}</span>
  </div>

  <div class="row ml-4 d-flex justify-content-between container-fluid">
    <div class="col-md-8 card ml-n5">
      <div class="lead">
        <i class="fa fa-pencil text-danger mr-3 mt-3 mb-2"></i> Détails de la
        commande
      </div>

      <div class="d-flex justify-content-between">
        <span class="h6 font-italic text-center mt-1">
          <input
            type="text"
            placeholder="Reçu le"
            disabled="disabled"
            class="input1"
          />
          <input
            type="text"
            placeholder=" {{
              singleInterpretation.sendTime.toDate() | date : 'dd/MM/yyyy'
            }} "
            disabled="disabled"
            class="input2"
          />
        </span>

        <span class="h6 font-italic text-center mt-1">
          <input
            type="text"
            placeholder="Statut"
            disabled="disabled"
            class="input1"
          />
          <input
            type="text"
            placeholder=" {{ singleInterpretation.state }} "
            disabled="disabled"
            class="input2"
          />
        </span>
      </div>

      <div class="d-flex justify-content-between">
        <span class="h6 font-italic text-center mt-1">
          <input
            type="text"
            placeholder="Intervention"
            disabled="disabled"
            class="input1"
          />
          <input
            type="text"
            placeholder=" {{
              singleInterpretation.missions.intervention.name
            }} "
            disabled="disabled"
            class="input2"
          />
        </span>

        <span class="h6 font-italic text-center mt-1">
          <input
            type="text"
            placeholder="Objet"
            disabled="disabled"
            class="input1"
          />
          <input
            type="text"
            placeholder=" {{ singleInterpretation.event }} "
            disabled="disabled"
            class="input2"
          />
        </span>
      </div>

      <div class="d-flex justify-content-between">
        <span class="h6 font-italic text-center mt-1">
          <input
            type="text"
            placeholder="Langue d'origine"
            disabled="disabled"
            class="input1"
          />
          <input
            type="text"
            placeholder=" {{
              singleInterpretation.missions.srcLang | iso639ToNative
            }} "
            disabled="disabled"
            class="input2"
          />
        </span>
        <span class="h6 font-italic text-center mt-1">
          <input
            type="text"
            placeholder="Traduit vers"
            disabled="disabled"
            class="input1"
          />
          <input
            type="text"
            placeholder=" {{
              singleInterpretation.missions.destLang | iso639ToNative
            }} "
            disabled="disabled"
            class="input2"
          />
        </span>
      </div>

      <div class="d-flex justify-content-between">
        <span class="h6 font-italic text-center mt-1">
          <input
            type="text"
            placeholder="Durée"
            disabled="disabled"
            class="input1"
          />
          <input
            type="text"
            placeholder=" {{ singleInterpretation.missions.extHours }} "
            disabled="disabled"
            class="input2"
          />
        </span>

        <span class="h6 font-italic text-center mt-1">
          <input
            type="text"
            placeholder="Date d'intervention"
            disabled="disabled"
            class="input1"
          />
          <input
            type="text"
            placeholder=" {{
              singleInterpretation.missions.date.toDate()
                | date : 'dd/MM/yyyy HH:mm'
            }} "
            disabled="disabled"
            class="input2"
          />
        </span>
      </div>

      <div class="d-flex justify-content-between">
        <span class="h6 font-italic text-center mt-1">
          <input
            type="text"
            placeholder="Prix TTC"
            disabled="disabled"
            class="input1"
          />
          <input
            type="text"
            placeholder=" {{ singleInterpretation.price.priceTTC }} &euro; "
            disabled="disabled"
            class="input2"
          />
        </span>
      </div>

      <div class="mt-3 d-flex justify-content-start mb-2">
        <details>
          <summary><mark>Description de la commande</mark></summary>
          <p>{{ singleInterpretation.missions.context }}</p>
        </details>
      </div>
    </div>

    <div class="col-md-4 card">
      <div class="lead">
        <i class="fa fa-users text-danger mr-3 mt-3"></i> Détails sur le client
        et le traducteur
      </div>
      <hr class="mt-4" />
      <div class="h5 text-muted mb-3 d-flex justify-content-between">
        <div><i class="fa fa-user text-danger mr-3"></i> Client</div>

        <button
          class="btn btn-admin btn-sm"
          (click)="profile(singleInterpretation.professionnelUid)"
        >
          <i class="fa fa-eye"></i> Consulter son profil
        </button>
      </div>

      <div class="d-flex justify-content-between">
        <span class="h6 font-italic text-center mt-1">
          <input
            type="text"
            placeholder="Type"
            disabled="disabled"
            class="input3"
          />
          <input
            type="text"
            placeholder=" Professionnel "
            disabled="disabled"
            class="input4"
          />
        </span>

        <span class="h6 font-italic text-center mt-1">
          <input
            type="text"
            placeholder="Raison sociale"
            disabled="disabled"
            class="input3"
          />
          <input
            type="text"
            placeholder=" {{ singleInterpretation.professionnelName }} "
            disabled="disabled"
            class="input4"
          />
        </span>
      </div>
      <hr />
      <div class="h5 text-muted mt-3 d-flex justify-content-between mb-3">
        <div><i class="fa fa-language text-danger mr-3"></i> Traducteur</div>
      </div>

      <div class="d-flex justify-content-between">
        <span class="h6 font-italic text-center mt-1">
          <input
            type="text"
            placeholder="Raison sociale"
            disabled="disabled"
            class="input3"
          />
          <input
            type="text"
            placeholder=" {{ singleInterpretation.translatorName }} "
            disabled="disabled"
            class="input4"
          />
        </span>
      </div>
      <hr />
    </div>
  </div>

  <div class="row mt-4 ml-4 d-flex justify-content-between container-fluid">
    <div class="col-md-8 card ml-n5">
      <div class="lead">
        <i class="fa fa-trash text-danger mr-3 mt-3"></i> Supprimer la commande
      </div>
      <hr />
      <div
        class="lead font-weight-bold font-italic text-muted"
        *ngIf="
          singleInterpretation.state === 'waiting-for-payment' ||
          singleInterpretation.state === 'waiting-for-translator' ||
          singleInterpretation.state === 'refused-by-translator'
        "
      >
        Voulez vous supprimer la commande d'interpretation n° {{ id }} ?
      </div>

      <div
        class="lead font-weight-bold font-italic text-muted"
        *ngIf="
          singleInterpretation.state !== 'waiting-for-payment' &&
          singleInterpretation.state !== 'waiting-for-translator' &&
          singleInterpretation.state !== 'refused-by-translator'
        "
      >
        Il est trop tard pour supprimer cette commande
      </div>

      <div
        class="d-flex justify-content-end mb-3"
        *ngIf="
          singleInterpretation.state === 'waiting-for-payment' ||
          singleInterpretation.state === 'waiting-for-translator' ||
          singleInterpretation.state === 'refused-by-translator'
        "
      >
        <button class="btn btn-admin" (click)="deleteCommand()">
          Supprimer la commande
        </button>
      </div>
    </div>

    <div class="col-md-4 card">
      <div class="lead">
        <i class="fa fa-files-o text-danger mr-3 mt-3"></i> Gestion du statut de
        la commande
      </div>

      <hr />

      <div class="d-flex justify-content-center mt-2">
        <button
          class="btn btn-admin mr-3"
          *ngIf="singleInterpretation.state === 'waiting-for-translator'"
          (click)="changeStatus('refused-by-translator', 'pro')"
        >
          Refuser la mission
        </button>
        <button
          class="btn btn-success"
          *ngIf="
            singleInterpretation.state !== 'validated' &&
            singleInterpretation.state !== 'refused-by-translator'
          "
          (click)="changeStatus('validated', 'pro')"
        >
          Terminer la mission
        </button>
        <button
          class="btn btn-admin"
          *ngIf="singleInterpretation.state === 'refused-by-translator'"
          (click)="redirectToOtherTrad()"
        >
          Rediriger vers un autre traducteur
        </button>
        <span *ngIf="singleInterpretation.state === 'validated'">
          Commande déja validé.
        </span>
      </div>
    </div>
  </div>
</div>
