import * as yup from "yup";

// Parts of the user profile required for user signup
export interface ISignupUserProfile {
  email?: string;
  givenName: string;
  familyName: string;
  telephone: string;
  subscription?: string;
  budget?: number;
  complement_address?: string;
  adress_email?: string;
  isANR?: boolean;
  isExpat?: boolean;
  isSeineMaritime?: boolean;
  isFidinam?: boolean;
  createdAt?: string;
}

export interface IUserProfile extends ISignupUserProfile {
  // TODO: Add fields here for generic user profile
  // These fields are ones that are not required for signup

  address?: string;
}

export const UserProfileSchema = yup.object({
  email: yup.string().email(),
  givenName: yup.string().required(),
  familyName: yup.string().required(),
  telephone: yup.string().required(),
  subscription: yup.string(),
  adress_email: yup.string().email(),
  createdAt: yup.string(),
});

export const EmptyUserProfile = UserProfileSchema.cast({});
