var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Template } from './Template';
const FIREBASE_TEMPLATE_COLLECTION = 'templates';
export class TemplateList {
    constructor(user, query, initSnap, watch) {
        this._totalFilteredUnpagedCount = 0;
        this._page = 0;
        this._pageSize = 10;
        this.getData = null;
        this._user = user;
        this.reconstruct(initSnap);
        this._listSubject = new BehaviorSubject(this.All);
        if (watch) {
            this._stopListening = query.onSnapshot({
                next: (snapshot) => {
                    this.reconstruct(snapshot);
                },
                error: (err) => {
                }
            });
        }
    }
    get Count() {
        return this._filteredArr.length;
    }
    /**
     * Gets the number of filtered items before paging
     */
    get TotalCount() {
        return this._totalFilteredUnpagedCount;
    }
    /**
     * Get a copy of your query
     */
    get All() {
        return [...this._filteredArr];
    }
    updateQuery(filters, order, page, pageSize) {
        this._filter = filters;
        this._order = order;
        this._page = page;
        this._pageSize = pageSize;
        // Filter and order
        this._filteredArr = this.getWithQuery(this._filter, this._order, page, pageSize);
        if (this._listSubject) {
            this._listSubject.next(this.All);
        }
    }
    getWithQuery(filters, order, page = 0, pageSize = 10) {
        // Filter by selected states
        let result = [];
        if (filters && filters.length > 0) {
            const filterSet = new Set();
            filters.forEach((filter) => {
                const filterResult = this._templateArr.filter((template) => {
                    // Iterate through ANDS, find one where there is no match
                    const noMatch = filter.and.find((colVal) => {
                        return (template.Data[colVal.column] !== colVal.value);
                    });
                    // If none found, all match, therefore add to filter result
                    return (!noMatch);
                });
                // Add only unique to list
                filterResult.forEach((template) => {
                    if (!filterSet.has(template.Id)) {
                        result.push(template);
                        filterSet.add(template.Id);
                    }
                });
            });
        }
        else {
            // No filter means all values
            result = this._templateArr;
        }
        // Order
        if (order && order.length > 0) {
            for (let i = order.length - 1; i >= 0; --i) {
                const orderParam = order[i];
                const sorted = result.sort((a, b) => {
                    let res = 0;
                    let dataA = a.Data[orderParam.column];
                    let dataB = a.Data[orderParam.column];
                    if (this.getData) {
                        dataA = this.getData(orderParam.column, a.Data[orderParam.column]);
                        dataB = this.getData(orderParam.column, b.Data[orderParam.column]);
                    }
                    if (typeof (dataA) === 'string') {
                        res = dataA.localeCompare(dataB);
                    }
                    else {
                        res = dataA - dataB;
                    }
                    if (orderParam.desc) {
                        res = res * -1;
                    }
                    return res;
                });
                result = sorted;
            }
        }
        // Save the total filtered count
        this._totalFilteredUnpagedCount = result.length;
        // Paging
        const startPage = page * pageSize;
        result = result.slice(startPage, startPage + pageSize);
        return result;
    }
    getTemplate(id) {
        return this._templateMap.get(id);
    }
    reconstruct(snap) {
        this._templateMap = new Map();
        this._templateArr = [];
        snap.forEach((doc) => {
            const raw = doc.data();
            const template = new Template(this._user, doc.ref, doc.id, raw);
            this._templateMap.set(doc.id, template);
            this._templateArr.push(template);
        });
        // Filter and order
        this._filteredArr = this.getWithQuery(this._filter, this._order);
        if (this._listSubject) {
            this._listSubject.next(this.All);
        }
    }
    /**
     * Subscribe for changes to this list
     * @param observer
     */
    WatchList(observer) {
        return this._listSubject.subscribe(observer);
    }
    /**
     * Make sure you call this function to avoid memory leaks.
     */
    cleanup() {
        if (this._stopListening) {
            this._stopListening();
            this._stopListening = null;
        }
    }
    static Init(user, watch) {
        return __awaiter(this, void 0, void 0, function* () {
            const query = user.DB.collection(FIREBASE_TEMPLATE_COLLECTION);
            const snapshot = yield query.get();
            return new TemplateList(user, query, snapshot, watch);
        });
    }
    static CountTemplates(user, srcLangIso639, destLangIso639) {
        return __awaiter(this, void 0, void 0, function* () {
            const query = user.DB.collection(FIREBASE_TEMPLATE_COLLECTION)
                .where('srcLanguageIso639', '==', srcLangIso639)
                .where('destLanguageIso639', '==', destLangIso639);
            const snapshot = yield query.get();
            return snapshot.size;
        });
    }
}
