import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { HieroBDD } from "../../../services/hierobdd.service";

@Component({
  selector: "usersFidinam",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.scss"],
})
export class UsersFidinamComponent implements OnInit {
  public utilisateurs;
  p: number = 1;

  constructor(private hiero: HieroBDD, private router: Router) {}

  ngOnInit(): void {
    this.allUsers().then((value) => (this.utilisateurs = value));
  }

  private allUsers() {
    return this.hiero.DB.collection("users")
      .where("profile.isFidinam", "==", true)
      .orderBy("profile.createdAt", "desc")
      .get()
      .then((snap) => {
        return snap.docs;
      });
  }
}
