<div class="container-fluid">
  <div class="d-flex justify-content-around">
    <div class="lead">
      <i class="fa fa-folder-open text-info"></i>
      Gestion de toutes les commandes de Hierø
    </div>
  </div>

  <div class="card mt-4" *ngIf="particular">
    <div class="d-flex justify-content-between">
      <div
        class="h5 mt-3 font-weight-bold text-info ml-4 d-flex justify-content-between"
      >
        <div class="mr-3">Commandes de particuliers</div>

        <div class="dropdown">
          <button
            class="btn btn-outline-secondary dropdown-toggle"
            type="button"
            data-toggle="dropdown"
          >
            {{ filter }}
          </button>
          <ul class="dropdown-menu">
            <input
              class="form-control"
              id="myInput"
              type="text"
              placeholder="Chercher..."
              #inpt
              (keyup)="search(inpt.value)"
            />

            <li (click)="commandAsserParticular()">
              <a href="javascript:void(0);">Traduction assermenté</a>
            </li>
            <li (click)="commandClassicParticular()">
              <a href="javascript:void(0);">Traduction classique</a>
            </li>
            <li (click)="allCommandParticular()">
              <a href="javascript:void(0);">Toute les commandes</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="mt-3 mr-4">
        <button class="btn btn-outline-primary" (click)="switchBetweenProPar()">
          <i class="fa fa-repeat"></i> Voir commandes professionnels
        </button>
      </div>
    </div>

    <div>
      <table class="table table-striped mt-4">
        <thead>
          <tr>
            <th scope="col" class="align-middle">Envoyé le</th>
            <th scope="col" class="align-middle">Type de prestation</th>
            <th scope="col" class="align-middle">Nom du document</th>
            <th scope="col" class="align-middle">De</th>
            <th scope="col" class="align-middle">Vers</th>
            <th scope="col" class="align-middle">Prix (TTC)</th>
            <th scope="col" class="align-middle">Traducteur</th>
            <th scope="col" class="align-middle">Statut</th>
            <th scope="col" class="align-middle">Partenaire</th>
          </tr>
        </thead>
        <tbody *ngIf="searching === false">
          <tr
            *ngFor="
              let item of commandParticular
                | paginate: { itemsPerPage: 10, currentPage: p }
            "
            class="clickable"
            [routerLink]="[item.prestationId]"
          >
            <td class="align-middle">
              {{ item.createdAt.toDate() | date: "dd/MM/yyyy" }}
            </td>
            <td class="align-middle">
              {{
                item.prestationInfo.srcCountryCode === ""
                  ? "Traduction classique"
                  : "Traduction assermentée"
              }}
            </td>
            <td class="align-middle">
              {{
                item.documentType.length > 17
                  ? item.documentType.substr(0, 17) + "..."
                  : item.documentType
              }}
            </td>

            <td class="align-middle">
              {{ item.srcLanguageIso639 | iso639ToNative }}
            </td>

            <td class="align-middle">
              {{ item.destLanguageIso639 | iso639ToNative }}
            </td>
            <td class="align-middle">
              {{ item.prestationInfo.price.ttc.toFixed(2) }} &euro;
            </td>
            <td class="align-middle">{{ item.traducteurName }}</td>
            <td class="align-middle font-weight-bold h7">
              <span
                style="color: #935e10"
                *ngIf="item.prestationInfo.state == 'waiting-for-translator'"
                >Nouveau</span
              >
              <span
                style="color: orange"
                *ngIf="item.prestationInfo.state == 'translating'"
                >En cours de traduction</span
              >
              <span
                style="color: #257f25"
                *ngIf="item.prestationInfo.state == 'validated-pro'"
                >{{ item.prestationInfo.state }}</span
              >
              <span
                style="color: #935e10"
                *ngIf="
                  item.prestationInfo.state == 'waiting-for-client-to-validate'
                "
                >En attente de validation par le client</span
              >
              <span
                style="color: red"
                *ngIf="item.prestationInfo.state == 'refused-by-translator'"
                >Refusé par le traducteur</span
              >
              <span
                style="color: #935e10"
                *ngIf="item.prestationInfo.state == 'cancelled-by-client'"
                >{{ item.prestationInfo.state }}</span
              >
              <span
                style="color: #935e10"
                *ngIf="item.prestationInfo.state == 'validated'"
                >Validé</span
              >
              <span
                style="color: #935e10"
                *ngIf="item.prestationInfo.state == 'waiting-for-payment'"
                >En attente de paiement</span
              >
            </td>
            <td>
              {{
                item.prestationInfo.partenaire !== "science-acceuil"
                  ? "Expat.com"
                  : "Science-accueil"
              }}
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="searching === true">
          <tr
            *ngFor="
              let item of result
                | paginate: { itemsPerPage: 10, currentPage: p }
            "
            class="clickable"
            [routerLink]="[item.prestationId]"
          >
            <td class="align-middle">
              {{ item.createdAt | date: "dd/MM/yyyy" }}
            </td>
            <td class="align-middle">
              {{
                item.prestationInfo.srcCountryCode === ""
                  ? "Traduction classique"
                  : "Traduction assermentée"
              }}
            </td>
            <td class="align-middle">
              {{
                item.documentType.length > 17
                  ? item.documentType.substr(0, 17) + "..."
                  : item.documentType
              }}
            </td>

            <td class="align-middle">
              {{ item.srcLanguageIso639 | iso639ToNative }}
            </td>

            <td class="align-middle">
              {{ item.destLanguageIso639 | iso639ToNative }}
            </td>
            <td class="align-middle">
              {{ item.prestationInfo.price.ttc.toFixed(2) }} &euro;
            </td>
            <td class="align-middle">{{ item.traducteurName }}</td>
            <td class="align-middle font-weight-bold h7">
              <span
                style="color: #935e10"
                *ngIf="item.prestationInfo.state == 'waiting-for-translator'"
                >Nouveau</span
              >
              <span
                style="color: orange"
                *ngIf="item.prestationInfo.state == 'translating'"
                >En cours de traduction</span
              >
              <span
                style="color: #257f25"
                *ngIf="item.prestationInfo.state == 'validated-pro'"
                >{{ item.prestationInfo.state }}</span
              >
              <span
                style="color: #935e10"
                *ngIf="
                  item.prestationInfo.state == 'waiting-for-client-to-validate'
                "
                >En attente de validation par le client</span
              >
              <span
                style="color: red"
                *ngIf="item.prestationInfo.state == 'refused-by-translator'"
                >Refusé par le traducteur</span
              >
              <span
                style="color: #935e10"
                *ngIf="item.prestationInfo.state == 'cancelled-by-client'"
                >{{ item.prestationInfo.state }}</span
              >
              <span
                style="color: #257f25"
                *ngIf="item.prestationInfo.state == 'validated'"
                >Validé</span
              >
              <span
                style="color: #935e10"
                *ngIf="item.prestationInfo.state == 'waiting-for-payment'"
                >En attente de paiement</span
              >
            </td>
            <td>
              {{
                item.prestationInfo.partenaire !== "science-acceuil"
                  ? "Expat.com"
                  : "Science-acceuil"
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="d-flex justify-content-center mt-4">
      <pagination-controls
        (pageChange)="p = $event"
        class="my-pagination"
      ></pagination-controls>
    </div>
  </div>

  <div class="card mt-4" *ngIf="particular === false">
    <div class="d-flex justify-content-between">
      <div
        class="h5 mt-3 font-weight-bold text-info ml-4 d-flex justify-content-between"
      >
        <div class="mr-3">Commandes de professionels</div>
        <div class="dropdown">
          <button
            class="btn btn-outline-secondary dropdown-toggle"
            type="button"
            data-toggle="dropdown"
          >
            {{ filterPro }}
          </button>
          <ul class="dropdown-menu">
            <input
              class="form-control"
              id="myInput"
              type="text"
              placeholder="Chercher..."
              #inpt
              (keyup)="searchPro(inpt.value)"
            />

            <li (click)="commandAsserPro()">
              <a href="javascript:void(0);">Traduction assermenté</a>
            </li>
            <li (click)="commandClassicPro()">
              <a href="javascript:void(0);">Traduction classique</a>
            </li>
            <li (click)="allCommandPro()">
              <a href="javascript:void(0);">Toute les commandes</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="mt-3 mr-4">
        <button class="btn btn-outline-primary" (click)="switchBetweenProPar()">
          <i class="fa fa-repeat"></i> Voir commandes de particuliers
        </button>
      </div>
    </div>

    <div>
      <table class="table table-striped mt-4">
        <thead>
          <tr>
            <th scope="col" class="align-middle">Envoyé le</th>
            <th scope="col" class="align-middle">Type de prestation</th>
            <th scope="col" class="align-middle">Nom du document</th>
            <th scope="col" class="align-middle">De</th>
            <th scope="col" class="align-middle">Vers</th>
            <th scope="col" class="align-middle">Prix (TTC)</th>
            <th scope="col" class="align-middle">Traducteur</th>
            <th scope="col" class="align-middle">Statut</th>
          </tr>
        </thead>
        <tbody *ngIf="searchingPro === false">
          <tr
            *ngFor="
              let item of commandPro
                | paginate: { itemsPerPage: 10, currentPage: p2 }
            "
            class="clickable"
            [routerLink]="[item.prestationId]"
          >
            <td class="align-middle">
              {{ item.createdAt.toDate() | date: "dd/MM/yyyy" }}
            </td>
            <td class="align-middle">
              {{
                item.prestationInfo.srcCountryCode === ""
                  ? "Traduction classique"
                  : "Traduction assermentée"
              }}
            </td>
            <td class="align-middle">
              {{
                item.documentType.length > 17
                  ? item.documentType.substr(0, 17) + "..."
                  : item.documentType
              }}
            </td>

            <td class="align-middle">
              {{ item.srcLanguageIso639 | iso639ToNative }}
            </td>

            <td class="align-middle">
              {{ item.destLanguageIso639 | iso639ToNative }}
            </td>
            <td class="align-middle">
              {{ item.prestationInfo.price.ttc.toFixed(2) }} &euro;
            </td>
            <td class="align-middle">{{ item.traducteurName }}</td>
            <td class="align-middle font-weight-bold h7">
              <span
                style="color: #935e10"
                *ngIf="item.prestationInfo.state == 'waiting-for-translator'"
                >Nouveau</span
              >
              <span
                style="color: orange"
                *ngIf="item.prestationInfo.state == 'translating'"
                >En cours de traduction</span
              >
              <span
                style="color: #257f25"
                *ngIf="item.prestationInfo.state == 'validated-pro'"
                >{{ item.prestationInfo.state }}</span
              >
              <span
                style="color: #935e10"
                *ngIf="
                  item.prestationInfo.state == 'waiting-for-client-to-validate'
                "
                >En attente de validation par le client</span
              >
              <span
                style="color: red"
                *ngIf="item.prestationInfo.state == 'refused-by-translator'"
                >Refusé par le traducteur</span
              >
              <span
                style="color: #935e10"
                *ngIf="item.prestationInfo.state == 'cancelled-by-client'"
                >{{ item.prestationInfo.state }}</span
              >
              <span
                style="color: #257f25"
                *ngIf="item.prestationInfo.state == 'validated'"
                >Validé</span
              >
              <span
                style="color: #935e10"
                *ngIf="item.prestationInfo.state == 'waiting-for-payment'"
                >En attente de paiement</span
              >
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="searchingPro === true">
          <tr
            *ngFor="
              let item of resultPro
                | paginate: { itemsPerPage: 10, currentPage: p2 }
            "
            class="clickable"
            [routerLink]="[item.prestationId]"
          >
            <td class="align-middle">
              {{ item.createdAt | date: "dd/MM/yyyy" }}
            </td>
            <td class="align-middle">
              {{
                item.prestationInfo.srcCountryCode === ""
                  ? "Traduction classique"
                  : "Traduction assermentée"
              }}
            </td>
            <td class="align-middle">
              {{
                item.documentType.length > 17
                  ? item.documentType.substr(0, 17) + "..."
                  : item.documentType
              }}
            </td>

            <td class="align-middle">
              {{ item.srcLanguageIso639 | iso639ToNative }}
            </td>

            <td class="align-middle">
              {{ item.destLanguageIso639 | iso639ToNative }}
            </td>
            <td class="align-middle">
              {{ item.prestationInfo.price.ttc.toFixed(2) }} &euro;
            </td>
            <td class="align-middle">{{ item.traducteurName }}</td>
            <td class="align-middle font-weight-bold h7">
              <span
                style="color: #935e10"
                *ngIf="item.prestationInfo.state == 'waiting-for-translator'"
                >Nouveau</span
              >
              <span
                style="color: orange"
                *ngIf="item.prestationInfo.state == 'translating'"
                >En cours de traduction</span
              >
              <span
                style="color: #257f25"
                *ngIf="item.prestationInfo.state == 'validated-pro'"
                >{{ item.prestationInfo.state }}</span
              >
              <span
                style="color: #935e10"
                *ngIf="
                  item.prestationInfo.state == 'waiting-for-client-to-validate'
                "
                >En attente de validation par le client</span
              >
              <span
                style="color: red"
                *ngIf="item.prestationInfo.state == 'refused-by-translator'"
                >Refusé par le traducteur</span
              >
              <span
                style="color: #935e10"
                *ngIf="item.prestationInfo.state == 'cancelled-by-client'"
                >{{ item.prestationInfo.state }}</span
              >
              <span
                style="color: #935e10"
                *ngIf="item.prestationInfo.state == 'validated'"
                >Validé</span
              >
              <span
                style="color: #935e10"
                *ngIf="item.prestationInfo.state == 'waiting-for-payment'"
                >En attente de paiement</span
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="d-flex justify-content-center mt-4">
      <pagination-controls
        (pageChange)="p2 = $event"
        class="my-pagination"
      ></pagination-controls>
    </div>
  </div>
</div>
