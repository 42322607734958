<div class="container">
  <div class="main-body">
    <!-- /Breadcrumb -->

    <div class="row gutters-sm">
      <div class="col-md-4 mb-3">
        <div class="card">
          <div class="card-body">
            <div class="d-flex flex-column align-items-center text-center">
              <img
                src="{{
                  photo ? photo : '../../../assets/img/user/avatarUser.png'
                }}"
                alt="Admin"
                class="rounded-circle"
                width="150"
              />
              <div class="mt-3">
                <h4 class="text-admin">
                  {{ user.profile.givenName + " " + user.profile.familyName }}
                </h4>
                <p class="text-secondary mb-1">
                  {{ user.roles[0].type.toUpperCase() }}
                </p>
                <p class="text-muted font-size-sm">
                  Inscrit le {{ registrationTime | date: "dd/MM/yyyy" }}
                </p>
                <button class="btn btn-danger mr-2" disabled>
                  Rénitialiser le mot de passe
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          class="card mt-3"
          *ngIf="
            user.roles[0].type === 'traducteur' || user.roles[0].type === 'pro'
          "
        >
          <ul class="list-group list-group-flush">
            <li
              class="list-group-item d-flex justify-content-between align-items-center flex-wrap"
              *ngIf="user.roles[0].type === 'traducteur'"
            >
              <h6 class="mb-0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-globe mr-2 icon-inline"
                >
                  <circle cx="12" cy="12" r="10"></circle>
                  <line x1="2" y1="12" x2="22" y2="12"></line>
                  <path
                    d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"
                  ></path></svg
                >Est il assermenté?
              </h6>
              <span class="text-secondary">{{
                userAdress.assermentation ? "oui" : "non"
              }}</span>
            </li>

            <li
              class="list-group-item d-flex justify-content-between align-items-center flex-wrap"
            >
              <h6 class="mb-0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-globe mr-2 icon-inline"
                >
                  <circle cx="12" cy="12" r="10"></circle>
                  <line x1="2" y1="12" x2="22" y2="12"></line>
                  <path
                    d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"
                  ></path></svg
                >Abonnement actuel
              </h6>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-center flex-wrap"
            >
              <h6 class="mb-0">
                <i class="fa fa-building mr-2 ml-2"></i> Raison sociale
              </h6>
              <span class="text-secondary">{{ userAdress.businessName }}</span>
            </li>

            <li
              class="list-group-item d-flex justify-content-between align-items-center flex-wrap"
            >
              <h6 class="mb-0">
                <i class="ml-2 mr-2 fa fa-registered"></i> Siret
              </h6>
              <span class="text-secondary">{{ userAdress.registration }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-8">
        <div class="card mb-3">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-3">
                <h6 class="mb-0">Nom complet</h6>
              </div>
              <div class="col-sm-9 text-secondary">
                {{ user.profile.givenName + " " + user.profile.familyName }}
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-sm-3">
                <h6 class="mb-0">Email</h6>
              </div>
              <div class="col-sm-9 text-secondary">
                {{ email }}
                <button
                  [ngClass]="
                    verify ? 'btn btn-vert ml-5' : 'btn btn-danger ml-5'
                  "
                >
                  <i [ngClass]="verify ? 'fa fa-check' : 'fa fa-times'"></i>
                  {{ verify ? "Vérifié" : "Non vérifié" }}
                </button>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-sm-3">
                <h6 class="mb-0">Identifiant utilisateur</h6>
              </div>
              <div class="col-sm-9 text-secondary">{{ id }}</div>
            </div>
            <hr />
            <div class="row">
              <div class="col-sm-3">
                <h6 class="mb-0">Téléphone</h6>
              </div>
              <div class="col-sm-9 text-secondary">
                {{ user.profile.telephone }}
              </div>
            </div>

            <hr />
            <div class="row">
              <div class="col-sm-3">
                <h6 class="mb-0">Adresse</h6>
              </div>

              <div class="col-sm-9 text-secondary" *ngIf="userAdress">
                {{ userAdress.address.number }}
                {{ userAdress.address.street
                }}{{ userAdress.address.street !== "" ? "," : "" }}
                {{ userAdress.address.code }}
                {{ userAdress.address.city }}
                {{ userAdress.address.country }}
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-sm-3">
                <h6 class="mb-0">Complément d'adresse</h6>
              </div>
              <div class="col-sm-9 text-secondary">
                {{ userAdress.address.extra }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
