import { EnumPrestationState } from "./IPrestation";
import { PrestationType } from "./types";

export interface DevisDocument {
  id: string;
  devisData: IDevis;
}

export interface IDevis {
  numero: number;
  date: any;
  srcLang: string;
  destLang: string;
  professionnelName: string;
  professionnelId: string;
  professionnelUid: string;
  translatorName: string;
  translatorId: string;
  state: EnumDevisState;
  acceptedByClientAt?: any;
  refusedByClientAt?: any;
  price: IPrice;
  typePrestation: PrestationType;

  purchaseOrder?: string;
  pagesNumber?: number;
  event?: string;
  missions?: IMission;

  isDevisExpat?: boolean;
  isDevisSeineMaritime?: boolean;
  isDevisFidinam?: boolean;
}

/**
 * State of the devis
 */
export enum EnumDevisState {
  WaitingForValidation = "waiting-for-validation",
  RefusedByClient = "refused-by-client",
  AcceptedByClient = "accepted-by-client",
}

export interface IMission {
  address?: string;
  roomName?: string;
  context: string;
  date: any;
  extHours: string;
  srcLang: string;
  destLang: string;
  intervention: any;
}

export interface IPrice {
  priceTVA: number;
  priceTVAVal: number;
  priceHT: number;
  priceTTC: number;
}
