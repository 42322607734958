import * as firebase from 'firebase/app';
import { IFile } from '../interfaces/IFile';
import { IPrestation } from '../interfaces/IPrestation';
export class File {

  private _storage: firebase.storage.Storage;
  private _userId: string;
  private _prestation: IPrestation;
  private _subFolder: string;
  private _file: IFile;

  constructor (storage: firebase.storage.Storage, userId: string, prestation: IPrestation, subFolder: string, file: IFile) {
    this._storage = storage;
    this._userId = userId;
    this._prestation = prestation;
    this._subFolder = subFolder;
    this._file = file;
  }

  get UserId(): string {
    return this._userId;
  }

  get PrestationId(): string {
    return this._prestation.deviceStorageId;
  }

  get SubFolder(): string {
    return this._subFolder;
  }

  get File(): IFile {
    return this._file;
  }

  public async GetDownloadLink(): Promise<string> {
    const id = this._userId + '/' + this._prestation.deviceStorageId + '/' + this._subFolder + '/' + this._file.deviceStorageId;
    const docRef = this._storage.ref(id);
    const url = await docRef.getDownloadURL();
    return url;
  }

  public async GetDownloadLinkExtra(): Promise<string> {
    const id = this._userId + '/' + this._prestation.deviceStorageId + '/' + this._subFolder + '/' + 'extra_' + this._file.deviceStorageId;
    const docRef = this._storage.ref(id);
    const url = await docRef.getDownloadURL();
    return url;
  }

}
