import { Component, OnInit } from "@angular/core";
import { LocalisationService } from "../../../../common/src/modules/localisation/localisation.service";
import { HieroBDD } from "../../services/hierobdd.service";
import * as i18nIsoLanguages from "@cospired/i18n-iso-languages";
import * as jsPDF from "jspdf";
import algoliasearch from "algoliasearch";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AppModalConfirmComponent } from "../../../../common/src/utility/modal/confirm-modal.component";
import { Router } from "@angular/router";
import { Location } from "@angular/common";

var doc = new jsPDF();
var pageHeight =
  doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

let img = new Image();
img.src = "../../../../assets/img/logo/fact-admin.png";

let tamponRefused = new Image();
tamponRefused.src =
  "../../../../assets/img/tampon/image_2022-05-03_12-49-42.png";

let tamponAccepted = new Image();

tamponAccepted.src = "../../../../assets/img/tampon/MicrosoftTeams-image.png";

var timeFacture = new Date("10/01/2022").getTime(); // the timestamp that corresponds to 01/10/2022 where the SIRET changed

@Component({
  selector: "factures",
  templateUrl: "./factures.component.html",
  styleUrls: ["./factures.component.scss"],
})
export class FacturesComponent implements OnInit {
  public pro: boolean;
  public searching: boolean;
  public searchingPro: boolean;
  private nbMaxResultSearchParticular: number;
  private nbMaxResultSearchPro: number;
  private result: any;
  private resultPro: any;
  private filter: string;
  filterPro: string;
  public facturesPro: any;
  public facturesPart: any;
  p: number = 1;
  p2: number = 1;
  private busy = true;

  private product: any;

  constructor(
    private hiero: HieroBDD,
    private localisation: LocalisationService,
    private modalService: NgbModal,
    private router: Router,
    public _location: Location
  ) {}

  ngOnInit(): void {
    this.filter = "Filtrer";
    this.filterPro = "Filtrer";
    this.searching = false;
    this.searchingPro = false;

    this.getFacturesPro().then((value) => {
      this.facturesPro = value;
      this.nbMaxResultSearchPro = value.length;
    });

    this.getFacturesPart().then((value) => {
      this.facturesPart = value;
      this.nbMaxResultSearchParticular = value.length;
    });

    this.pro = JSON.parse(localStorage.getItem("isPro"));

    if (this.pro === null) {
      this.pro = true;
    }
  }

  switchBetweenProPar() {
    localStorage.setItem("isPro", JSON.stringify(!this.pro));

    this.pro = JSON.parse(localStorage.getItem("isPro"));
  }

  getFacturesPart() {
    return this.hiero.DB.collection("facture_particuliers")
      .orderBy("date", "desc")
      .get()
      .then((snap) => {
        return snap.docs;
      });
  }

  facturesClassicPart() {
    this.searching = false;

    this.hiero.DB.collection("facture_particuliers")
      .where("typePrestation", "==", "Traduction classique")
      .orderBy("date", "desc")
      .get()
      .then((snap) => {
        this.facturesPart = snap.docs;
      });

    this.p = 1;
    this.filter = "Traductions classiques";
  }

  facturesAsserPart() {
    this.searching = false;

    this.hiero.DB.collection("facture_particuliers")
      .where("typePrestation", "==", "Traduction assermentée")
      .orderBy("date", "desc")
      .get()
      .then((snap) => {
        this.facturesPart = snap.docs;
      });

    this.p = 1;
    this.filter = "Traductions assermentées";
  }

  facturesInterPart() {
    this.searching = false;

    this.hiero.DB.collection("facture_particuliers")
      .where("typePrestation", "==", "Interprétation")
      .orderBy("date", "desc")
      .get()
      .then((snap) => {
        this.facturesPart = snap.docs;
      });

    this.p = 1;
    this.filter = "Interprétations";
  }

  allfacturesPart() {
    this.searching = false;
    this.hiero.DB.collection("facture_particuliers")
      .orderBy("date", "desc")
      .get()
      .then((snapshot) => {
        this.facturesPart = snapshot.docs;
      });
    this.p = 1;
    this.filter = "Toutes les factures";
  }

  getFacturesPro() {
    return this.hiero.DB.collection("facture")
      .orderBy("date", "desc")
      .get()
      .then((snap) => {
        return snap.docs;
      });
  }

  facturesClassicPro() {
    this.searchingPro = false;

    this.hiero.DB.collection("facture")
      .where("typePrestation", "==", "Traduction classique")
      .orderBy("date", "desc")
      .get()
      .then((snap) => {
        this.facturesPro = snap.docs;
      });

    this.p2 = 1;
    this.filterPro = "Traductions classiques";
  }

  facturesAsserPro() {
    this.searchingPro = false;

    this.hiero.DB.collection("facture")
      .where("typePrestation", "==", "Traduction assermentée")
      .orderBy("date", "desc")
      .get()
      .then((snap) => {
        this.facturesPro = snap.docs;
      });

    this.p2 = 1;
    this.filterPro = "Traductions assermentées";
  }

  facturesInterPro() {
    this.searchingPro = false;

    this.hiero.DB.collection("facture")
      .where("typePrestation", "==", "Interprétation")
      .orderBy("date", "desc")
      .get()
      .then((snap) => {
        this.facturesPro = snap.docs;
      });

    this.p2 = 1;
    this.filterPro = "Interprétations";
  }

  allfacturesPro() {
    this.searchingPro = false;
    this.hiero.DB.collection("facture")
      .orderBy("date", "desc")
      .get()
      .then((snapshot) => {
        this.facturesPro = snapshot.docs;
      });
    this.p2 = 1;
    this.filterPro = "Toutes les factures";
  }

  public async deleteFacture(id) {
    const modalRef = this.modalService.open(AppModalConfirmComponent, {
      centered: true,
    });
    modalRef.componentInstance.header = "Confirmer la suppression";
    modalRef.componentInstance.message =
      "Etes vous vraiment sur de vouloir supprimer cette facture ?";
    modalRef.componentInstance.ok = "Supprimer";
    modalRef.componentInstance.cancel = "Annuler";

    let result = false;
    try {
      result = await modalRef.result;
    } catch {}

    if (result) {
      this.busy = true;
      if (this.pro) {
        try {
          this.hiero.DB.collection("facture")
            .doc(id)
            .delete()
            .then(() => {
              this.router
                .navigateByUrl("/refresh", { skipLocationChange: true })
                .then(() => {
                  this.router.navigate([decodeURI(this._location.path())]);
                });
            });
        } catch (err) {
          console.error(err.message);
        } finally {
          this.busy = false;
        }
      } else {
        try {
          this.hiero.DB.collection("facture_particuliers")
            .doc(id)
            .delete()
            .then(() => {
              this.router
                .navigateByUrl("/refresh", { skipLocationChange: true })
                .then(() => {
                  this.router.navigate([decodeURI(this._location.path())]);
                });
            });
        } catch (err) {
          console.error(err.message);
        } finally {
          this.busy = false;
        }
      }
    }
  }

  private async getClientInfo(clientId: string) {
    if (this.pro) {
      return this.hiero.DB.collection("professionnels")
        .doc(clientId)
        .get()
        .then((pro) => {
          return this.hiero.DB.collection("users")
            .doc(pro.data().uid)
            .get()
            .then((user) => {
              return {
                businessName: pro.data().businessName,
                address: pro.data().address,
                user: user.data().profile,
              };
            });
        });
    } else {
      return this.hiero.DB.collection("particuliers")
        .doc(clientId)
        .get()
        .then((pro) => {
          return this.hiero.DB.collection("users")
            .doc(pro.data().uid)
            .get()
            .then((user) => {
              return {
                address: pro.data().address,
                user: user.data().profile,
              };
            });
        });
    }
  }

  private async getProduct(prestation) {
    if (this.pro) {
      return this.hiero.DB.collection("professionnel_commandes")
        .where("prestationId", "==", prestation)
        .get()
        .then((doc) => {
          return doc.docs.map((doc) => {
            return doc.data().documentType;
          });
        });
    } else {
      return this.hiero.DB.collection("particuliers_commandes")
        .where("prestationId", "==", prestation)
        .get()
        .then((doc) => {
          return doc.docs.map((doc) => {
            return doc.data().documentType;
          });
        });
    }
  }

  public async download(facture, param2) {
    let client: any;
    if (this.pro) {
      client = await this.getClientInfo(facture.professionnelId);
    } else {
      client = await this.getClientInfo(facture.particulierId);
    }

    if (facture.event) {
      this.product = facture.event;
    } else {
      this.product = await this.getProduct(facture.prestation);
    }

    let currentLang = this.localisation.CurrentLanguageISO639;

    i18nIsoLanguages.registerLocale(
      require("@cospired/i18n-iso-languages/langs/" + currentLang + ".json")
    );

    setTimeout(() => {
      var doc = new jsPDF();

      // ####### Adresse de Hiero
      doc.setFontSize(12);
      doc.setFontType("bold");
      doc.text(this.localisation.localise("support_company"), 10, 10);

      doc.setFontSize(10);
      doc.setFontType("normal");
      if (facture.date.toDate().getTime() >= timeFacture) {
        doc.text(this.localisation.localise("support_addr1_paris"), 10, 15);
        doc.text(this.localisation.localise("support_addr2_paris"), 10, 20);
      } else {
        doc.text(this.localisation.localise("support_addr1"), 10, 15);
        doc.text(this.localisation.localise("support_addr2"), 10, 20);
      }
      doc.text(this.localisation.localise("support_tel"), 10, 25);
      doc.text(this.localisation.localise("support_email"), 10, 30);

      //##### Logo

      doc.addImage(img, "png", 150, 10, 50, 40);

      // ##### Titre facture
      doc.setTextColor("#ff5b57");
      doc.setFontSize(20);
      doc.setFontType("bold");
      doc.text(
        this.localisation.localise("head_facture").toUpperCase(),
        10,
        70
      );
      doc.setFontType("normal");
      doc.setTextColor(255, 0, 0);
      doc.setFontSize(30);

      // Infos sous le titre de la facture:
      doc.setTextColor("black");
      doc.setFontSize(10);
      doc.setFontType("bold");
      doc.text(
        this.localisation.localise("facture_numero").toUpperCase(),
        20,
        80
      );
      doc.text(
        this.localisation.localise("section_date").toUpperCase(),
        20,
        85
      );
      doc.text(
        this.localisation.localise("facture_echeance").toUpperCase(),
        20,
        90
      );
      doc.text(
        this.localisation.localise("facture_conditions").toUpperCase(),
        20,
        95
      );

      facture.purchaseOrder
        ? doc.text(this.localisation.localise("bon_commande"), 20, 100)
        : null;

      doc.setFontType("normal");

      doc.text(`${facture.numero}`, 60, 80);
      doc.text(facture.date.toDate().toLocaleDateString("fr-FR"), 60, 85);
      doc.text(facture.date.toDate().toLocaleDateString("fr-FR"), 60, 90);
      doc.text(this.localisation.localise("facture_comptant"), 60, 95);

      facture.purchaseOrder ? doc.text(facture.purchaseOrder, 60, 100) : null;

      // ADRESSE DE FACTURATION

      doc.setFontType("bold");
      doc.text(
        this.localisation.localise("facture_adress").toUpperCase(),
        130,
        80
      );
      doc.setFontType("normal");
      let typeClient: string = null;
      if (this.pro) {
        typeClient = "Professionnel";
        client.user.isSeineMaritime
          ? (typeClient += " Seine Maritime")
          : client.user.isFidinam
          ? (typeClient += " Fidinam")
          : client.user.isExpat
          ? (typeClient += " Expat")
          : (typeClient += " " + client.businessName);
      } else {
        typeClient = "Particulier";
        client.user.partenaire
          ? (typeClient += " Science Accueil")
          : (typeClient += " Expat");
      }

      doc.text(typeClient, 130, 87);
      doc.text(
        client.user.givenName + " " + client.user.familyName.toUpperCase(),
        130,
        92
      );
      doc.text(client.address.number + " " + client.address.street, 130, 97);
      doc.text(client.address.code + " " + client.address.city, 130, 102);
      doc.text(client.address.country, 130, 107);

      // Ligne horizontal
      doc.setDrawColor("#ff5b57");
      doc.line(pageWidth - 20, 120, 20, 120);
      // doc.line(190, 151, 30, 151);

      // ####### Le tableau

      // # En tête
      doc.setFillColor(255, 91, 87);

      doc.rect(20, 125, pageWidth - 40, 10, "F");

      doc.setTextColor("#FFFFFF");

      doc.text(
        this.localisation.localise("facture_qte").toUpperCase(),
        21,
        131
      );

      doc.text(
        this.localisation.localise("commande_produit").toUpperCase(),
        33,
        131
      );

      doc.text(
        this.localisation.localise("commande_desc").toUpperCase(),
        73,
        131
      );

      doc.text(
        this.localisation.localise("tasks_price_tva").toUpperCase(),
        127,
        131
      );

      doc.text(
        this.localisation.localise("facture_price").toUpperCase(),
        147,
        129
      );
      doc.text(
        this.localisation.localise("facture_unitaire").toUpperCase(),
        147,
        133
      );

      doc.text(
        this.localisation.localise("facture_montant").toUpperCase(),
        171,
        131
      );

      // # La data

      doc.setTextColor("black");

      doc.text(`${facture.pagesNumber ? facture.pagesNumber : 1}`, 21, 143); //  QTE

      var strArr = doc.splitTextToSize(this.product, 35);
      doc.text(strArr, 33, 143); //  Product
      let type: string = "";
      if (facture.typePrestation === "Interprétation")
        type = this.localisation.localise("interpretation");
      if (facture.typePrestation === "Traduction assermentée")
        type = this.localisation.localise("traduction_assermentee");
      if (facture.typePrestation === "Traduction classique")
        type = this.localisation.localise("traduction_classic");

      var strDes = doc.splitTextToSize(
        type +
          " " +
          this.localisation.localise("prestation_order_from").toLowerCase() +
          " " +
          i18nIsoLanguages.getName(facture.srcLang, currentLang).toLowerCase() +
          " " +
          this.localisation.localise("order_language_to").toLowerCase() +
          " " +
          i18nIsoLanguages.getName(facture.destLang, currentLang).toLowerCase(),
        40
      );
      doc.text(strDes, 73, 143); // Description (faire la gestion de l'espace direcetement avec substr() et \n)

      // doc.setFont("Helvetica");
      doc.text("20 %", 127, 143); // TVA

      doc.text(
        (
          facture.priceHT.toFixed(2) /
          (facture.pagesNumber ? facture.pagesNumber : 1)
        ).toFixed(2),
        152,
        143
      ); // Question par rapport au Prix Unitaire

      doc.text(facture.priceHT.toFixed(2), pageWidth - 30, 143); // Montant total

      doc.setDrawColor("#ff5b57");
      // doc.line(190, 185, 30, 185);
      doc.line(pageWidth - 20, 155, 20, 155);

      // ##### Table des totaux
      //# Col 1

      doc.text(this.localisation.localise("total_ht").toUpperCase(), 127, 165);
      doc.text(this.localisation.localise("total_tva").toUpperCase(), 127, 175);
      doc.text(this.localisation.localise("total_ttc").toUpperCase(), 127, 185);
      doc.text(
        this.localisation.localise("facute_solde").toUpperCase(),
        127,
        195
      );

      // # Col 2

      doc.text(facture.priceHT.toFixed(2), pageWidth - 30, 165);
      doc.text(facture.priceTVAVal.toFixed(2), pageWidth - 30, 175);
      doc.text(facture.priceTTC.toFixed(2), pageWidth - 30, 185);

      doc.setTextColor("#ff5b57");
      doc.setFontSize(15);
      doc.setFontType("bold");
      doc.text(facture.priceTTC.toFixed(2) + " " + "EUR", 165, 195);

      doc.setFontType("normal");

      // Recap TVA
      doc.setFontSize(12);
      doc.setTextColor("black");
      doc.text(this.localisation.localise("recap_tva").toUpperCase(), 20, 208);
      doc.setFontSize(10);

      // doc.text('MONTANT DE TVA', )

      // doc.text('BASE HT', )

      // doc.text()

      doc.setTextColor("black");

      // Lignes inférieures

      // doc.setDrawColor('#ff5b57')
      // doc.line(30, 230, 190, 230)
      // doc.line(20, 201, pageWidth-20, 201)
      doc.setFillColor(255, 91, 87);

      doc.rect(20, 211, pageWidth - 40, 10, "F");

      doc.setTextColor("#FFFFFF");
      doc.text(this.localisation.localise("recap_taux").toUpperCase(), 30, 217);
      doc.text(
        this.localisation.localise("recap_mt_tva").toUpperCase(),
        90,
        217
      );
      doc.text(
        this.localisation.localise("recap_base_ht").toUpperCase(),
        170,
        217
      );

      doc.setTextColor("black");

      doc.text(
        this.localisation.localise("facture_tva_fr").toUpperCase(),
        25,
        227
      );
      doc.text(facture.priceTVAVal.toFixed(2), 100, 227);
      doc.text(facture.priceHT.toFixed(2), 179, 227);

      // Pénalité et etc...
      doc.setFontType("normal");
      doc.setFontSize(10);

      doc.setTextColor("black");
      doc.text(
        this.localisation.localise("condition_paragraphe_1"),
        pageWidth / 2,
        245,
        {
          align: "center",
        }
      );
      doc.text(
        this.localisation.localise("condition_paragraphe_2"),
        pageWidth / 2,
        250,
        {
          align: "center",
        }
      );
      doc.text(
        this.localisation.localise("condition_paragraphe_3"),
        pageWidth / 2,
        255,
        { align: "center" }
      );

      // Footer

      doc.setDrawColor("#ff5b57");
      doc.line(0, pageHeight - 16, pageWidth, pageHeight - 16);
      doc.setFontSize(8);
      if (facture.date.toDate().getTime() >= timeFacture)
        doc.text(
          this.localisation.localise("capital_sasu") +
            " - " +
            this.localisation.localise("agency_address_number") +
            " " +
            this.localisation.localise("rcs_infos_new") +
            " - " +
            this.localisation.localise("agency_address_number") +
            " " +
            this.localisation.localise("siret_new") +
            " - " +
            this.localisation.localise("agency_address_number") +
            " " +
            this.localisation.localise("tva_fr"),
          pageWidth / 2,
          pageHeight - 10,
          { align: "center" }
        );
      else
        doc.text(
          this.localisation.localise("capital_sasu") +
            " - " +
            this.localisation.localise("agency_address_number") +
            " " +
            this.localisation.localise("rcs_infos") +
            " - " +
            this.localisation.localise("agency_address_number") +
            " " +
            this.localisation.localise("siret") +
            " - " +
            this.localisation.localise("agency_address_number") +
            " " +
            this.localisation.localise("tva_fr"),
          pageWidth / 2,
          pageHeight - 10,
          { align: "center" }
        );

      if (param2 === 1) {
        doc.autoPrint();
        //This is a key for printing
        doc.output("dataurlnewwindow");
      } else {
        //Save the PDF
        doc.save(
          this.localisation.localise("head_facture") +
            "_" +
            facture.numero +
            ".pdf"
        );
      }
    }, 1000);
  }
}
