import { Component, OnInit } from "@angular/core";
import { HieroBDD } from "../../services/hierobdd.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AppModalConfirmComponent } from "../../../../common/src/utility/modal/confirm-modal.component";
import { LocalisationService } from "../../../../common/src/modules/localisation/localisation.service";
import { Router } from "@angular/router";

@Component({
  selector: "all_users",
  templateUrl: "./allUsers.component.html",
  styleUrls: ["./allUsers.component.scss"],
})
export class AllUsersComponent implements OnInit {
  newRegistration;
  data;
  p: number = 1;
  filter:string;

  constructor(private hiero: HieroBDD, private router: Router) {}

  ngOnInit(): void {
    this.filter = "Filtrer"
    this.numberOfUsers().then((value) => {
      this.newRegistration = value.sort(function (a, b) {
        return (
          new Date(b.metadata.creationTime).getTime() -
          new Date(a.metadata.creationTime).getTime()
        );
      });
    });

    this.allUsersReq().then((value) => (this.data = value));
  }

  async numberOfUsers() {
    const fun = fetch(
      "https://us-central1-hiero-prod.cloudfunctions.net/allUsers"
    );

    const data = await fun;

    const result = await data.json();

    return result;
  }

  allUsersReq() {
    const req = this.hiero.DB.collection("users");

    return req.get().then((snapshot) => {
      return snapshot.docs;
    });
  }

  profile(id) {
    this.router.navigate(["app/users", id]);
  }

  allTrad () {
    const traducteurs = this.data.filter(trad=> {
      if (trad.data().roles[0].type === 'traducteur'){
        return trad.id
      }else {
        return false
      }
    })

    this.newRegistration = this.newRegistration.filter(user => traducteurs.map(trad=> trad.id).includes(user.uid))
    this.filter = "Tout les traduteurs"
  }

  allUsersFilter(){
    this.numberOfUsers().then((value) => {
      this.newRegistration = value.sort(function (a, b) {
        return (
          new Date(b.metadata.creationTime).getTime() -
          new Date(a.metadata.creationTime).getTime()
        );
      });
    })
    this.filter = 'Tout les utilisateurs'
  }
}
