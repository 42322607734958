import {Injectable} from '@angular/core';
import {HieroBDD} from '../hierobdd.service';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {HttpClient} from '@angular/common/http';


@Injectable()

export class UsersParticularsServices {
  private users: any[] = [];
  usersSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  constructor(private hiero: HieroBDD, private http: HttpClient) {
  }

  dispatchUsers() {
    this.usersSubject.next(this.users);
  }


  getUsersWeb (): void {
    const req = this.http.get('https://us-central1-hiero-prod.cloudfunctions.net/allUsers');
    req.subscribe({
      next: (value: any[]) => {
        const obj = {access: 'granted', type: 'particulier'};
        this.hiero.DB.collection('users')
          .where('roles', 'array-contains', obj)
          .get()
          .then(snap => {
            this.users = snap.docs.map(user => {
              const data = value.find(el => el.uid === user.id) !== undefined ? value.find(el => el.uid === user.id) : {};
              return {user: user.data(), otherInfos: data};
            });
            this.dispatchUsers();
          });
      }
    });
  }

}
