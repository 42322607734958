import { Injectable } from "@angular/core";
import { IInterpretation } from "../../../../common/src/bdd/interfaces/IInterpretation";
import { EnumPrestationState } from "../../../../common/src/bdd/interfaces/IPrestation";
import { COLLECTION } from "../../../../common/src/bdd/utility/collections";
import { HieroBDD } from "../hierobdd.service";
import { TypeProfessionnal } from "../../../../common/src/bdd/interfaces/types";
import { ProFactureService } from "./pro-factures.service";

@Injectable()
export class ProInterpretationsService {
  constructor(
    private hiero: HieroBDD,
    private proFactureService: ProFactureService
  ) {}

  getProInterpretation(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.hiero.DB.collection(COLLECTION.INTERPRETATIONS_COMMANDES)
        .orderBy("sendTime", "desc")
        .get()
        .then((snap) => {
          resolve(snap.docs);
        })
        .catch(reject);
    });
  }

  getSingleProInterpretation(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.hiero.DB.collection(COLLECTION.INTERPRETATIONS_COMMANDES)
        .doc(id)
        .get()
        .then((snap) => {
          resolve(snap.data());
        })
        .catch(reject);
    });
  }

  updateProInterpretationStatus(id: string, status: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.hiero.DB.collection(COLLECTION.INTERPRETATIONS_COMMANDES)
        .doc(id)
        .update({ state: status })
        .then((value) => resolve("Mis à jours"))
        .catch(reject);
    });
  }
  /* 
  addProInterpretation(idDevis: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.hiero.DB.collection(COLLECTION.INTERPRETATIONS_COMMANDES).add()
        .then((value) => resolve("Mis à jours"))
        .catch(reject);
    });
  } */

  public async addProInterpretation(
    event,
    formValue,
    translatorName,
    translatorId,
    professionnelName,
    professionnelId,
    professionnelUid,
    professionnelType,
    price,
    devisId
  ): Promise<string> {
    return new Promise(async (resolve, reject) => {
      let interpretation: IInterpretation = {
        sendTime: new Date(),
        missions: formValue,
        state: EnumPrestationState.WaitingForTranslator,
        translatorName: translatorName,
        translatorId: translatorId,
        professionnelName: professionnelName,
        professionnelId: professionnelId,
        professionnelUid: professionnelUid,
        event: event,
        price: price,
        devisId: devisId,
      };

      if (professionnelType === TypeProfessionnal.seineMaritime)
        interpretation["isInterpretationSeineMaritime"] = true;
      if (professionnelType === TypeProfessionnal.fidinam)
        interpretation["isInterpretationFidinam"] = true;

      this.hiero.DB.collection(COLLECTION.INTERPRETATIONS_COMMANDES)
        .add(interpretation)
        .then((value: any) => {
          this.proFactureService.addFacture(
            value.id,
            event,
            formValue,
            price,
            translatorId,
            translatorName,
            professionnelId,
            professionnelName,
            professionnelType
          );
          resolve(value.id);
        })
        .catch(reject);
    });
  }
}
