import { Component, OnInit } from "@angular/core";
import { HieroBDD } from "../../services/hierobdd.service";
import algoliasearch from "algoliasearch";

@Component({
  selector: "commandes",
  templateUrl: "./commandes.component.html",
  styleUrls: ["./commandes.component.scss"],
})
export class CommandesComponent implements OnInit {
  particular: boolean;

  commandParticular;
  commandPro;
  p: number = 1;
  p2: number = 1;
  result;
  searching: boolean;
  searchingPro: boolean;
  nbMaxResultSearchParticular: number;
  filter: string;
  filterPro: string;
  nbMaxResultSearchPro: number;
  resultPro: any;

  constructor(private hiero: HieroBDD) {}

  ngOnInit(): void {
    this.filter = "Filtrer";
    this.filterPro = "Filtrer";
    this.searching = false;
    this.searchingPro = false;

    this.getCommandParticular().then(
      (value) => (this.commandParticular = value)
    );

    this.getCommandParticular().then(
      (value) => (this.nbMaxResultSearchParticular = value.length)
    );

    this.getCommandPro().then(
      (value) => (this.nbMaxResultSearchPro = value.length)
    );

    this.getCommandPro().then((value) => (this.commandPro = value));

    this.particular = JSON.parse(localStorage.getItem("isPart"));

    if (this.particular === null) {
      this.particular = true;
    }
  }

  getCommandParticular() {
    const req = this.hiero.DB.collection("particuliers_commandes").orderBy(
      "createdAt",
      "desc"
    );

    return req.get().then((snapshot) => {
      return snapshot.docs.map((command) => {
        return command.data();
      });
    });
  }

  getCommandPro() {
    const req = this.hiero.DB.collection("professionnel_commandes").orderBy(
      "createdAt",
      "desc"
    );

    return req.get().then((snapshot) => {
      return snapshot.docs.map((command) => command.data());
    });
  }

  switchBetweenProPar() {
    localStorage.setItem("isPart", JSON.stringify(!this.particular));

    this.particular = JSON.parse(localStorage.getItem("isPart"));
  }

  async search(query) {
    this.searching = true;
    const client = algoliasearch(
      "BXE9JAXJO1",
      "85a0ae1d2f4256cebde087be3be02b8f"
    );
    const index = client.initIndex("Hierø");

    index
      .search(query, { hitsPerPage: this.nbMaxResultSearchParticular })
      .then((data) => {
        this.result = data.hits;

        this.result.sort((a, b) => b.createdAt - a.createdAt);
      });

    this.p = 1;
    this.filter = query !== "" ? query : "Filtrer";
  }

  async searchPro(query) {
    this.searchingPro = true;
    const client = algoliasearch(
      "BXE9JAXJO1",
      "6fa046bd9b0b0a56059288e8da0faf8e"
    );
    const index = client.initIndex("commandes_professionels");

    index
      .search(query, { hitsPerPage: this.nbMaxResultSearchPro })
      .then((data) => {
        this.resultPro = data.hits;

        this.resultPro.sort((a, b) => b.createdAt - a.createdAt);
      });

    this.p2 = 1;
    this.filterPro = query !== "" ? query : "Filtrer";
  }

  commandClassicParticular() {
    this.searching = false;
    this.hiero.DB.collection("particuliers_commandes")
      .where("prestationInfo.srcCountryCode", "==", "")
      .orderBy("createdAt", "desc")
      .get()
      .then((snap) => {
        this.commandParticular = snap.docs.map((command) => {
          return command.data();
        });
      });
    this.p = 1;
    this.filter = "Commandes classiques";
  }

  commandClassicPro() {
    this.searchingPro = false;
    this.hiero.DB.collection("professionnel_commandes")
      .where("prestationInfo.srcCountryCode", "==", "")
      .orderBy("createdAt", "desc")
      .get()
      .then((snap) => {
        this.commandPro = snap.docs.map((command) => {
          return command.data();
        });
      });
    this.p2 = 1;
    this.filterPro = "Commandes classiques";
  }

  commandAsserParticular() {
    this.searching = false;
    this.hiero.DB.collection("particuliers_commandes")
      .orderBy("createdAt", "desc")
      .get()
      .then((snap) => {
        this.commandParticular = snap.docs
          .map((command) => {
            return command.data();
          })
          .filter((asser) => asser.prestationInfo.srcCountryCode !== "");
      });
    this.p = 1;
    this.filter = "Commandes assermentées";
  }

  commandAsserPro() {
    this.searchingPro = false;
    this.hiero.DB.collection("professionnel_commandes")
      .orderBy("createdAt", "desc")
      .get()
      .then((snap) => {
        this.commandPro = snap.docs
          .map((command) => {
            return command.data();
          })
          .filter((asser) => asser.prestationInfo.srcCountryCode !== "");
      });
    this.p2 = 1;
    this.filterPro = "Commandes assermentées";
  }

  allCommandParticular() {
    this.searching = false;
    this.hiero.DB.collection("particuliers_commandes")
      .orderBy("createdAt", "desc")
      .get()
      .then((snapshot) => {
        this.commandParticular = snapshot.docs.map((command) => {
          return command.data();
        });
      });
    this.p = 1;
    this.filter = "Toutes les commandes";
  }

  allCommandPro() {
    this.searchingPro = false;
    this.hiero.DB.collection("professionnel_commandes")
      .orderBy("createdAt", "desc")
      .get()
      .then((snapshot) => {
        this.commandPro = snapshot.docs.map((command) => {
          return command.data();
        });
      });
    this.p2 = 1;
    this.filterPro = "Toutes les commandes";
  }
}
