<div class="container-fluid">
  <div class="text-center text-info" *ngIf="!usersExpat">
    <strong>Chargement...</strong>
    <div
      class="spinner-border mb-3"
      style="width: 6rem; height: 6rem"
      role="status"
    ></div>
  </div>
  <div class="row" *ngIf="usersExpat">
    <!-- BEGIN col-3 -->
    <div class="col-xl-4 col-md-6">
      <div class="card widget widget-stats bg-white">
        <div class="stats-icon stats-icon-lg">
          <i class="fa fa-users fa-fw"></i>
        </div>
        <div class="stats-content">
          <div class="stats-title text-dark">
            <i class="text-info h4 fa fa-users fa-fw"></i>
            <span class="h6 ml-3">Nombre total d'utilisateur inscrits</span>
          </div>

          <div class="stats-number text-dark d-flex justify-content-around">
            <span class="font-weight-bold"> {{ usersExpat + usersProExpat }}  </span>
            <span class="stats-desc text-dark font-italic h6 mt-3"
              >au {{ date | date: "dd/MM/yyyy" }} <span class="text-danger">( {{userThisMonth + usersProExpatThisMonth }} inscrits ce mois)</span> </span
            >
          </div>

          <div class="stats-desc text-dark text-left">
            <span class="font-weight-bold text-success font-italic lead">
              {{usersExpat}}
            </span>
            utilisateurs particuliers
          </div>

          <div class="stats-desc text-dark mt-3 text-right">
            <span class="font-weight-bold proColor font-italic lead">
              {{usersProExpat}}
            </span>
            utilisateurs professionels
          </div>

<!--          <div class="stats-desc text-dark"> Utilisateurs inscrits ce mois </div>-->

<!--          <div class="stats-number text-dark d-flex justify-content-end">-->
<!--            <span class="font-weight-bold text-info font-italic">-->
<!--              {{ userThisMonth }}-->
<!--            </span>-->
<!--          </div>-->
        </div>
      </div>
    </div>

    <div class="col-xl-4 col-md-6">
      <div class="card widget widget-stats bg-white">
        <div class="stats-icon stats-icon-lg">
          <i class="fa fa-shopping-cart fa-fw"></i>
        </div>
        <div class="stats-content">
          <div class="stats-title text-dark">
            <i class="text-info h4 fa fa-shopping-cart fa-fw"></i>
            <span class="h6 ml-3">Nombre total de commandes</span>
          </div>

          <div class="stats-number text-dark d-flex justify-content-around">
            <span class="font-weight-bold">{{ commandsExpat.length + commandsProExpat.length }}</span>
            <span class="stats-desc text-dark font-italic h6 mt-3"
              >au {{ date | date: "dd/MM/yyyy" }} <span class="text-danger">( {{commandsExpatThisMonth.length + commandsProExpatThisMonth.length}} commandes ce mois)</span> </span
            >
          </div>

          <div class="stats-desc text-dark text-left">
            <span class="font-weight-bold text-success font-italic lead">
              {{ commandsExpat.length }}
            </span>
            commandes de particuliers
          </div>

          <div class="stats-desc text-dark mt-3 text-right">
            <span class="font-weight-bold proColor font-italic lead">
              {{commandsProExpat.length}}
            </span>
            commandes de professionels
          </div>

        </div>
      </div>
    </div>

    <div class="col-xl-4 col-md-6">
      <div class="card widget widget-stats bg-white">
        <div class="stats-icon stats-icon-lg">
          <i class="fa fa-money fa-fw"></i>
        </div>
        <div class="stats-content">
          <div class="stats-title text-dark">
            <i class="text-info h4 fa fa-money fa-fw"></i>
            <span class="h6 ml-3">Chiffre d'affaire rapporté en TTC</span>
          </div>

          <div class="stats-number text-dark d-flex justify-content-around">
            <span class="font-weight-bold">{{ (sales + salesPro).toFixed(2) }} &euro;</span>
            <span class="stats-desc text-dark font-italic h6 mt-3"
              >au {{ date | date: "dd/MM/yyyy" }} <span class="text-danger">( {{salesThisMonth + salesProThisMonth }} ce mois)</span> </span
            >
          </div>

          <div class="stats-desc text-dark text-left">
            <span class="font-weight-bold text-success font-italic lead">
              {{sales}} &euro;
            </span>
            rapporté par les particuliers
          </div>

          <div class="stats-desc text-dark mt-3 text-right">
            <span class="font-weight-bold proColor font-italic lead">
              {{salesPro}} &euro;
            </span>
             rapporté par les professionels
          </div>

        </div>
      </div>
    </div>
  </div>

  <div class="card mt-5 container col-xl-9">
    <canvas id="barChart"></canvas>
  </div>

  <div class="card mt-5" *ngIf="switchParticularPro === false">
    <div class="d-flex justify-content-around">
      <div class="h5 mt-3 font-weight-bold text-info ml-4">
        Historiques des commandes de particuliers
      </div>
      <div>
        <button class="btn btn-outline-secondary mt-3 " (click)="switchBetweenParticularPro()"> <i class="fa fa-toggle-off" aria-hidden="true"></i> Commandes professionnels</button>
      </div>
    </div>

    <div>
      <table class="table table-striped mt-4">
        <thead>
          <tr>
            <th scope="col" class="align-middle">Envoyé le</th>
            <th scope="col" class="align-middle">Type de prestation</th>
            <th scope="col" class="align-middle">Nom du document</th>
            <th scope="col" class="align-middle">De</th>
            <th scope="col" class="align-middle">Vers</th>
            <th scope="col" class="align-middle">Prix (TTC)</th>
            <th scope="col" class="align-middle">Statut</th>
          </tr>
        </thead>

        <tbody>
          <tr
            *ngFor="
              let item of commandsExpat
                | paginate: { itemsPerPage: 5, currentPage: p }
            "
          >
            <td class="align-middle">
              {{ item.createdAt.toDate() | date: "dd/MM/yyyy" }}
            </td>
            <td class="align-middle">
              {{
                item.prestationInfo.srcCountryCode === ""
                  ? "Traduction classique"
                  : "Traduction assermentée"
              }}
            </td>
            <td class="align-middle">
              {{
                item.documentType.length > 17
                  ? item.documentType.substr(0, 17) + "..."
                  : item.documentType
              }}
            </td>

            <td class="align-middle">
              {{ item.srcLanguageIso639 | iso639ToNative }}
            </td>

            <td class="align-middle">
              {{ item.destLanguageIso639 | iso639ToNative }}
            </td>
            <td class="align-middle">
              {{ item.prestationInfo.price.ttc.toFixed(2) }} &euro;
            </td>

            <td class="align-middle font-weight-bold h7">
              <span
                style="color: #935e10"
                *ngIf="item.prestationInfo.state == 'waiting-for-translator'"
                >Nouveau</span
              >
              <span
                style="color: #257f25"
                *ngIf="item.prestationInfo.state == 'translating'"
                >En cours de traduction</span
              >
              <span
                style="color: #257f25"
                *ngIf="item.prestationInfo.state == 'validated-pro'"
                >{{ item.prestationInfo.state }}</span
              >
              <span
                style="color: #935e10"
                *ngIf="
                  item.prestationInfo.state == 'waiting-for-client-to-validate'
                "
                >En attente de validation par le client</span
              >
              <span
                style="color: #935e10"
                *ngIf="item.prestationInfo.state == 'refused-by-translator'"
                >Refusé par le traducteur</span
              >
              <span
                style="color: #935e10"
                *ngIf="item.prestationInfo.state == 'cancelled-by-client'"
                >{{ item.prestationInfo.state }}</span
              >
              <span
                style="color: #935e10"
                *ngIf="item.prestationInfo.state == 'validated'"
                >Validé</span
              >
              <span
                style="color: #935e10"
                *ngIf="item.prestationInfo.state == 'waiting-for-payment'"
                >En attente de paiement</span
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="d-flex justify-content-center mt-4">
      <pagination-controls
        (pageChange)="p = $event"
        class="my-pagination"
      ></pagination-controls>
    </div>
  </div>

  <div class="card mt-5" *ngIf="switchParticularPro === true">
    <div class="d-flex justify-content-around">
      <div class="h5 mt-3 font-weight-bold text-info ml-4">
        Historiques des commandes de professionels
      </div>
      <div>
        <button class="btn btn-outline-secondary mt-3 " (click)="switchBetweenParticularPro()"> <i class="fa fa-toggle-on" aria-hidden="true"></i> Commandes professionnels</button>
      </div>
    </div>

    <div>
      <table class="table table-striped mt-4">
        <thead>
        <tr>
          <th scope="col" class="align-middle">Envoyé le</th>
          <th scope="col" class="align-middle">Type de prestation</th>
          <th scope="col" class="align-middle">Nom du document</th>
          <th scope="col" class="align-middle">De</th>
          <th scope="col" class="align-middle">Vers</th>
          <th scope="col" class="align-middle">Prix (TTC)</th>
          <th scope="col" class="align-middle">Statut</th>
        </tr>
        </thead>

        <tbody>
        <tr
          *ngFor="
              let item of commandsProExpat
                | paginate: { itemsPerPage: 5, currentPage: p2 }
            "
        >
          <td class="align-middle">
            {{ item.createdAt.toDate() | date: "dd/MM/yyyy" }}
          </td>
          <td class="align-middle">
            {{
            item.prestationInfo.srcCountryCode === ""
              ? "Traduction classique"
              : "Traduction assermentée"
            }}
          </td>
          <td class="align-middle">
            {{
            item.documentType.length > 17
              ? item.documentType.substr(0, 17) + "..."
              : item.documentType
            }}
          </td>

          <td class="align-middle">
            {{ item.srcLanguageIso639 | iso639ToNative }}
          </td>

          <td class="align-middle">
            {{ item.destLanguageIso639 | iso639ToNative }}
          </td>
          <td class="align-middle">
            {{ item.prestationInfo.price.ttc.toFixed(2) }} &euro;
          </td>

          <td class="align-middle font-weight-bold h7">
              <span
                style="color: #935e10"
                *ngIf="item.prestationInfo.state == 'waiting-for-translator'"
              >Nouveau</span
              >
            <span
              style="color: #257f25"
              *ngIf="item.prestationInfo.state == 'translating'"
            >En cours de traduction</span
            >
            <span
              style="color: #257f25"
              *ngIf="item.prestationInfo.state == 'validated-pro'"
            >{{ item.prestationInfo.state }}</span
            >
            <span
              style="color: #935e10"
              *ngIf="
                  item.prestationInfo.state == 'waiting-for-client-to-validate'
                "
            >En attente de validation par le client</span
            >
            <span
              style="color: #935e10"
              *ngIf="item.prestationInfo.state == 'refused-by-translator'"
            >Refusé par le traducteur</span
            >
            <span
              style="color: #935e10"
              *ngIf="item.prestationInfo.state == 'cancelled-by-client'"
            >{{ item.prestationInfo.state }}</span
            >
            <span
              style="color: #935e10"
              *ngIf="item.prestationInfo.state == 'validated'"
            >Validé</span
            >
            <span
              style="color: #935e10"
              *ngIf="item.prestationInfo.state == 'waiting-for-payment'"
            >En attente de paiement</span
            >
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <div class="d-flex justify-content-center mt-4">
      <pagination-controls
        (pageChange)="p2 = $event"
        class="my-pagination"
      ></pagination-controls>
    </div>
  </div>
</div>
