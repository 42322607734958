<div class="row">
  <!-- BEGIN col-3 -->
  <div class="col-xl-3 col-md-6">
    <div class="card widget widget-stats bg-white">
      <div class="stats-icon stats-icon-lg">
        <i class="fa fa-users fa-fw"></i>
      </div>
      <div class="stats-content">
        <div class="stats-title text-dark">
          <i class="text-info h4 fa fa-user fa-fw"></i>
          <span class="h6 ml-3">Nombre total de particuliers</span>
        </div>

        <div class="stats-number text-dark d-flex justify-content-around">
          <span class="font-weight-bold">{{ numberParticular }}</span>
          <span class="stats-desc text-dark font-italic h6 mt-3"
            >au {{ date | date: "dd/MM/yyyy" }}</span
          >
        </div>

        <div class="stats-desc text-dark">Particuliers inscrits ce mois</div>

        <div class="stats-number text-dark d-flex justify-content-between">
          <span
            [ngClass]="
              particularRate > 0
                ? 'mt-3 text-success font-italic h7'
                : 'mt-3 text-danger font-italic h7'
            "
            >{{
              particularRate > 0
                ? "+" + particularRate + " %"
                : particularRate + " %"
            }}
            par rapport au mois dernier</span
          >
          <span class="font-weight-bold">{{ particularThisMonth }}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="col-xl-3 col-md-6">
    <div class="card widget widget-stats bg-white">
      <div class="stats-icon stats-icon-lg">
        <i class="fa fa-users fa-fw"></i>
      </div>
      <div class="stats-content">
        <div class="stats-title text-dark">
          <i class="text-info h4 fa fa-user fa-fw"></i>
          <span class="h6 ml-3">Nombre total de professionnels</span>
        </div>

        <div class="stats-number text-dark d-flex justify-content-around">
          <span class="font-weight-bold">{{ numberPro }}</span>
          <span class="stats-desc text-dark font-italic h6 mt-3">{{
            date | date: "dd/MM/yyyy"
          }}</span>
        </div>

        <div class="stats-desc text-dark">Professionnels inscrits ce mois</div>

        <div class="stats-number text-dark d-flex justify-content-between">
          <span
            [ngClass]="
              proRate > 0
                ? 'mt-3 text-success font-italic h7'
                : 'mt-3 text-danger font-italic h7'
            "
            >{{ proRate > 0 ? "+" + proRate + " %" : proRate + " %" }} par
            rapport au mois dernier</span
          >
          <span class="font-weight-bold">{{ proThisMonth }}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="col-xl-3 col-md-6">
    <div class="card widget widget-stats bg-white">
      <div class="stats-icon stats-icon-lg">
        <i class="fa fa-users fa-fw"></i>
      </div>
      <div class="stats-content">
        <div class="stats-title text-dark">
          <i class="text-info h4 fa fa-user fa-fw"></i>
          <span class="h6 ml-3">Nombre total de traducteurs</span>
        </div>

        <div class="stats-number text-dark d-flex justify-content-around">
          <span class="font-weight-bold">{{ numberTrad }}</span>
          <span class="stats-desc text-dark font-italic h6 mt-3">{{
            date | date: "dd/MM/yyyy"
          }}</span>
        </div>

        <div class="stats-desc text-dark">Traducteurs inscrits ce mois</div>

        <div class="stats-number text-dark d-flex justify-content-between">
          <span
            [ngClass]="
              translatorRate > 0
                ? 'mt-3 text-success font-italic h7'
                : 'mt-3 text-danger font-italic h7'
            "
            >{{ translatorRate > 0 ? "+" + translatorRate : translatorRate }} %
            par rapport au mois dernier</span
          >
          <span class="font-weight-bold">{{ tradThisMonth }}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="col-xl-3 col-md-6">
    <div class="card widget widget-stats bg-white">
      <div class="stats-icon stats-icon-lg">
        <i class="fa fa-users fa-fw"></i>
      </div>
      <div class="stats-content">
        <div class="stats-title text-dark">
          <i class="text-info h4 fa fa-users fa-fw"></i>
          <span class="h6 ml-3">Nombre total d'utilisateurs</span>
        </div>

        <div class="stats-number text-dark d-flex justify-content-around">
          <span class="font-weight-bold">{{ numberUser }}</span>
          <span class="stats-desc text-dark font-italic h6 mt-3"
            >au {{ date | date: "dd/MM/yyyy" }}</span
          >
        </div>

        <div class="stats-desc text-dark">Utilisateurs inscrits ce mois</div>

        <div class="stats-number text-dark d-flex justify-content-between">
          <span
            [ngClass]="
              userRate > 0
                ? 'mt-3 text-success font-italic h7'
                : 'mt-3 text-danger font-italic h7'
            "
            >{{ userRate > 0 ? "+" + userRate : userRate }} % par rapport au
            mois dernier</span
          >
          <span class="font-weight-bold">{{ usersThisMonth }}</span>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row mt-3">
  <!-- BEGIN col-3 -->
  <div class="col-xl-3 col-md-6">
    <div class="card widget widget-stats bg-white">
      <div class="stats-icon stats-icon-lg"></div>
      <div class="stats-content">
        <div class="stats-title text-dark">
          <span style="color: #ff5b57">
            <i class="h5 fa fa-language fa-fw"></i>
          </span>
          <span class="h5 ml-3">Commandes en cours</span>
        </div>
        <div class="mb-2 d-flex justify-content-around mt-3">
          <span class="stats-number font-weight-bold" style="color: #ff5b57"
            >{{ command }}
          </span>
          <span class="h6 font-italic text-center mt-1">
            <input
              type="text"
              placeholder="Montant total"
              disabled="disabled"
              class="input1"
            />
            <input
              type="text"
              placeholder="{{ commandPriceTotal }} &euro;"
              disabled="disabled"
              class="input2"
            />
          </span>
        </div>
        <div class="stats-number text-dark">
          <span class="h6 font-italic"> Detail par type de prestation </span>
          <span style="color: #ff5b57">
            <i class="h5 fa fa-question-circle fa-fw"></i>
          </span>
        </div>

        <div class="special-card">
          <div class="text-dark d-flex justify-content-between">
            <span>
              <span class="text-info font-weight-bold">9 </span>
              <button class="btn btn-info btn-sm">250 &euro;</button>
            </span>
            -->
            <span>Traductions assermentées</span>
          </div>

          <div class="text-dark mt-4 d-flex justify-content-between">
            <span>
              <span class="text-info font-weight-bold">4 </span>
              <button class="btn btn-info btn-sm">150 &euro;</button>
            </span>
            -->
            <span>Traductions classiques</span>
          </div>

          <div class="text-dark mt-4 d-flex justify-content-between">
            <span>
              <span class="text-info font-weight-bold">3 </span>
              <button class="btn btn-info btn-sm">200 &euro;</button>
            </span>
            -->
            <span>Interpretations</span>
          </div>
        </div>

        <div class="mt-4">
          <button class="btn btn-info btn-block">Detail des sommes</button>
        </div>
      </div>
    </div>
  </div>

  <!-- BEGIN col-3 -->
  <div class="col-xl-3 col-md-6">
    <div class="card widget widget-stats bg-white">
      <div class="stats-icon stats-icon-lg"></div>
      <div class="stats-content">
        <div class="stats-title text-dark">
          <span style="color: #ff5b57">
            <i class="h5 fa fa-language fa-fw"></i>
          </span>
          <span class="h5 ml-3">Chiffre d'affaires réalisé</span>
        </div>
        <div class="mb-2 mt-3">
          <span class="h6 font-italic text-center mt-1">
            <input
              type="text"
              placeholder="Montant  des ventes en DEC"
              disabled="disabled"
              class="input4"
            />
            <input
              type="text"
              placeholder="{{ sales }} &euro;"
              disabled="disabled"
              class="input2"
            />
          </span>
        </div>

        <div class="stats-number text-dark d-flex justify-content-end">
          <span
            [ngClass]="
              salesRate > 0
                ? 'mt-3 text-success font-italic h7'
                : 'mt-3 text-danger font-italic h7'
            "
            >{{
              salesRate > 0 ? "+" + salesRate + " %" : +salesRate + " %"
            }}
            par rapport au mois de Novembre</span
          >
        </div>

        <canvas id="barChart" height="242"></canvas>
      </div>
    </div>
  </div>

  <div class="col-xl-6 col-lg-6">
    <div class="card widget widget-stats bg-white h-90">
      <div class="d-flex justify-content-between">
        <div class="font-weight-bold text-dark">
          <i class="text-info mr-2 h4 fa fa-user-plus fa-fw h5"></i>Nouveaux
          inscrits
        </div>

        <div>
          <button class="btn btn-outline-dark btn-sm" disabled>
            Filtrer par catégorie <i class="fa fa-filter"></i>
          </button>
        </div>
      </div>
      <table class="table table-striped mt-3">
        <tbody>
          <tr *ngFor="let user of newRegistration">
            <th scope="row">
              <div class="text-info h7">
                <img
                  src="{{
                    user.photoURL
                      ? user.photoURL
                      : '../../../../../assets/img/user/avatarUser.png'
                  }}"
                  alt=""
                  width="23"
                  class="image"
                />
                <span *ngFor="let item of data">
                  {{
                    item.id === user.uid
                      ? item.data().profile.givenName +
                        " " +
                        item.data().profile.familyName
                      : null
                  }}
                </span>
              </div>
              <div class="h7 ml-4 font-italic">
                <span class="text-dark" *ngFor="let item of data">
                  {{
                    user.uid === item.id
                      ? item.data().roles[0].type.toUpperCase()
                      : ""
                  }}
                </span>
              </div>
            </th>
            <td>
              <div class="text-info h7 mt-1" *ngFor="let item of data">
                {{
                  item.id === user.uid ? item.data().profile.telephone : null
                }}
              </div>
              <div class="h7 mt-1">
                {{
                  user.email.length > 21 ? user.email.substr(0, 21) : user.email
                }}
              </div>
            </td>
            <td>
              <div class="h7 mt-1">
                Inscrit le {{ user.metadata.creationTime | date: "dd/MM/yyyy" }}
              </div>
            </td>
            <div class="mt-3">
              <button
                [ngClass]="
                  user.emailVerified
                    ? 'btn btn-success btn-sm mr-1'
                    : 'btn btn-danger btn-sm mr-1'
                "
                disabled
              >
                <i class="fa fa-envelope"></i>
              </button>
              <button class="btn btn-info btn-sm" (click)="profile(user.uid)">
                <i class="fa fa-eye"></i>
              </button>
            </div>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-xl-6 col-lg-6">
    <div class="card widget widget-stats bg-white">
      <div class="d-flex justify-content-between">
        <div class="font-weight-bold text-dark">
          <i class="text-info mr-2 h4 fa fa-folder-open fa-fw h5"></i>Commandes
          professionnelles en cours
        </div>

        <div>
          <button class="btn btn-outline-dark btn-sm" disabled>
            Filtrer par catégorie <i class="fa fa-filter"></i>
          </button>
        </div>
      </div>

      <table class="table table-striped mt-3">
        <tbody>
          <tr *ngFor="let item of commandOfPro">
            <th scope="row">
              <div class="text-info h7">
                <div>
                  <button class="btn btn-info btn-sm">
                    {{ item.createdAt.toDate() | date: "dd/MM/yyyy" }}
                  </button>
                  Commande
                  {{
                    item.prestationId.length > 7
                      ? item.prestationId.substr(0, 5) + "..."
                      : item.prestationId
                  }}
                </div>

                <div
                  class="h7 ml-6 font-italic text-dark"
                  *ngFor="let item2 of dataPro"
                >
                  <span class="font-weight-bold">
                    {{
                      item.professionnelId === item2.id
                        ? "Par" + " " + item2.data().businessName
                        : null
                    }}
                  </span>
                </div>
              </div>
            </th>
            <td>
              <div class="text-dark h7 mt-1">
                <span class="font-weight-bold">{{
                  item.prestationInfo.srcCountryCode !== ""
                    ? "Traduction assermentée"
                    : "Traduction classique"
                }}</span>
              </div>
              <div class="h7 mt-1">
                Document:
                <span class="font-weight-bold">{{ item.documentType }}</span>
              </div>
            </td>
            <td>
              <div class="h7 mt-1 text-right font-weight-bold">
                {{ item.budget | number }} &euro;
                <span class="font-weight-normal">TTC</span>
              </div>
              <div class="text-dark h7 mt-1">
                Statut :
                <span class="text-orange font-weight-bold">
                  <span
                    style="color: #935e10"
                    *ngIf="
                      item.prestationInfo.state == 'waiting-for-translator'
                    "
                    >Nouveau</span
                  >
                  <span
                    style="color: #257f25"
                    *ngIf="item.prestationInfo.state == 'translating'"
                    >En cours de traduction</span
                  >
                  <span
                    style="color: #257f25"
                    *ngIf="item.prestationInfo.state == 'validated-pro'"
                    >{{ item.prestationInfo.state }}</span
                  >
                  <span
                    style="color: #935e10"
                    *ngIf="
                      item.prestationInfo.state ==
                      'waiting-for-client-to-validate'
                    "
                    >En attente de validation par le client</span
                  >
                  <span
                    style="color: #935e10"
                    *ngIf="item.prestationInfo.state == 'refused-by-translator'"
                    >Refusé par le traducteur</span
                  >
                  <span
                    style="color: #935e10"
                    *ngIf="item.prestationInfo.state == 'cancelled-by-client'"
                    >{{ item.prestationInfo.state }}</span
                  >
                  <span
                    style="color: #935e10"
                    *ngIf="item.prestationInfo.state == 'validated'"
                    >Validé</span
                  >
                  <span
                    style="color: #935e10"
                    *ngIf="item.prestationInfo.state == 'waiting-for-payment'"
                    >En attente de paiement</span
                  >
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="text-right mt-2" [routerLink]="['../commandes']">
        <button class="btn btn-info">
          Voir toutes les commandes professionnelles
        </button>
      </div>
    </div>
  </div>

  <div class="col-xl-6 col-lg-6">
    <div class="card widget widget-stats bg-white">
      <div class="d-flex justify-content-between">
        <div class="font-weight-bold text-dark">
          <i class="text-info mr-2 h4 fa fa-folder-open fa-fw h5"></i>Autres
          commandes en cours
        </div>

        <div>
          <button class="btn btn-outline-dark btn-sm" disabled>
            Filtrer par catégorie <i class="fa fa-filter"></i>
          </button>
        </div>
      </div>

      <table class="table table-striped mt-3">
        <tbody>
          <tr *ngFor="let item of commandParticular">
            <th scope="row">
              <div class="text-info h7">
                <div>
                  <button class="btn btn-info btn-sm">
                    {{ item.createdAt.toDate() | date: "dd/MM/yyyy" }}
                  </button>
                  Commande
                  {{
                    item.prestationId.length > 7
                      ? item.prestationId.substr(0, 5) + "..."
                      : item.prestationId
                  }}
                </div>

                <div class="h7 ml-6 font-italic text-dark">
                  de <span class="font-weight-bold">Expat.com</span>
                </div>
              </div>
            </th>
            <td>
              <div class="text-dark h7 mt-1">
                <span class="font-weight-bold">{{
                  item.prestationInfo.srcCountryCode !== ""
                    ? "Traduction assermentée"
                    : "Traduction classique"
                }}</span>
              </div>
              <div class="h7 mt-1">
                Document:
                <span class="font-weight-bold">{{ item.documentType }}</span>
              </div>
            </td>
            <td>
              <div class="h7 mt-1 text-right font-weight-bold">
                {{ item.prestationInfo.price.ttc | number }} &euro;
                <span class="font-weight-normal">TTC</span>
              </div>
              <div class="text-dark h7 mt-1">
                Statut :
                <span class="text-orange font-weight-bold"
                  ><span
                    style="color: #935e10"
                    *ngIf="
                      item.prestationInfo.state == 'waiting-for-translator'
                    "
                    >Nouveau</span
                  >
                  <span
                    style="color: #257f25"
                    *ngIf="item.prestationInfo.state == 'translating'"
                    >En cours de traduction</span
                  >
                  <span
                    style="color: #257f25"
                    *ngIf="item.prestationInfo.state == 'validated-pro'"
                    >{{ item.prestationInfo.state }}</span
                  >
                  <span
                    style="color: #935e10"
                    *ngIf="
                      item.prestationInfo.state ==
                      'waiting-for-client-to-validate'
                    "
                    >En attente de validation par le client</span
                  >
                  <span
                    style="color: #935e10"
                    *ngIf="item.prestationInfo.state == 'refused-by-translator'"
                    >Refusé par le traducteur</span
                  >
                  <span
                    style="color: #935e10"
                    *ngIf="item.prestationInfo.state == 'cancelled-by-client'"
                    >{{ item.prestationInfo.state }}</span
                  >
                  <span
                    style="color: #935e10"
                    *ngIf="item.prestationInfo.state == 'validated'"
                    >Validé</span
                  >
                  <span
                    style="color: #935e10"
                    *ngIf="item.prestationInfo.state == 'waiting-for-payment'"
                    >En attente de paiement</span
                  >
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="text-right mt-2" [routerLink]="['../commandes']">
        <button class="btn btn-info">Voir toutes les autres commandes</button>
      </div>
    </div>
  </div>
</div>
