var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BehaviorSubject } from "rxjs/BehaviorSubject";
const FIREBASE_COLLECTION = "professionnel_commandes";
export class ProfessionnelService {
    constructor(professionnel, docId, data) {
        this._professionnel = professionnel;
        this._docId = docId;
        this._data = data;
    }
    get Id() {
        return this._docId;
    }
    get data() {
        return this._data;
    }
    get Professionnel() {
        return this._professionnel;
    }
}
export class ProfessionnelServiceList {
    constructor(professionnel) {
        this._professionnel = professionnel;
        this._services = [];
        this._servicesSubject = new BehaviorSubject(this._services);
        const query = professionnel.User.DB.collection(FIREBASE_COLLECTION).where("professionnelId", "==", professionnel.Id);
        query.onSnapshot({
            next: (snapshort) => {
                this._services = [];
                snapshort.forEach((result) => {
                    const data = result.data();
                    const cmd = new ProfessionnelService(professionnel, result.id, data);
                    this._services.push(cmd);
                });
            },
        });
        // tslint:disable-next-line:label-position no-unused-expression
        error: (error) => { };
        try {
            query.get();
        }
        catch (err) {
            console.warn(err);
        }
    }
    // list commande
    WatchProfessionnelServiceList(observer) {
        return this._servicesSubject.subscribe(observer);
    }
    // Add commande
    addService(proService) {
        return __awaiter(this, void 0, void 0, function* () {
            const commande = {
                professionnelId: proService.professionnelId,
                srcLanguageIso639: proService.srcLanguageIso639,
                originLanguage: proService.originLanguage,
                destLanguageIso639: proService.destLanguageIso639,
                documentType: proService.documentType,
                docFile: proService.docFile,
                traducteurName: proService.traducteurName,
                traducteurId: proService.traducteurId,
                budget: proService.budget,
                createdAt: proService.createdAt,
                prestationId: proService.prestationId,
                prestationInfo: proService.prestationInfo,
                pagesNumber: proService.pagesNumber,
                urgency: proService.urgency,
                description: proService.description,
                devisId: proService.devisId,
            };
            if (proService.isCommandSeineMaritime)
                commande["isCommandSeineMaritime"] = true;
            if (proService.isCommandFidinam)
                commande["isCommandFidinam"] = true;
            if (proService.isCommandExpat)
                commande["isCommandExpat"] = true;
            const exist = this._services.find((service) => {
                return (service.data.srcLanguageIso639 === proService.srcLanguageIso639 &&
                    service.data.destLanguageIso639 === proService.destLanguageIso639 &&
                    service.data.originLanguage === proService.originLanguage &&
                    service.data.budget === proService.budget &&
                    service.data.documentType === proService.documentType &&
                    service.data.docFile === proService.docFile &&
                    service.data.traducteurName === proService.traducteurName);
            });
            if (exist) {
                throw Error("This order service already exist");
            }
            yield this._professionnel.User.DB.collection(FIREBASE_COLLECTION).add(commande);
        });
    }
    removeService(prestationId) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this._professionnel.User.DB.collection(FIREBASE_COLLECTION)
                .doc(prestationId)
                .delete();
        });
    }
    getSrcLanguages(professionnel) {
        const documentTypeRef = professionnel.User.DB.collection("app_indexes").doc("services");
        documentTypeRef
            .get()
            .then((snapshot) => {
            const lang = snapshot.get("languages");
            // console.log(lang);
            /*
            for(const key in data){
              const value = data[key];
              return (value['iso639']);
            }
            */
        })
            .catch();
    }
    // brin test
    getList(professionnel) {
        return __awaiter(this, void 0, void 0, function* () {
            {
                const query = professionnel.User.DB.collection(FIREBASE_COLLECTION).where("professionnelId", "==", professionnel.Id);
                const results = yield query.get();
                if (results) {
                    return results.size > 0;
                }
                else {
                    return false;
                }
            }
        });
    }
    static Init(professionnel) {
        return __awaiter(this, void 0, void 0, function* () {
            return Promise.resolve(new ProfessionnelServiceList(professionnel));
        });
    }
    static HasService(professionnel) {
        return __awaiter(this, void 0, void 0, function* () {
            const query = professionnel.User.DB.collection(FIREBASE_COLLECTION).where("professionnelId", "==", professionnel.Id);
            const results = yield query.get();
            if (results) {
                return results.size > 0;
            }
            else {
                return false;
            }
        });
    }
}
