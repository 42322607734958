var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import { BehaviorSubject } from "rxjs/BehaviorSubject";
import { Admin } from "../../../common/src/bdd/admin/Admin";
import { AuthService } from "../../../common/src/bdd/user/AuthService";
import { ERoleType } from "../../../common/src/bdd/user/User";
import { Config } from "../../../common/src/services/config.service";
import { GoogleGeo } from "./google.services";
export class HieroBDD {
    constructor(config, google) {
        this.config = config;
        this.google = google;
        // Initialize Firebase
        firebase.initializeApp(this.config.Environment.firebase);
        // Create the database object
        this._db = firebase.firestore();
        this._auth = firebase.auth();
        this._storage = firebase.storage();
        this._adminSubject = new BehaviorSubject(null);
        // Setup the service provider
        this._authService = new AuthService(this._auth, this._db, ERoleType.admin);
        // Setup extra login steps:
        // 1/ Login automatically as a translator
        this._authService.extraLoginSteps.push((user) => __awaiter(this, void 0, void 0, function* () {
            const admin = yield Admin.Init(user);
            this._adminSubject.next(admin);
        }));
        // Set up a watch on user, if user becomes null, so does traducteur
        this._authService.WatchUser({
            next: (user) => {
                if (user == null) {
                    this._adminSubject.next(null);
                }
            },
        });
        // Start listener
        this.Auth.Listen();
    }
    get Auth() {
        return this._authService;
    }
    get DB() {
        return this._db;
    }
    get Storage() {
        return this._storage;
    }
    WatchAdmin(observer) {
        return this._adminSubject.subscribe(observer);
    }
    Admin() {
        return this._adminSubject.value;
    }
}
