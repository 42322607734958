<div class="row">
  <div class="col">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" aria-current="page">
          <a
            [routerLinkActive]="['breadcrumbactive']"
            [routerLink]="['/app', 'templates']"
            >Templates</a
          >
        </li>
        <li class="breadcrumb-item" aria-current="page">
          <a [routerLinkActive]="['breadcrumbactive']">Ajouter </a>
        </li>
      </ol>
    </nav>
  </div>
</div>

<div class="row">
  <div class="col-md-6">
    <form [formGroup]="newForm.Group" novalidate>
      <app-form-typeahead
        [parameters]="newForm.GetInputElement('srcCode')"
        [search]="search"
        [formatter]="formatter"
        (selected)="onSrcSelected($event)"
      ></app-form-typeahead>
      <app-form-typeahead
        [parameters]="newForm.GetInputElement('srcCountry')"
        [search]="countrySearch"
        [formatter]="countryFormatter"
        (selected)="onCountrySelected($event)"
      ></app-form-typeahead>
      <app-form-typeahead
        [parameters]="newForm.GetInputElement('dstCode')"
        [search]="search"
        [formatter]="formatter"
        (selected)="onDstSelected($event)"
      ></app-form-typeahead>
      <app-form-dropdown
        [parameters]="documentTypeDropdown"
        (onSelect)="selectDocumentType($event)"
      ></app-form-dropdown>
      <app-form-input
        [parameters]="newForm.GetInputElement('price')"
      ></app-form-input>

      <ngx-file-drop
        dropZoneLabel="Glisser le template PDF ici"
        (onFileDrop)="dropped($event)"
        accept=".pdf"
        class="mb-3"
      >
        <ng-template
          ngx-file-drop-content-tmp
          let-openFileSelector="openFileSelector"
        >
          Glisser le template ici.
          <button type="button" (click)="openFileSelector()">Parcourir</button>
        </ng-template>
      </ngx-file-drop>

      <app-form-general-error [submitForm]="newForm"></app-form-general-error>
      <button
        class="btn btn-success mt-3 mb-3"
        (click)="addTemplate()"
        [disabled]="!newForm.Valid || !pdfRender"
      >
        Ajouter!
      </button>
      <p class="small text-danger" *ngIf="newForm.Dirty && !pdfRender">
        Merci d'ajouter le template avant de cliquer sur Ajouter
      </p>
      <p class="small text-danger" *ngIf="newForm.Dirty && !newForm.Valid">
        Merci de completer le formulaire ou résoudre les problèmes en rouge
        avant cliquer sur Ajouter
      </p>
    </form>

    <app-wait [show]="busy"></app-wait>
  </div>
  <div class="col-md-6">
    <app-pdf-editor
      *ngIf="!!pdfRender"
      [objectEntry]="pdfRender"
      (update)="onUpdated($event)"
    ></app-pdf-editor>
  </div>
</div>

<!--
  <style type="text/css">
  .barrelaterale {
  clear: left;
  float: left;
  width: 34%;
  margin-right: 0%;
  padding: 1%;
  margin-left: 2%;
  background-color: rgba(255, 139, 135, 0.2);
}

.tableauprincipal {
  float: left;
  width: 62%;
  margin-left: 0%;
  padding: 1%;
}
</style>
 


  <div class="barrelaterale">
      <form [formGroup]="newForm.Group" novalidate>
          <h4>Templates</h4>
      <app-form-typeahead [parameters]="newForm.GetInputElement('srcCode')" [search]="search" [formatter]="formatter" (selected)='onSrcSelected($event)' ></app-form-typeahead>
      <app-form-typeahead [parameters]="newForm.GetInputElement('srcCountry')" [search]="countrySearch" [formatter]="countryFormatter" (selected)='onCountrySelected($event)'></app-form-typeahead>
      <app-form-typeahead [parameters]="newForm.GetInputElement('dstCode')" [search]="search" [formatter]="formatter"  (selected)='onDstSelected($event)'></app-form-typeahead>
      <app-form-dropdown [parameters]="documentTypeDropdown" (onSelect)="selectDocumentType($event)"></app-form-dropdown>
      <app-form-input [parameters]="newForm.GetInputElement('price')"></app-form-input>

      <ngx-file-drop 
        dropZoneLabel="Glisser le template PDF ici" 
        (onFileDrop)="dropped($event)" 
        accept=".pdf"
        class="mb-3"
      >
        <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
          Glisser le template ici.
          <button type="button" (click)="openFileSelector()">Parcourir</button>
        </ng-template>
      </ngx-file-drop>

      <app-form-general-error [submitForm]="newForm"></app-form-general-error>
      <button class="btn btn-success mt-3 mb-3" (click)='addTemplate()' [disabled]="!newForm.Valid || !pdfRender">Ajouter!</button>
      <p class="small text-danger" *ngIf="newForm.Dirty && !pdfRender">Merci d'ajouter le template avant de cliquer sur Ajouter</p>
      <p class="small text-danger" *ngIf="newForm.Dirty && !newForm.Valid">Merci de completer le formulaire ou résoudre les problèmes en rouge avant cliquer sur Ajouter</p>
      
      <app-pdf-editor  *ngIf="!!pdfRender" [objectEntry]="pdfRender" (update)="onUpdated($event)"></app-pdf-editor>

      
      </form>
    </div>
    
    <div class="tableauprincipal">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">De</th>
            <th scope="col">Pays</th>
            <th scope="col">Vers</th>
            <th scope="col">Type de document</th>
            <th scope="col">Prix</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let template of templates; let i = index">
            <td>{{ template.Data.srcLanguageIso639 | iso639ToNative  }} </td>
            <td>{{ getCountryName(template.Data.srcCountryCode) }}</td>
            <td>{{ template.Data.destLanguageIso639 | iso639ToNative  }} </td>
            <td>{{ getDocTypeFor('fr', template.Data.documentTypeId ) }}</td>
            <td>{{ template.Data.priceHT }} €</td>
            <td>
              <button class="btn btn-danger btn-sm" (click)="edit(template)">Modifier</button>          
            </td>
          </tr>      
        </tbody>       
      </table>
    </div>  

     <app-wait [show]='busy'></app-wait> 
  -->
