import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LoggedInGuard } from "./guards/loggedin.guard";
import { AccountComponent } from "./routes/account/account.component";
import { LoginComponent } from "./routes/account/login/login.component";
import { AppBaseComponent } from "./routes/app/appbase/appbase.component";
import { DocumentTypeComponent } from "./routes/app/appbase/documentType/documentType.component";
import { DocumentTypeListComponent } from "./routes/app/appbase/documentTypes/documentTypes.component";
import { TemplateListComponent } from "./routes/app/appbase/templates/templates.component";
import { BaseComponent } from "./routes/base/base.component";
import { NotFoundComponent } from "./routes/not_found/not_found.component";
import { TestComponent } from "./routes/base/test/test.component";
import { TemplateComponent } from "./routes/app/appbase/template/template.component";
import { AddTemplateComponent } from "./routes/app/appbase/addTemplate/addtemplate.component";
import { DashboardComponent } from "./routes/dashboard/dasboard.component";
import { CommandesComponent } from "./routes/commandes/commandes.component";
import { SingleCommandComponent } from "./routes/single-command/single-command.component";
import { AllUsersComponent } from "./routes/allUsers/allUsers.component";
import { SingleProfilComponent } from "./routes/singleProfil/singleProfil.component";
import { FacturesComponent } from "./routes/factures/factures.component";
import { ExpatComponent } from "./routes/expat/expat.component";
import { ExpatGuard } from "./guards/expat.guard";
import { SeineComponent } from "./routes/seine_maritime/dashboard/dashboard.component";
import { UsersSeineComponent } from "./routes/seine_maritime/dashboard/users/users.component";
import { FactureSeineComponent } from "./routes/seine_maritime/dashboard/factures/factures.component";
import { SeineMaritimeGuard } from "./guards/seineMaritime.guard";
import { LoginSeineMaritimeComponent } from "./routes/account/seine-login/loginSeineMaritime.component";
import { DevisComponent } from "./routes/devis/devis.component";
import { LoginFidinamComponent } from "./routes/account/fidinam-login/login-fidinam.component";
import { FidinamGuard } from "./guards/fidinam.guard";
import { FidinamComponent } from "./routes/fidinam/dashboard/dashboard.component";
import { UsersFidinamComponent } from "./routes/fidinam/users/users.component";
import { FactureFidinamComponent } from "./routes/fidinam/factures/factures.component";
import { InterpretationsComponent } from "./routes/interpretations/interpretations.component";
import { SingleInterpretationsComponent } from "./routes/single-interpretations/single-interpretations.component";

const appRoutes: Routes = [
  // Pages for logging in
  {
    path: "compte",
    component: AccountComponent,
    children: [
      {
        path: "connexion",
        component: LoginComponent,
      },
      {
        path: "connect",
        component: LoginSeineMaritimeComponent,
      },
      {
        path: "fidinam",
        component: LoginFidinamComponent,
      },
    ],
  },

  {
    path: "testpdf",
    component: TestComponent,
  },

  // Validated section - user is connected
  {
    path: "app",
    canActivate: [LoggedInGuard],
    component: AppBaseComponent,
    children: [
      {
        path: "documentTypes",
        component: DocumentTypeListComponent,
        data: { title: "Types de document" },
        canActivate: [ExpatGuard, SeineMaritimeGuard, FidinamGuard],
      },
      {
        path: "documentType/:documentTypeId",
        component: DocumentTypeComponent,
        canActivate: [ExpatGuard, SeineMaritimeGuard, FidinamGuard],
      },
      {
        path: "templates",
        component: TemplateListComponent,
        data: { title: "Templates" },
        canActivate: [ExpatGuard, SeineMaritimeGuard, FidinamGuard],
      },
      {
        path: "addtemplate",
        component: AddTemplateComponent,
        data: { title: "Ajouter un template" },
        canActivate: [ExpatGuard, SeineMaritimeGuard, FidinamGuard],
      },
      {
        path: "template/:templateId",
        component: TemplateComponent,
        canActivate: [ExpatGuard, SeineMaritimeGuard, FidinamGuard],
      },
      {
        path: "dashboard",
        component: DashboardComponent,
        data: { title: "Tableau de bord" },
        canActivate: [ExpatGuard, SeineMaritimeGuard, FidinamGuard],
      },
      {
        path: "commandes",
        component: CommandesComponent,
        data: { title: "Toutes les commandes de traductions" },
        canActivate: [ExpatGuard, SeineMaritimeGuard, FidinamGuard],
      },
      {
        path: "interpretations",
        component: InterpretationsComponent,
        data: { title: "Toutes les commandes d'interpretations" },
        canActivate: [ExpatGuard, SeineMaritimeGuard, FidinamGuard],
      },
      {
        path: "interpretations/:id",
        component: SingleInterpretationsComponent,
        data: { title: "Détails de la commande" },
        canActivate: [ExpatGuard, SeineMaritimeGuard, FidinamGuard],
      },
      {
        path: "commandes/:id",
        component: SingleCommandComponent,
        data: { title: "Détails de la commande" },
        canActivate: [ExpatGuard, SeineMaritimeGuard, FidinamGuard],
      },
      {
        path: "users",
        component: AllUsersComponent,
        data: { title: "Utilisateurs inscrits" },
        canActivate: [ExpatGuard, SeineMaritimeGuard, FidinamGuard],
      },
      {
        path: "devis",
        component: DevisComponent,
        data: { title: "Toutes les devis" },
        canActivate: [ExpatGuard, SeineMaritimeGuard, FidinamGuard],
      },
      {
        path: "factures",
        component: FacturesComponent,
        data: { title: "Toutes les factures" },
        canActivate: [ExpatGuard, SeineMaritimeGuard, FidinamGuard],
      },

      {
        path: "expat",
        component: ExpatComponent,
        data: { title: "A propos de Expat.com" },
        canActivate: [SeineMaritimeGuard, FidinamGuard],
      },

      {
        path: "users/:id",
        component: SingleProfilComponent,
        data: { title: "Profil utilisateur" },
        canActivate: [ExpatGuard, SeineMaritimeGuard, FidinamGuard],
      },

      {
        path: "seine-maritime",
        canActivate: [ExpatGuard, FidinamGuard],
        children: [
          {
            path: "dashboard",
            component: SeineComponent,
            data: { title: "Tableau de board" },
            canActivate: [ExpatGuard, FidinamGuard],
          },
          {
            path: "users",
            component: UsersSeineComponent,
            data: { title: "Gestion des utilisateurs" },
            canActivate: [ExpatGuard, FidinamGuard],
          },
          {
            path: "factures",
            component: FactureSeineComponent,
            data: { title: "Historique des factures" },
            canActivate: [ExpatGuard, FidinamGuard],
          },
        ],
      },
      {
        path: "fidinam",
        canActivate: [ExpatGuard, SeineMaritimeGuard],
        children: [
          {
            path: "dashboard",
            component: FidinamComponent,
            data: { title: "Tableau de board" },
            canActivate: [ExpatGuard, SeineMaritimeGuard],
          },
          {
            path: "users",
            component: UsersFidinamComponent,
            data: { title: "Gestion des utilisateurs" },
            canActivate: [ExpatGuard, SeineMaritimeGuard],
          },
          {
            path: "factures",
            component: FactureFidinamComponent,
            data: { title: "Historique des factures" },
            canActivate: [ExpatGuard, SeineMaritimeGuard],
          },
        ],
      },

      /*
      {
        path: 'profile',
        component: ProfileComponent
      },
      {
        path: 'traducteur',
        component: TraducteurComponent
      },
      {
        path: 'services',
        component: TraducteurServiceListComponent
      },
      {
        path: 'prestations',
        component: PrestationListComponent
      },
      {
        path: 'prestation/:prestationId',
        component: PrestationComponent
      }
      */
    ],
  },

  // Logged out page(s)
  {
    path: "",
    component: BaseComponent,
    children: [],
  },

  /// Fallbacks
  { path: "", redirectTo: "BaseComponent", pathMatch: "full" },
  { path: "**", component: NotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes
      // { enableTracing: true } // <-- debugging purposes only
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
