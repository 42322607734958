import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs/BehaviorSubject";
import { ProInterpretationsService } from "../pro/pro-interpretations.service";

@Injectable()
export class InterpretationServices {
  private commands: any[] = [];
  private singleCommand: any;
  commandSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(
    <any[]>[]
  );
  singleCommandSubject: BehaviorSubject<any> = new BehaviorSubject<any>({});
  constructor(private pro: ProInterpretationsService) {}

  dispatchCommands(): void {
    this.commandSubject.next(this.commands);
  }

  dispatchSingleCommands(): void {
    this.singleCommandSubject.next(this.singleCommand);
  }

  getInterpretationsCommands(): void {
    this.pro
      .getProInterpretation()
      .then((value) => (this.commands = value))
      .then((value) => this.dispatchCommands());
  }

  getSingleInterpretation(id: string, type: string): any {
    if (type === "pro") {
      this.pro
        .getSingleProInterpretation(id)
        .then((value) => (this.singleCommand = value))
        .then((value) => this.dispatchSingleCommands());
    }
  }

  updateStatus(id: string, status: string, type: string): Promise<string> {
    if (type === "pro") {
      return this.pro.updateProInterpretationStatus(id, status);
    }
  }
}
