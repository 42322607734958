import { Component, OnDestroy, OnInit } from "@angular/core";
import { InterpretationServices } from "../../services/particuliers&pro/interpretation.services";
import { Subscription } from "rxjs/Subscription";
import { ActivatedRoute, Router } from "@angular/router";
import { filter, map } from "rxjs/operators";
import swal from "sweetalert";
import { AppModalConfirmComponent } from "../../../../common/src/utility/modal/confirm-modal.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-single-interpretation",
  templateUrl: "./single-interpretations.component.html",
  styleUrls: ["./single-interpretations.component.scss"],
})
export class SingleInterpretationsComponent implements OnInit, OnDestroy {
  id!: string;
  singleInterpretation!: any;
  subscription!: Subscription;
  busy: boolean = <boolean>true;

  constructor(
    private interpretations: InterpretationServices,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get("id");
    this.subscription = this.interpretations.singleCommandSubject
      .pipe(filter((val) => JSON.stringify(val) !== "{}"))
      .subscribe({
        next: (value) => {
          this.singleInterpretation = value;
        },
      });
    this.interpretations.getSingleInterpretation(this.id, "pro");
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  profile(uid: string): void {
    this.router.navigate(["app/users", uid]);
  }

  resetPage(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = "reload";
    this.router.navigate(["/app", "interpretations", this.id], {
      relativeTo: this.route,
      queryParamsHandling: "merge",
    });
  }

  deleteCommand(): void {
    swal({
      text: " En cours de developpement. ",
    });
  }

  async changeStatus(status: string, type: string): Promise<any> {
    const statusView: string =
      status === "refused-by-translator" ? "refuser" : "valider";
    const statusBtn: string =
      status === "refused-by-translator" ? "Refuser" : "Valider";
    const modalRef = this.modalService.open(AppModalConfirmComponent, {
      centered: true,
    });
    modalRef.componentInstance.header = "Confirmer le changement de statut";
    modalRef.componentInstance.message = `Voulez-vous vraiment ${statusView} cette commande ? Ce changement de statut est irréversible.`;
    modalRef.componentInstance.ok = statusBtn;
    modalRef.componentInstance.cancel = "Annuler";

    let result = false;
    try {
      result = await modalRef.result;
    } catch {}
    if (result) {
      this.busy = true;
      try {
        this.interpretations
          .updateStatus(this.id, status, type)
          .then((value) => {
            console.log(value);
            this.resetPage();
          });
      } catch (err) {
        console.error(err.message);
      } finally {
        this.busy = false;
      }
    }
  }

  redirectToOtherTrad(): void {
    swal({
      text: " Cette fonctionnalité arrive très bientôt. ",
    });
  }
}
