var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit } from "@angular/core";
import { HieroBDD } from "../../../../services/hierobdd.service";
import * as i18nIsoLanguages from "@cospired/i18n-iso-languages";
import * as jsPDF from "jspdf";
import { LocalisationService } from "../../../../../../common/src/modules/localisation/localisation.service";
var doc = new jsPDF();
var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
let img = new Image();
img.src = "../../../../assets/img/logo/fact.png";
let tamponRefused = new Image();
tamponRefused.src =
    "../../../../assets/img/tampon/image_2022-05-03_12-49-42.png";
let tamponAccepted = new Image();
tamponAccepted.src = "../../../../assets/img/tampon/MicrosoftTeams-image.png";
export class FactureSeineComponent {
    constructor(hiero, localisation) {
        this.hiero = hiero;
        this.localisation = localisation;
        this.p = 1;
    }
    ngOnInit() {
        this.allFacturesSeine().then((value) => (this.factures = value));
        this.test();
    }
    allFacturesSeine() {
        return this.hiero.DB.collection("facture")
            .where("isFactureSeineMaritime", "==", true)
            .orderBy("date", "desc")
            .get()
            .then((snap) => {
            return snap.docs.map((facture) => facture.data());
        });
    }
    test() {
        this.hiero.DB.collection("interpretations_commandes")
            .get()
            .then((snap) => {
            console.log(snap.docs[0]);
        });
    }
    getAdressPro(proId) {
        return __awaiter(this, void 0, void 0, function* () {
            const docRef = yield this.hiero.DB.collection("professionnels")
                .doc(proId)
                .get();
            return docRef.data();
        });
    }
    getPro(uid) {
        return __awaiter(this, void 0, void 0, function* () {
            const docRef = yield this.hiero.DB.collection("users").doc(uid).get();
            return docRef.data();
        });
    }
    download(facture, param2) {
        return __awaiter(this, void 0, void 0, function* () {
            let currentLang = this.localisation.CurrentLanguageISO639;
            i18nIsoLanguages.registerLocale(require("@cospired/i18n-iso-languages/langs/" + currentLang + ".json"));
            let clientPro = yield this.getAdressPro(facture.professionnelId);
            let client = yield this.getPro(clientPro.uid);
            setTimeout(() => {
                var doc = new jsPDF();
                // ####### Adresse de Hiero
                doc.setFontSize(12);
                doc.setFontType("bold");
                doc.text(this.localisation.localise("support_company"), 10, 10);
                doc.setFontSize(10);
                doc.setFontType("normal");
                doc.text(this.localisation.localise("support_addr1"), 10, 15);
                doc.text(this.localisation.localise("support_addr2"), 10, 20);
                doc.text(this.localisation.localise("support_tel"), 10, 25);
                doc.text(this.localisation.localise("support_email"), 10, 30);
                //##### Logo
                doc.addImage(img, "png", 150, 10, 50, 40);
                // ##### Titre facture
                doc.setTextColor("#702963");
                doc.setFontSize(20);
                doc.setFontType("bold");
                doc.text(this.localisation.localise("head_facture").toUpperCase(), 10, 70);
                doc.setFontType("normal");
                doc.setTextColor(255, 0, 0);
                doc.setFontSize(30);
                // Infos sous le titre de la facture:
                doc.setTextColor("black");
                doc.setFontSize(10);
                doc.setFontType("bold");
                doc.text(this.localisation.localise("facture_numero").toUpperCase(), 20, 80);
                doc.text(this.localisation.localise("section_date").toUpperCase(), 20, 85);
                doc.text(this.localisation.localise("facture_echeance").toUpperCase(), 20, 90);
                doc.text(this.localisation.localise("facture_conditions").toUpperCase(), 20, 95);
                facture.purchaseOrder
                    ? doc.text(this.localisation.localise("bon_commande"), 20, 100)
                    : null;
                doc.setFontType("normal");
                doc.text(`${facture.numero}`, 60, 80);
                doc.text(new Date(facture.date.seconds * 1000).toLocaleDateString("fr-FR"), 60, 85);
                doc.text(new Date(facture.date.seconds * 1000).toLocaleDateString("fr-FR"), 60, 90);
                doc.text(this.localisation.localise("facture_comptant"), 60, 95);
                facture.purchaseOrder ? doc.text(facture.purchaseOrder, 60, 100) : null;
                // ADRESSE DE FACTURATION
                doc.setFontType("bold");
                doc.text(this.localisation.localise("facture_adress").toUpperCase(), 130, 80);
                doc.setFontType("normal");
                doc.text(clientPro.businessName, 130, 87);
                doc.text(client.profile.givenName +
                    " " +
                    client.profile.familyName.toUpperCase(), 130, 92);
                doc.text(clientPro.address.number + " " + clientPro.address.street, 130, 97);
                doc.text(clientPro.address.code + " " + clientPro.address.city, 130, 102);
                doc.text(clientPro.address.country, 130, 107);
                // Ligne horizontal
                doc.setDrawColor("#702963");
                doc.line(pageWidth - 20, 120, 20, 120);
                // doc.line(190, 151, 30, 151);
                // ####### Le tableau
                // # En tête
                doc.setFillColor(112, 41, 99);
                doc.rect(20, 125, pageWidth - 40, 10, "F");
                doc.setTextColor("#FFFFFF");
                doc.text(this.localisation.localise("facture_qte").toUpperCase(), 21, 131);
                doc.text(this.localisation.localise("commande_produit").toUpperCase(), 33, 131);
                doc.text(this.localisation.localise("commande_desc").toUpperCase(), 73, 131);
                doc.text(this.localisation.localise("tasks_price_tva").toUpperCase(), 127, 131);
                doc.text(this.localisation.localise("facture_price").toUpperCase(), 147, 129);
                doc.text(this.localisation.localise("facture_unitaire").toUpperCase(), 147, 133);
                doc.text(this.localisation.localise("facture_montant").toUpperCase(), 171, 131);
                // # La data
                doc.setTextColor("black");
                doc.text(`${facture.pagesNumber ? facture.pagesNumber : 1}`, 21, 143); //  QTE
                var strArr = facture.product
                    ? doc.splitTextToSize(facture.product.documentType, 35)
                    : "Interprétation";
                doc.text(strArr, 33, 143); //  Product
                let type = "";
                if (facture.typePrestation === "Interprétation")
                    type = this.localisation.localise("interpretation");
                if (facture.typePrestation === "Traduction assermentée")
                    type = this.localisation.localise("traduction_assermentee");
                if (facture.typePrestation === "Traduction classique")
                    type = this.localisation.localise("traduction_classic");
                var strDes = doc.splitTextToSize(type +
                    " " +
                    this.localisation.localise("prestation_order_from").toLowerCase() +
                    " " +
                    i18nIsoLanguages.getName(facture.srcLang, currentLang).toLowerCase() +
                    " " +
                    this.localisation.localise("order_language_to").toLowerCase() +
                    " " +
                    i18nIsoLanguages.getName(facture.destLang, currentLang).toLowerCase(), 40);
                doc.text(strDes, 73, 143); // Description (faire la gestion de l'espace direcetement avec substr() et \n)
                // doc.setFont("Helvetica");
                doc.text("20 %", 127, 143); // TVA
                doc.text((facture.priceHT.toFixed(2) /
                    (facture.pagesNumber ? facture.pagesNumber : 1)).toFixed(2), 152, 143); // Question par rapport au Prix Unitaire
                doc.text(facture.priceHT.toFixed(2), pageWidth - 30, 143); // Montant total
                doc.setDrawColor("#702963");
                // doc.line(190, 185, 30, 185);
                doc.line(pageWidth - 20, 155, 20, 155);
                // ##### Table des totaux
                //# Col 1
                doc.text(this.localisation.localise("total_ht").toUpperCase(), 127, 165);
                doc.text(this.localisation.localise("total_tva").toUpperCase(), 127, 175);
                doc.text(this.localisation.localise("total_ttc").toUpperCase(), 127, 185);
                doc.text(this.localisation.localise("facute_solde").toUpperCase(), 127, 195);
                // # Col 2
                doc.text(facture.priceHT.toFixed(2), pageWidth - 30, 165);
                doc.text(facture.priceTVAVal.toFixed(2), pageWidth - 30, 175);
                doc.text(facture.priceTTC.toFixed(2), pageWidth - 30, 185);
                doc.setTextColor("#702963");
                doc.setFontSize(15);
                doc.setFontType("bold");
                doc.text(facture.priceTTC.toFixed(2) + " " + "EUR", 165, 195);
                doc.setFontType("normal");
                // Recap TVA
                doc.setFontSize(12);
                doc.setTextColor("black");
                doc.text(this.localisation.localise("recap_tva").toUpperCase(), 20, 208);
                doc.setFontSize(10);
                // doc.text('MONTANT DE TVA', )
                // doc.text('BASE HT', )
                // doc.text()
                doc.setTextColor("black");
                // Lignes inférieures
                // doc.setDrawColor('#702963')
                // doc.line(30, 230, 190, 230)
                // doc.line(20, 201, pageWidth-20, 201)
                doc.setFillColor(112, 41, 99);
                doc.rect(20, 211, pageWidth - 40, 10, "F");
                doc.setTextColor("#FFFFFF");
                doc.text(this.localisation.localise("recap_taux").toUpperCase(), 30, 217);
                doc.text(this.localisation.localise("recap_mt_tva").toUpperCase(), 90, 217);
                doc.text(this.localisation.localise("recap_base_ht").toUpperCase(), 170, 217);
                doc.setTextColor("black");
                doc.text(this.localisation.localise("facture_tva_fr").toUpperCase(), 25, 227);
                doc.text(facture.priceTVAVal.toFixed(2), 100, 227);
                doc.text(facture.priceHT.toFixed(2), 179, 227);
                // Pénalité et etc...
                doc.setFontType("normal");
                doc.setFontSize(10);
                doc.setTextColor("black");
                doc.text(this.localisation.localise("condition_paragraphe_1"), pageWidth / 2, 245, {
                    align: "center",
                });
                doc.text(this.localisation.localise("condition_paragraphe_2"), pageWidth / 2, 250, {
                    align: "center",
                });
                doc.text(this.localisation.localise("condition_paragraphe_3"), pageWidth / 2, 255, { align: "center" });
                // Footer
                doc.setDrawColor("#702963");
                doc.line(0, pageHeight - 16, pageWidth, pageHeight - 16);
                doc.setFontSize(8);
                doc.text(this.localisation.localise("capital_sasu") +
                    " - " +
                    this.localisation.localise("agency_address_number") +
                    " " +
                    this.localisation.localise("rcs_infos") +
                    " - " +
                    this.localisation.localise("agency_address_number") +
                    " " +
                    this.localisation.localise("siret") +
                    " - " +
                    this.localisation.localise("agency_address_number") +
                    " " +
                    this.localisation.localise("tva_fr"), pageWidth / 2, pageHeight - 10, { align: "center" });
                if (param2 === 1) {
                    doc.autoPrint();
                    //This is a key for printing
                    doc.output("dataurlnewwindow");
                }
                else {
                    //Save the PDF
                    doc.save(this.localisation.localise("head_facture") +
                        "_" +
                        facture.numero +
                        ".pdf");
                }
            }, 1000);
        });
    }
}
