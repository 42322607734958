import {Injectable} from '@angular/core';
import { Chart } from 'chart.js';
import {ChartConfig} from '../interfaces/chart';

@Injectable()

export  class ChartsServices {

  constructor() {}

  last12Months (): string[] {
    const months = ['JAN', 'FEV', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
    return months.map((month, index) => {
      const d = new Date();
      const i = d.getMonth() - index;
      d.setMonth(i);
      month = months[d.getMonth()] + d.getFullYear().toString().slice(-2);
      return month;
    }).reverse();
  }

  addBarChart (config: ChartConfig, configPro) {
    return new Chart('barChart', {
      type: config.type,
      data: {
        labels: config.labels,
        datasets: [
          {
            label: config.label,
            data: config.data,
            backgroundColor: config.backgroundColor,
            borderColor: config.borderColor,
            borderWidth: 2,
          },
          {
            label: configPro.label,
            data: configPro.data,
            backgroundColor: configPro.backgroundColor,
            borderColor: configPro.borderColor,
            borderWidth: 2,
          }
        ],
      },
      options: {
        title: {
          text: config.title,
          display: true,
          maintainAspectRatio: false,
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
    });
  }
}
