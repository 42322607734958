<div>
  <div class="lead font-italic mb-5">
    Gestion de commandes /
    <span class="text-danger"> Commande n° {{ id }}</span>
  </div>

  <div class="row ml-4 d-flex justify-content-between container-fluid">
    <div class="col-md-8 card ml-n5">
      <div class="lead">
        <i class="fa fa-pencil text-danger mr-3 mt-3 mb-2"></i> Détails de la
        commande
      </div>

      <div class="d-flex justify-content-between">
        <span class="h6 font-italic text-center mt-1">
          <input
            type="text"
            placeholder="Reçu le"
            disabled="disabled"
            class="input1"
          />
          <input
            type="text"
            placeholder=" {{
              command.createdAt.toDate() | date: 'dd/MM/yyyy '
            }} "
            disabled="disabled"
            class="input2"
          />
        </span>

        <span class="h6 font-italic text-center mt-1">
          <input
            type="text"
            placeholder="Statut"
            disabled="disabled"
            class="input1"
          />
          <input
            type="text"
            placeholder=" {{ command.prestationInfo.state }} "
            disabled="disabled"
            class="input2"
          />
        </span>
      </div>

      <div class="d-flex justify-content-between">
        <span class="h6 font-italic text-center mt-1">
          <input
            type="text"
            placeholder="Prestation"
            disabled="disabled"
            class="input1"
          />
          <input
            type="text"
            placeholder=" {{
              command.prestationInfo.srcCountryCode === ''
                ? 'Traduction classique'
                : 'Traduction assermentée'
            }} "
            disabled="disabled"
            class="input2"
          />
        </span>

        <span class="h6 font-italic text-center mt-1">
          <input
            type="text"
            placeholder="Document"
            disabled="disabled"
            class="input1"
          />
          <input
            type="text"
            placeholder=" {{ command.documentType }} "
            disabled="disabled"
            class="input2"
          />
        </span>
      </div>

      <div class="d-flex justify-content-between">
        <span class="h6 font-italic text-center mt-1">
          <input
            type="text"
            placeholder="Langue d'origine"
            disabled="disabled"
            class="input1"
          />
          <input
            type="text"
            placeholder=" {{ command.srcLanguageIso639 | iso639ToNative }} "
            disabled="disabled"
            class="input2"
          />
        </span>

        <span class="h6 font-italic text-center mt-1">
          <input
            type="text"
            placeholder="Pays d'origine"
            disabled="disabled"
            class="input1"
            *ngIf="command.prestationInfo.srcCountryCode !== ''"
          />
          <input
            type="text"
            placeholder=" {{ command.prestationInfo.srcCountryCode }} "
            disabled="disabled"
            class="input2"
            *ngIf="command.prestationInfo.srcCountryCode !== ''"
          />
        </span>
      </div>

      <div class="d-flex justify-content-between">
        <span class="h6 font-italic text-center mt-1">
          <input
            type="text"
            placeholder="Traduit vers"
            disabled="disabled"
            class="input1"
          />
          <input
            type="text"
            placeholder=" {{ command.destLanguageIso639 | iso639ToNative }} "
            disabled="disabled"
            class="input2"
          />
        </span>
      </div>

      <div class="d-flex justify-content-between">
        <span class="h6 font-italic text-center mt-1">
          <input
            type="text"
            placeholder="Doc à traduire"
            disabled="disabled"
            class="input1"
          />
          <input
            type="text"
            placeholder=" {{
              command.prestationInfo.documents[0].original.length
            }} "
            disabled="disabled"
            class="input2"
          />
        </span>

        <span class="h6 font-italic text-center mt-1">
          <input
            type="text"
            placeholder="Nb de pages"
            disabled="disabled"
            class="input1"
          />
          <input
            type="text"
            placeholder=""
            disabled="disabled"
            class="input2"
          />
        </span>
      </div>

      <div class="d-flex justify-content-between">
        <span class="h6 font-italic text-center mt-1">
          <input
            type="text"
            placeholder="Prix TTC"
            disabled="disabled"
            class="input1"
          />
          <input
            type="text"
            placeholder=" {{
              command.prestationInfo.price.ttc.toFixed(2)
            }} &euro; "
            disabled="disabled"
            class="input2"
          />
        </span>
        <span class="h6 font-italic text-center mt-1">
          <input
            type="text"
            placeholder="Délai livraison (scan) "
            disabled="disabled"
            class="input1"
          />
          <input
            type="text"
            placeholder="   "
            disabled="disabled"
            class="input2"
          />
        </span>
      </div>
      <div>
        <span class="h6 font-italic text-center mt-1">
          <input
            type="text"
            placeholder="Délai livraison (courrier)"
            disabled="disabled"
            class="input1"
          />
          <input
            type="text"
            placeholder=" {{ command.urgency | date: 'dd/MM/yyyy' }} "
            disabled="disabled"
            class="input2"
          />
        </span>
      </div>

      <div class="mt-3 d-flex justify-content-start mb-2">
        <details>
          <summary><mark>Description de la commande</mark></summary>
          <p>{{ command.description }}</p>
        </details>
      </div>
    </div>

    <div class="col-md-4 card">
      <div class="lead">
        <i class="fa fa-users text-danger mr-3 mt-3"></i> Détails sur le client
        et le traducteur
      </div>
      <hr class="mt-4" />
      <div class="h5 text-muted mb-3 d-flex justify-content-between">
        <div><i class="fa fa-user text-danger mr-3"></i> Client</div>

        <button
          class="btn btn-admin btn-sm"
          (click)="profile(command.prestationInfo.uid)"
        >
          <i class="fa fa-eye"></i> Consulter son profil
        </button>
      </div>

      <div class="d-flex justify-content-between">
        <span class="h6 font-italic text-center mt-1">
          <input
            type="text"
            placeholder="Type"
            disabled="disabled"
            class="input3"
          />
          <input
            type="text"
            placeholder=" {{ clientType }} "
            disabled="disabled"
            class="input4"
          />
        </span>

        <span class="h6 font-italic text-center mt-1">
          <input
            type="text"
            placeholder="{{
              clientType === 'Particulier' ? 'Nom' : 'Raison sociale'
            }}"
            disabled="disabled"
            class="input3"
          />
          <input
            type="text"
            placeholder=" {{ name }} "
            disabled="disabled"
            class="input4"
          />
        </span>
      </div>
      <hr />
      <div class="h5 text-muted mt-3 d-flex justify-content-between mb-3">
        <div><i class="fa fa-language text-danger mr-3"></i> Traducteur</div>
        <button
          class="btn btn-admin btn-sm"
          (click)="profile(command.prestationInfo.traducteur.uid)"
        >
          <i class="fa fa-eye"></i> Consulter son profil
        </button>
      </div>

      <div class="d-flex justify-content-between">
        <span class="h6 font-italic text-center mt-1">
          <input
            type="text"
            placeholder="Raison sociale"
            disabled="disabled"
            class="input3"
          />
          <input
            type="text"
            placeholder=" {{ command.traducteurName }} "
            disabled="disabled"
            class="input4"
          />
        </span>

        <span class="h6 font-italic text-center mt-1">
          <input
            type="text"
            placeholder="Id"
            disabled="disabled"
            class="input5"
          />
          <input
            type="text"
            placeholder=" {{ command.traducteurId }}  "
            disabled="disabled"
            class="input6"
          />
        </span>
      </div>
      <hr />
    </div>
  </div>

  <div class="row mt-4 ml-4 d-flex justify-content-between container-fluid">
    <div class="col-md-8 card ml-n5">
      <div class="lead">
        <i class="fa fa-trash text-danger mr-3 mt-3"></i> Supprimer la commande
      </div>
      <hr />
      <div
        class="lead font-weight-bold font-italic text-muted"
        *ngIf="
          command.prestationInfo.state === 'waiting-for-payment' ||
          command.prestationInfo.state === 'waiting-for-translator' ||
          command.prestationInfo.state === 'refused-by-translator'
        "
      >
        Voulez vous supprimer la commande n° {{ id }} ?
      </div>

      <div
        class="lead font-weight-bold font-italic text-muted"
        *ngIf="
          command.prestationInfo.state !== 'waiting-for-payment' &&
          command.prestationInfo.state !== 'waiting-for-translator' &&
          command.prestationInfo.state !== 'refused-by-translator'
        "
      >
        Il est trop tard pour supprimer cette commande
      </div>

      <div
        class="d-flex justify-content-end mb-3"
        *ngIf="
          command.prestationInfo.state === 'waiting-for-payment' ||
          command.prestationInfo.state === 'waiting-for-translator' ||
          command.prestationInfo.state === 'refused-by-translator'
        "
      >
        <button class="btn btn-danger" (click)="deleteCommand()">
          Supprimer la commande
        </button>
      </div>
    </div>

    <div class="col-md-4 card">
      <div class="lead">
        <i class="fa fa-files-o text-danger mr-3 mt-3"></i> Documments
      </div>

      <hr />

      <div>
        <h6 class="text-center"><mark>Documents additionnels</mark></h6>
        <span
          *ngFor="
            let document of command.prestationInfo.documents;
            let i = index
          "
        >
          <button
            *ngFor="let file of document.extra"
            class="btn btn-outline-secondary btn-sm mr-3"
            (click)="openFile(command.prestationInfo, document, file, true)"
          >
            <!--<i *ngIf="file.type === 1" class="fas fa-user" aria-hidden="true"></i>
         <i *ngIf="file.type === 0" class="fas fa-file" aria-hidden="true"></i> -->
            <i class="fa fa-file-text mr-1"></i>
            {{ file.name }}
          </button>
        </span>
      </div>

      <div class="mt-3">
        <h6 class="text-center"><mark>A traduire</mark></h6>
        <span
          *ngFor="
            let document of command.prestationInfo.documents;
            let i = index
          "
        >
          <button
            *ngFor="let file of document.original"
            class="btn btn-outline-secondary btn-sm mr-3"
            (click)="openFile(command.prestationInfo, document, file, false)"
          >
            <!--<i *ngIf="file.type === 1" class="fas fa-user" aria-hidden="true"></i>
         <i *ngIf="file.type === 0" class="fas fa-file" aria-hidden="true"></i> -->
            <i class="fa fa-file-text mr-1"></i>
            {{ file.name }}
          </button>
        </span>
      </div>
    </div>
  </div>

  <div class="row ml-4 d-flex justify-content-end container-fluid mt-3">
    <div class="col-md-4 card mb-4">
      <div class="lead">
        <i class="fa fa-files-o text-danger mr-3 mt-3"></i> Gestion du statut de
        la commande
      </div>

      <hr />

      <div class="d-flex justify-content-center mt-2 mb-3">
        <button
          class="btn btn-admin mr-3"
          *ngIf="command.prestationInfo.state === 'waiting-for-translator'"
          (click)="updateCommandStatus(id, 'refused-by-translator')"
        >
          Refuser la mission
        </button>
        <button
          class="btn btn-success"
          *ngIf="
            command.prestationInfo.state !== 'validated' &&
            command.prestationInfo.state !== 'validated-pro' &&
            command.prestationInfo.state !== 'refused-by-translator'
          "
          (click)="updateCommandStatus(id, 'validated')"
        >
          Terminer la mission
        </button>
        <button
          class="btn btn-admin"
          *ngIf="command.prestationInfo.state === 'refused-by-translator'"
          (click)="redirectToOtherTrad()"
        >
          Rediriger vers un autre traducteur
        </button>
        <span
          *ngIf="
            command.prestationInfo.state === 'validated' ||
            command.prestationInfo.state === 'validated-pro'
          "
        >
          Commande déja validé.
        </span>
      </div>
    </div>
  </div>
</div>
