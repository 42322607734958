import { Injectable } from "@angular/core";
import { EnumDevisState } from "../../../../common/src/bdd/interfaces/IDevis";
import { COLLECTION } from "../../../../common/src/bdd/utility/collections";
import { HieroBDD } from "../hierobdd.service";

@Injectable()
export class ProDevisService {
  constructor(private hiero: HieroBDD) {}

  getProAllDevis(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.hiero.DB.collection(COLLECTION.DEVIS)
        .orderBy("date", "desc")
        .get()
        .then((snap) => {
          resolve(snap.docs);
        })
        .catch(reject);
    });
  }

  getProSingleDevis(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.hiero.DB.collection(COLLECTION.DEVIS)
        .doc(id)
        .get()
        .then((snap) => {
          resolve(snap.data());
        })
        .catch(reject);
    });
  }

  acceptProDevis(id: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.hiero.DB.collection(COLLECTION.DEVIS)
        .doc(id)
        .update({
          state: EnumDevisState.AcceptedByClient,
          acceptedByClientAt: new Date(),
        })
        .then((value) => resolve("Mis à jours"))
        .catch(reject);
    });
  }

  refuseProDevis(id: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.hiero.DB.collection(COLLECTION.DEVIS)
        .doc(id)
        .update({
          state: EnumDevisState.RefusedByClient,
          refusedByClientAt: new Date(),
        })
        .then((value) => resolve("Mis à jours"))
        .catch(reject);
    });
  }

  /* updateProInterpretationStatus(id: string, status: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.hiero.DB.collection("interpretations_commandes")
        .doc(id)
        .update({ state: status })
        .then((value) => resolve("Mis à jours"))
        .catch(reject);
    });
  } */
}
