var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// import { HieroBDD } from '../../../../hiero/app/services/hierobdd.service';
import { Localisation } from '../../bdd/settings/Localisation';
import { Storage } from '../../utility/storage.class';
import * as PHRASES from './phrases.js';
import { BehaviorSubject } from 'rxjs';
import { Language } from '../../bdd/utility/Language';
const langCookieKey = 'hiero-lang';
const FIREBASE_SETTINGS_COLLECTION = 'settings';
export class LocalisationService {
    constructor(
    // private hiero: HieroBDD
    ) {
        this._currentLanguageISO639 = 'fr';
        // Load local
        this._localisation = new Localisation(PHRASES);
        this.determineLanguage();
        this._availableLanguages = new BehaviorSubject(this.constructLocalisedList());
        // Load remote
        this.Init('i18n_traducteur');
    }
    get CurrentLanguageISO639() {
        return this._currentLanguageISO639;
    }
    get CurrentLanguageDictionary() {
        if (!this._localisation) {
            return {};
        }
        return this._localisation.getDict(this._currentLanguageISO639);
    }
    constructLocalisedList() {
        const list = [];
        this._localisation.AvailableLanguages.forEach((iso639) => {
            list.push({
                iso639: iso639,
                name: Language.GetNameFromISO639(iso639),
                current: iso639 === this._currentLanguageISO639
            });
        });
        return list;
    }
    determineLanguage() {
        // Try get last specified language or navigator language
        const cookieLang = Storage.GetValue(langCookieKey);
        if (!cookieLang) {
            const navLang = navigator.language;
            this._localisation.AvailableLanguages.forEach((lang) => {
                if (navLang.indexOf(lang) > -1) {
                    this._currentLanguageISO639 = lang;
                }
            });
        }
        else {
            this._currentLanguageISO639 = cookieLang;
        }
    }
    Init(service) {
        return __awaiter(this, void 0, void 0, function* () {
            /* const doc = this.hiero.DB.collection(FIREBASE_SETTINGS_COLLECTION).doc(service);
            const snapshot: firebase.firestore.DocumentSnapshot = await doc.get();
            if (snapshot.exists) {
              const dicts = snapshot.data();
              this._localisation = new Localisation(dicts);
              this.determineLanguage();
              this._availableLanguages.next(this.constructLocalisedList());
            } else {
              console.warn('Could not get localisations. Defaulting to static.');
            } */
        });
    }
    localise(key) {
        if (!this._localisation) {
            return '';
        }
        const clean = key.toLowerCase().trim();
        return this._localisation.localise(this._currentLanguageISO639, clean);
    }
    changeLanguage(langCodeISO639) {
        this._currentLanguageISO639 = langCodeISO639;
        Storage.SetValue(langCookieKey, this._currentLanguageISO639, 5000);
        window.location.reload(true);
    }
    WatchAvailableLanguages(observer) {
        return this._availableLanguages.subscribe(observer);
    }
}
