import {Injectable} from '@angular/core';
import {HieroBDD} from '../hierobdd.service';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';

@Injectable()

export class ParticularsServices {
  private commands: any[] = [];
  private commandsThisMonth: any[] = [];
  private command12Months: any[] = [];
  private indexLast12Months: any[] = [];
  commandSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(<any[]>[]);
  commandSubjectThisMonth: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(<any[]>[]);
  commandSubject12Months: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(<any[]>[]);

  constructor(private hiero: HieroBDD) {
  }

  dispatchCommand () {
    this.commandSubject.next(this.commands);
  }

  dispatchCommandThisMonth () {
    this.commandSubjectThisMonth.next(this.commandsThisMonth);
  }

  dispatchCommand12Months () {
    this.commandSubject12Months.next(this.command12Months);
  }

  getCommands(): void {
    this.hiero.DB.collection('particuliers_commandes')
     .orderBy('createdAt','desc')
     .get()
     .then(snap => {
        this.commands = snap.docs.map(command => {
          return command.data();
        });
        this.dispatchCommand();
     });
  }

  getCommandsThisMonth(): void {
    const date = new Date();
    const start = new Date(date.getFullYear(), date.getMonth(), 1);

    this.hiero.DB.collection('particuliers_commandes')
      .where('createdAt', '>=', start)
      .get()
      .then((snap) => {
        this.commandsThisMonth = snap.docs
          .map((command) => {
            return command.data();
          });
        this.dispatchCommandThisMonth();
      });
  }

  last12MonthIndex (): void {
    this.indexLast12Months =  Array(12).fill(0).map((month, index) => {
      const d = new Date();
      const i = d.getMonth() - index;
      d.setMonth(i);
      month = new Date(d.getFullYear(), d.getMonth(), 1);
      return month;
    }).reverse();
  }

  commandsLast12MonthExpat () {
    this.last12MonthIndex();
    let docs: any[] = [];
     this.hiero.DB.collection('particuliers_commandes')
      .get()
      .then(snap => {
         docs =  snap.docs.map(command => command.data());
      })
       .then(value => {
        this.command12Months = Array(12).fill(0).map((month, index) => {
           month = docs.filter( el => el.createdAt !== undefined && el.createdAt.toDate().getMonth() === this.indexLast12Months[index].getMonth() && el.createdAt.toDate().getFullYear() === this.indexLast12Months[index].getFullYear() && el.prestationInfo.partenaire !== 'science-acceuil' && el.prestationInfo.state === 'validated' );
           return month;
        });
       }).then(value => this.dispatchCommand12Months());
  }
}


