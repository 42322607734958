var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentType } from '../../../../../../common/src/bdd/documents/DocumentType';
import { HieroBDD } from '../../../../services/hierobdd.service';
import { SubmitForm } from '../../../../../../common/src/utility/forms/submitform.class';
import { Template } from '../../../../../../common/src/bdd/documents/Template';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Language } from '../../../../../../common/src/bdd/utility/Language';
import { Countries } from '../../../../../../common/src/bdd/utility/countries';
import { PDFParser } from '../../../../../../common/src/components/pdfpreview/pdfparser';
export function haveValidCountryValidator(id) {
    return (control) => {
        let noddress = false;
        if (control.parent) {
            const par = control.parent;
            const country = par.get(id).value;
            noddress = ((!country || !country.value));
        }
        return (noddress) ? { 'validCountry': true } : null;
    };
}
export function haveValidLanguageValidator(id) {
    return (control) => {
        let noddress = false;
        if (control.parent) {
            const par = control.parent;
            const language = par.get(id).value;
            let iso639 = null;
            if (language) {
                iso639 = Language.GetISO639(language);
            }
            noddress = ((!iso639));
        }
        return (noddress) ? { 'validLanguage': true } : null;
    };
}
export class AddTemplateComponent {
    constructor(hiero, fb, router, route) {
        this.hiero = hiero;
        this.fb = fb;
        this.router = router;
        this.route = route;
        this.busy = true;
        this.formatter = (lang) => lang ? lang : '';
        this.search = (text$) => text$.pipe(debounceTime(200), distinctUntilChanged(), map(term => term.length < 2
            ? []
            : this.fullLanguageList.filter((value) => (value.toLowerCase().indexOf(term.toLowerCase()) > -1 ? value : null), 10)));
        this.countryFormatter = (country) => country ? country.label : '';
        this.countrySearch = (text$) => text$.pipe(debounceTime(200), distinctUntilChanged(), map(term => term.length < 2
            ? []
            : Countries.GetCountryArray('fr').filter((value) => (value.label.toLowerCase().indexOf(term.toLowerCase()) > -1 ? value : null), 10)));
        this.fullLanguageList = Language.GetAllNativeAndRegularNames();
        this.adminSub = this.hiero.WatchAdmin({
            next: (admin) => {
                this.admin = admin;
                DocumentType.Init(admin.User, true)
                    .then((map) => {
                    this.docTypeMap = map;
                    this.createForm();
                    this.busy = false;
                });
            }
        });
        this.createForm();
    }
    ngOnDestroy() {
        if (this.adminSub) {
            this.adminSub.unsubscribe();
        }
    }
    getDocTypeFor(code, documentTypeId) {
        return this.docTypeMap.GetDocumentName(code, documentTypeId);
    }
    getCountryName(code) {
        return Countries.GetCountryMap('fr').get(code);
    }
    createForm() {
        this.newForm = new SubmitForm(this.fb, [
            {
                name: 'srcCode',
                value: '',
                validators: [haveValidLanguageValidator('srcCode')],
                type: 'text',
                title: 'Langue d\'origine',
                autocomplete: 'off',
                placeholder: 'Commencez à taper le nom de la langue',
                help: '',
                errors: [
                    {
                        code: 'required',
                        message: 'Champs obligatoire',
                    },
                    {
                        code: 'validLanguage',
                        message: 'Merci de sélectionner la langue de la liste défilante !',
                    },
                ]
            },
            {
                name: 'srcCountry',
                value: '',
                validators: [haveValidCountryValidator('srcCountry')],
                type: 'text',
                title: 'Pays d\'origine',
                autocomplete: 'off',
                placeholder: 'Taper le nom du pays...',
                help: '',
                errors: [
                    {
                        code: 'required',
                        message: 'Champs obligatoire',
                    },
                    {
                        code: 'validCountry',
                        message: 'Merci de sélectionner le pays de la liste défilante !',
                    },
                ]
            },
            {
                name: 'dstCode',
                value: '',
                validators: [haveValidLanguageValidator('dstCode')],
                type: 'text',
                title: 'Traduire vers...',
                autocomplete: 'off',
                placeholder: 'Commencez à taper le nom de la langue',
                help: '',
                errors: [
                    {
                        code: 'required',
                        message: 'Champs obligatoire',
                    },
                    {
                        code: 'validLanguage',
                        message: 'Merci de sélectionner la langue de la liste défilante !',
                    },
                ]
            },
            {
                name: 'type',
                value: '',
                validators: [],
                type: 'text',
                title: 'Type de document',
                autocomplete: 'off',
                placeholder: '',
                help: 'Le type de document representé par ce document',
                errors: [
                    {
                        code: 'required',
                        message: 'Champs obligatoire',
                    },
                ]
            },
            {
                name: 'price',
                value: '',
                validators: [Validators.required, Validators.min(0.1)],
                type: 'number',
                title: 'Prix HT',
                autocomplete: 'off',
                placeholder: '',
                min: 0.1,
                help: 'Le prix HT du document percu par le traducteur pour une prestation de ce type',
                errors: [
                    {
                        code: 'required',
                        message: 'Champs obligatoire',
                    },
                    {
                        code: 'min',
                        message: 'Minimum de 0.1€ obligatoire',
                    },
                ]
            }
        ], 
        // Submit callback
        (changes) => {
        }, 
        // Success callback
        () => {
            // What to do with login success ?
            this.busy = false;
        }, 
        // Fail callback
        err => {
            // What to do with login failuer
            console.error(err);
            this.busy = false;
        }, 
        // Changes callback
        null);
        this.setupDropdown();
    }
    setupDropdown() {
        const docTypes = [];
        if (this.docTypeMap) {
            this.docTypeMap.ToArray.forEach((docType) => {
                const docName = docType.label.find(label => label.code === 'fr');
                if (docName) {
                    docTypes.push({
                        name: docName.value,
                        data: docType._id
                    });
                }
            });
        }
        if (docTypes.length > 0) {
            this.selectedDocType = docTypes[0].data;
        }
        this.documentTypeDropdown = {
            choices: docTypes,
            selectedIndex: 0,
            title: '',
            input_title: 'Type de document',
            hasButton: false,
            button_title: '',
            help: ''
        };
    }
    onCountrySelected(country) {
        console.log(country);
        /* const oldAddress = this.profileForm.GetValue('address');
        if (oldAddress && oldAddress.extra) {
          address.address.extra = oldAddress.extra;
        }
    
        this.profileForm.SetValue('coords', address.coords);
        this.profileForm.SetValue('address', address.address);*/
    }
    onSrcSelected(country) {
        console.log(country);
        /* const oldAddress = this.profileForm.GetValue('address');
        if (oldAddress && oldAddress.extra) {
          address.address.extra = oldAddress.extra;
        }
    
        this.profileForm.SetValue('coords', address.coords);
        this.profileForm.SetValue('address', address.address);*/
    }
    onDstSelected(country) {
        console.log(country);
        /* const oldAddress = this.profileForm.GetValue('address');
        if (oldAddress && oldAddress.extra) {
          address.address.extra = oldAddress.extra;
        }
    
        this.profileForm.SetValue('coords', address.coords);
        this.profileForm.SetValue('address', address.address);*/
    }
    selectDocumentType(data) {
        this.selectedDocType = data.data;
    }
    addTemplate() {
        return __awaiter(this, void 0, void 0, function* () {
            const src = this.newForm.GetValue('srcCode');
            const dst = this.newForm.GetValue('dstCode');
            const srcCountryData = this.newForm.GetValue('srcCountry');
            const price = this.newForm.GetValue('price');
            const srcCode = Language.GetISO639(src);
            const dstCode = Language.GetISO639(dst);
            const srcCountry = srcCountryData.value;
            /// VALIDIITY
            if (!this.newForm.CheckValidity()) {
                return;
            }
            if (!srcCode) {
                this.newForm.SetGeneralError("Error: missing info: srcCode");
            }
            if (!dstCode) {
                this.newForm.SetGeneralError("Error: missing info: dstCode");
            }
            if (!srcCountry) {
                this.newForm.SetGeneralError("Error: missing info: srcCountry");
            }
            if (!this.selectedDocType) {
                this.newForm.SetGeneralError("Error: missing info: this.selectedDocType");
            }
            if (!price) {
                this.newForm.SetGeneralError("Error: missing info: price");
            }
            if (!this.pdfRender) {
                this.newForm.SetGeneralError("Error: missing info: this.pdfRender");
            }
            if (!srcCode || !dstCode || !srcCountry || !this.selectedDocType || !price || !this.pdfRender) {
                console.log(srcCode);
                console.log(dstCode);
                console.log(srcCountry);
                console.log(this.selectedDocType);
                console.log(price);
                return;
            }
            this.busy = true;
            this.pdfRender.clearHighlights();
            const priceNum = parseFloat(price);
            const t = {
                srcLanguageIso639: srcCode,
                srcCountryCode: srcCountry,
                destLanguageIso639: dstCode,
                documentTypeId: this.selectedDocType,
                priceHT: priceNum,
                config: this.pdfRender.Data
            };
            try {
                const newTemplate = yield Template.Create(this.admin.User, t);
                this.pdfRender = null;
                this.router.navigate(['/app', 'template', newTemplate.Id]);
            }
            catch (err) {
                this.newForm.SetGeneralError(err.message);
            }
        });
    }
    dropped(files) {
        return __awaiter(this, void 0, void 0, function* () {
            if (files.length === 0) {
                return;
            }
            const droppedFile = files[0];
            // Is it a file?
            if (droppedFile.fileEntry.isFile) {
                const fileEntry = droppedFile.fileEntry;
                this.busy = true;
                try {
                    const pdfRender = yield PDFParser.Parse(fileEntry);
                    this.pdfRender = pdfRender;
                    this.busy = false;
                }
                catch (err) {
                    console.warn(err.message);
                    this.busy = false;
                }
            }
        });
    }
    fileOver(event) {
        console.log(event);
    }
    fileLeave(event) {
        console.log(event);
    }
}
