export const COLLECTION = {
  ADMINS: "admins",
  APP_INDEXES: "app_indexes",
  CLIENT_MESSAGE_NOTIFICATIONS: "client_message_notifications",
  CUSTOMERS: "customers",
  DEST_LANGUAGES: "dest_languages",
  DEVIS: "devis",
  DOCUMENT_TYPES: "document_types",
  FACTURE: "facture",
  FACTURE_PARTICULIERS: "facture_particuliers",
  INTERPRETATIONS: "interpretations",
  INTERPRETATIONS_COMMANDES: "interpretations_commandes",
  MESSAGES: "messages",
  MESSAGES_WEB: "messages_web",
  MESSAGING: "messaging",
  PARTICULIERS: "particuliers",
  PARTICULIERS_COMMANDES: "particuliers_commandes",
  PRESTATIONS: "prestations",
  PRODUCTS: "products",
  PROFESSIONNEL_COMMANDES: "professionnel_commandes",
  PROFESSIONNELS: "professionnels",
  SETTINGS: "settings",
  SOURCE_LANGUAGES: "source_languages",
  TEMPLATES: "templates",
  TEMPLATES_FILTER: "templates_filter",
  TICKETS: "tickets",
  TRADUCTEUR_MESSAGE_NOTIFICATIONS: "traducteur_message_notifications",
  TRADUCTEUR_SERVICES: "traducteur_services",
  TRADUCTEURS: "traducteurs",
  TRADUCTEURS_TICKETS: "traducteurs_tickets",
  USERS: "users",
};
